import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import ReactMarkdown from 'react-markdown';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import {FrnHeader} from 'components/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Footer';

export default function WorkshopResourcesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = 'default',
  entry = 'default',
  ...props
}) {
  const pageTitle = 'Thriving on a Plant-Based Diet Workshop Resources';
  const pageDescription =
    'Get actionable insights from world-leading experts on how to optimize your health with a plant-based diet. Join this free live workshop and start your journey towards better health and happiness!';
  const shareUrl = 'https://wlc.foodrevolution.org/workshop/';
  const ogImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-facebook-202405.png';
  const pinterestImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-pinterest-202405.png';
  const facebookDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const twitterDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const pinterestDescription =
    'Join us in this free live workshop to discover the benefits of a plant-based diet! Get practical tips from nutrition experts to improve your health and happiness. Register now for free and take your first step towards a healthier you!';

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const phase = defaultSchedule.phase;

  useBalanceText();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <FrnHeader
          shareUrl={shareUrl}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          logoUrl="https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-logo-white.svg"
          logoAlt="WHOLE Life Club Workshop logo"
          logoWidth={300}
          logoHeight={132}
        />
      </div>

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons
          shareUrl={shareUrl}
          shareImage={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          floating
        />
      </div>
    </div>
  );
}
