import React, {useEffect} from 'react';
import {Location} from '@reach/router';
import qs from 'qs';
import {DateTime} from 'luxon';
import {Col, Container, Row, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBeacon from 'utils/useBeacon';
import useLetReach from 'utils/useLetReach';
import {useModal} from 'contexts/modal';
import {useUserState} from 'contexts/user';

import PageMeta from 'components/PageMeta';
import Header from 'components/Header';
import Video from 'components/Video';
import Footer from 'components/Footer';
import ShareButtons from 'components/Social/ShareButtons';
import {WlcSpeakerList} from 'components/SpeakerList';
import CommentSection from 'components/CommentSection';
import CheckoutButton from 'components/CheckoutButton';
import Tile, {RecipeWlcDay} from 'components/Elements/Tile';
import CountdownTimer from 'components/Elements/CountdownTimer';
import VideoButtons from 'components/Broadcasts/VideoButtons';
import Section, {SectionImage} from 'components/Elements/Section';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {SalesDisclaimer} from 'components/SalesCta';

export default function (props) {
  return <Location>{({location}) => <Broadcasts location={location} {...props} />}</Location>;
}

function Broadcasts({location, ...props}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: ''
          }
        ],
        noBroadcastSales: false,
        broadcastMonthOverride: ''
      },
      sales: salesSchedule = {
        phase: 'before',
        salesEndDate: '',
        salesCtaCopy: ''
      }
    }
  } = usePhaseState();

  const isSeries = defaultSchedule.actionHours.length > 1;
  const currentActionHour = defaultSchedule.currentActionHour;
  const broadcastTime = new Date(defaultSchedule.actionHours[currentActionHour].date).getTime();
  const broadcastVideo = defaultSchedule.actionHours[currentActionHour].video;
  const broadcastDate = DateTime.fromISO(defaultSchedule.actionHours[currentActionHour].date);
  const notFinalActionHour = currentActionHour + 1 < defaultSchedule.actionHours.length;

  const salesEnd = new Date(salesSchedule.salesEndDate).getTime();
  const isDollarTrial = salesSchedule.offerFlag === 'dollarTrial';

  const nextBroadcastTime = notFinalActionHour
    ? new Date(defaultSchedule.actionHours[currentActionHour + 1].date).getTime()
    : null;

  const salesTimer = new Date('2020-06-02T00:00:00-07:00').getTime();

  const {toggle: toggleModal} = useModal();
  const {email, registrationId} = useUserState();
  useEffect(
    () => !email && !registrationId && !qs.parse(location.search, {ignoreQueryPrefix: true}).o && toggleModal(),
    []
  );

  useBeacon();
  useLetReach();
  return (
    <div className="page">
      <PageMeta title="Broadcasts" description="Make FOOD the foundation of YOUR health" />

      <div className="page-content">
        <Header className="background-black header-wrap" logoWhite style="dark" logo={isSeries ? 'plural' : null} />

        <section className="section-broadcast section-broadcast-header background-gradient-indigo-purple-right text-white">
          <Container>
            <Row className="d-flex justify-content-center text-center">
              <Col lg="10">
                <h3 className="section-only-heading">
                  {!isSeries ? (
                    'Watch This Month’s Action Hour Below!'
                  ) : (
                    <>
                      {defaultSchedule.phase === 'before' ? (
                        'Welcome to the Broadcast Page!'
                      ) : defaultSchedule.phase === 'during' ? (
                        'The Action Hour is Going on Today!'
                      ) : (
                        <>
                          Today’s Action Hour has ended. <br className="d-none d-lg-block" />
                          Thanks for joining us!
                        </>
                      )}
                    </>
                  )}
                </h3>

                {!isSeries ? null : (
                  <>
                    {defaultSchedule.phase === 'before' && currentActionHour === 0 ? (
                      <>
                        <h5 className="broadcast-timer-heading text-uppercase mt-4 mb-2">
                          The Action Hour Series Starts In:
                        </h5>
                        <div>
                          <CountdownTimer time={broadcastTime} />
                        </div>
                      </>
                    ) : defaultSchedule.phase === 'before' ? (
                      <>
                        <h5 className="broadcast-timer-heading text-uppercase mt-4 mb-2">
                          The Next Action Hour Starts In:
                        </h5>
                        <div>
                          <CountdownTimer time={broadcastTime} />
                        </div>
                      </>
                    ) : defaultSchedule.phase === 'after' && notFinalActionHour ? (
                      <>
                        <h5 className="broadcast-timer-heading text-uppercase mt-4 mb-2">
                          The Next Action Hour Starts In:
                        </h5>
                        <div>
                          <CountdownTimer time={nextBroadcastTime} />
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>

        <Section
          id="broadcast-video"
          className="section-angle-first section-broadcast-video"
          innerClass="pb-0"
          color="white"
        >
          <Container>
            <Row className="d-flex justify-content-center text-center mb-3">
              <Col lg="10">
                {isSeries && defaultSchedule.phase === 'after' ? (
                  <>
                    <h3 className="section-heading">
                      Click this button <br className="d-none d-sm-block d-md-none" />
                      to access the replay
                    </h3>
                    <Button color="cta" size="lg" href="/replays/">
                      Access the Replay
                    </Button>
                  </>
                ) : (
                  <>
                    <Video
                      url={broadcastVideo ? broadcastVideo : 'https://www.youtube.com/watch?v=TQ9gNpP4wQM'}
                      playing
                    />
                  </>
                )}
              </Col>
            </Row>
            {isSeries && defaultSchedule.phase === 'after' ? null : (
              <Row>
                <Col>{defaultSchedule.noBroadcastSales ? <VideoButtons noSalesBtn /> : <VideoButtons />}</Col>
              </Row>
            )}

            {defaultSchedule.phase === 'after' ? null : (
              <Row className="d-flex justify-content-center text-center mt-3">
                <Col lg="10">
                  <p>
                    During the broadcasts, you’ll be able to watch the video with <br className="d-none d-lg-block" />
                    Ocean and the featured Health Revolutionary, and you’ll be able <br className="d-none d-lg-block" />
                    to engage with others in the comments.
                  </p>
                  <p>
                    <em>
                      Note: WHOLE Life Club members can submit questions for <br className="d-none d-lg-block" />
                      possible inclusion in future Action Hours.
                      {isSeries ? ' (More info below.)' : null}
                    </em>
                  </p>
                </Col>
              </Row>
            )}
          </Container>
        </Section>

        <Section id="broadcast-speakers" color="light-gray">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <div className="background-green text-white p-3 rounded mb-3">
                  <h4 className="section-only-heading text-center">
                    {defaultSchedule.broadcastMonthOverride
                      ? defaultSchedule.broadcastMonthOverride
                      : broadcastDate.setZone('America/Los_Angeles').toFormat('MMMM')}{' '}
                    {isSeries ? 'Action Hour Series Lineup' : 'Action Hour'}
                  </h4>
                </div>
                <div className="speaker-info mb-0">
                  <WlcSpeakerList broadcastList />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        {isSeries ? (
          <>
            <Section id="" color="black" innerClass="text-center text-white">
              <Container>
                <Row>
                  <Col>
                    <h3 className="section-only-heading">
                      GREAT job showing up <br className="d-none d-sm-block d-md-none" />
                      for your health!
                    </h3>
                  </Col>
                </Row>
              </Container>
            </Section>
            <Section id="" color="white">
              <Container>
                <Row>
                  <Col className="text-center">
                    <h2 className="section-heading mb-5">
                      Join WHOLE Life Club <br className="d-none d-sm-block d-lg-none" />
                      at the best price
                    </h2>
                    <h2 className="mt-0 mb-3">
                      No more procrastinating
                      <br />
                      Put what you KNOW <br className="d-none d-sm-block d-lg-none" />
                      into <em>action</em>
                    </h2>
                    <img
                      src="https://cdn.foodrevolution.org/wlc/wlc-product-image-v20220329.png"
                      width="1200"
                      height="500"
                      alt="WHOLE Life Club graphic"
                    />
                    <h5 className="text-400">WHOLE Life Club is open for enrollment</h5>
                    <h3 className="mt-0">
                      Join now for just $1 – for <br className="d-none d-sm-block d-lg-none" />
                      your first month
                    </h3>
                    <CheckoutButton
                      sku="WLC"
                      price={isDollarTrial ? '1' : isSeries ? '127' : '147'}
                      slug={
                        isDollarTrial ? 'wlc-promo-30daytrial' : isSeries ? 'wlc-promo-special' : 'wlc-special-brain'
                      }
                    >
                      <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Buy Now
                    </CheckoutButton>
                    <SalesDisclaimer iconColor="white" />
                    {isDollarTrial ? (
                      <p className="text-small text-center mt-2 mb-0">
                        Join WHOLE Life Club for just $1 for your first month. After that,{' '}
                        <br className="d-none d-md-block d-lg-none" />
                        you’ll be enrolled for a special membership <br className="d-none d-lg-block" />
                        price of just $99 a year <br className="d-none d-md-block d-lg-none" />
                        (that’s 27 cents per day) unless you cancel before your month is up.
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Section>
            <Section id="" color="green" innerClass="text-center text-white">
              <Container>
                <Row>
                  <Col>
                    <h2 className="section-only-heading">
                      What’s the secret sauce to <br className="d-none d-lg-block" />a happier, healthier life?
                    </h2>
                  </Col>
                </Row>
              </Container>
            </Section>
            <SectionImage id="" color="light-gray" imgPosition="right" imgUrl={require('static/img-recipes.jpg')}>
              <Container>
                <Row>
                  <Col>
                    <h4 className="section-heading">The RIGHT information — including mouthwatering recipes!</h4>
                    <IconList>
                      <IconListItem>
                        Weekly <strong>plant-based recipes</strong> that are as delicious as they are healthy
                      </IconListItem>
                      <IconListItem>
                        Live Monthly Action Hours with guest experts who <strong>challenge the status quo</strong>
                      </IconListItem>
                      <IconListItem>
                        A vault of 500+ articles to help you <strong>slash your risk</strong> of cancer, heart disease,
                        obesity, and more
                      </IconListItem>
                    </IconList>
                  </Col>
                </Row>
              </Container>
            </SectionImage>
            <SectionImage id="" color="white" imgUrl={require('static/img-wisdom.jpg')}>
              <Container>
                <Row>
                  <Col>
                    <h4 className="section-heading">Getting your questions answered</h4>
                    <IconList>
                      <IconListItem>
                        <strong>Submit questions</strong> for future live Action Hours
                      </IconListItem>
                      <IconListItem>
                        Ask YOUR questions to our <strong>certified nutritionists</strong>
                      </IconListItem>
                      <IconListItem>
                        Discover what you didn’t even know you needed — but that will <strong>revolutionize</strong>{' '}
                        your quality of life
                      </IconListItem>
                    </IconList>
                  </Col>
                </Row>
              </Container>
            </SectionImage>
            <SectionImage id="" color="light-gray" imgPosition="right" imgUrl={require('static/img-community.jpg')}>
              <Container>
                <Row>
                  <Col>
                    <h4 className="section-heading">Join a loving, supportive community</h4>
                    <IconList>
                      <IconListItem>
                        Share and <strong>celebrate</strong> your wins
                      </IconListItem>
                      <IconListItem>
                        Find connection with <strong>others who GET it</strong> and have faced similar situations
                      </IconListItem>
                      <IconListItem>
                        Get support so you <strong>never feel alone,</strong> no matter what comes up
                      </IconListItem>
                    </IconList>
                  </Col>
                </Row>
              </Container>
            </SectionImage>
            <Section id="cta-3" color="gradient-indigo-purple-right" innerClass="text-center text-white">
              <Container>
                <Row>
                  <Col>
                    <h3 className="section-heading">
                      You get all that and a LOT <br className="d-none d-sm-block d-xl-none" />
                      more in WHOLE Life Club.
                    </h3>
                    <h5 className="mt-0">
                      For the LAST time in 2022, the doors <br className="d-none d-sm-block d-xl-none" />
                      to WHOLE Life Club are open.{' '}
                    </h5>
                    <p>
                      (The ONLY other way to join for the rest of <br className="d-none d-sm-block d-md-none" />
                      the year will be <br className="d-none d-lg-block" />
                      after the purchase of select <br className="d-none d-sm-block d-md-none" />
                      FRN courses and other resources.)
                    </p>
                    <h3 className="mt-0">
                      Join now for just $1 – for <br className="d-none d-sm-block d-lg-none" />
                      your first month
                    </h3>
                    <CheckoutButton
                      sku="WLC"
                      price={isDollarTrial ? '1' : isSeries ? '127' : '147'}
                      slug={
                        isDollarTrial ? 'wlc-promo-30daytrial' : isSeries ? 'wlc-promo-special' : 'wlc-special-brain'
                      }
                    >
                      <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Buy Now
                    </CheckoutButton>
                    <SalesDisclaimer iconColor="white" />
                    {isDollarTrial ? (
                      <p className="text-small text-center mt-2 mb-0">
                        Join WHOLE Life Club for just $1 for your first month. After that,{' '}
                        <br className="d-none d-md-block d-lg-none" />
                        you’ll be enrolled for a special membership <br className="d-none d-lg-block" />
                        price of just $99 a year <br className="d-none d-md-block d-lg-none" />
                        (that’s 27 cents per day) unless you cancel before your month is up.
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Section>
          </>
        ) : null}

        <Section id="broadcast-tiles" color="white" className="section-angle-last" angle="none">
          {isSeries ? (
            <div className="broadcast-tiles mb-5">
              <Container>
                <Row>
                  <Col md="6" className="mb-4 mb-md-0">
                    <RecipeWlcDay />
                  </Col>
                  <Col md="6">
                    <Tile type="replay" />
                  </Col>
                </Row>
              </Container>
            </div>
          ) : null}
          <CommentSection id="comments" className="section-broadcast-comments mt-5" numPosts="10" broadcastPage />
        </Section>
      </div>

      <Footer />
      <ShareButtons floating />
    </div>
  );
}
