import React, {useState} from 'react';
import {navigate} from '@reach/router';
import classNames from 'classnames';
import ContactInfoForm from './components/ContactInfoForm';

import './OptinForm.scss';

export default function OptinFormWorkshop({
  id = 'optinFormWorkshop',
  linkColor = 'purple',
  onComplete = () => {},
  onStepComplete = () => {},
  entry,
  navigateUrl,
  formHeader = undefined,
  btnText = undefined,
  ...props
}) {
  const [step, setStep] = useState(0);
  return (
    <ContactInfoForm
      onComplete={() => {
        setStep(1);
        onStepComplete(1);
        onComplete();
      }}
      id={id}
      entry={entry}
      navigateUrl={navigateUrl}
      formHeader={formHeader}
      btnText={btnText}
    />
  );
}
