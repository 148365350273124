import React, {useCallback, useState} from 'react';
import {Col, Container, Row, Button, Alert} from 'reactstrap';
import {Link} from 'react-scroll';
import classNames from 'classnames';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';
import usePubNubSubscribe from 'utils/usePubNubSubscribe';

import PageMeta from 'components/PageMeta';
import {FrnHeader} from 'components/Header';
import Section from 'components/Elements/Section';
import CountdownTimer from 'components/Elements/CountdownTimer';
import Video from 'components/Video';
import ClosedCaptionModal from 'components/Broadcasts/ClosedCaptionModal';
import {ListIcon} from 'components/Elements/IconList';
import CommentSection, {CommentSectionInline} from 'components/CommentSection';
import Modal from 'components/Modal';
import Footer from 'components/Footer';

// @ts-ignore
import {days} from 'data/wlc-workshop.json';
import {ImgBulletCard} from 'components/Elements/ImgBullet';

export default function BroadcastIndex({
  pageTitle = 'The Thriving on a Plant-Based Diet Workshop',
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = 'default',
  version = 'broadcast',
  ...props
}) {
  const pageDescription =
    'Get actionable insights from world-leading experts on how to optimize your health with a plant-based diet. Join this free live workshop and start your journey towards better health and happiness!';
  const shareUrl = 'https://wlc.foodrevolution.org/workshop/';
  const ogImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-facebook-202405.png';
  const pinterestImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-pinterest-202405.png';
  const facebookDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const twitterDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const pinterestDescription =
    'Join us in this free live workshop to discover the benefits of a plant-based diet! Get practical tips from nutrition experts to improve your health and happiness. Register now for free and take your first step towards a healthier you!';

  const {
    phases: {
      workshop: workshopSchedule = {phase: 'before'},
      workshopBroadcast: broadcastSchedule = {phase: 'before', day: 0, message: {color: '', text: ''}}
    }
  } = usePhaseState();

  const workshopPhase = workshopSchedule.phase;
  const broadcastPhase = broadcastSchedule.phase;

  const beforeWorkshop =
    workshopPhase === 'before' ||
    (workshopPhase === 'during' && broadcastPhase === 'before' && broadcastSchedule.day === 0);
  const afterWorkshop = workshopPhase === 'after';

  const workshopDate = new Date('2024-06-01T09:00:00-07:00').getTime();
  const commentsUrl = 'https://wlc.foodrevolution.org/workshop/broadcast/';

  broadcastSchedule.day =
    broadcastSchedule.day && !isNaN(broadcastSchedule.day)
      ? Math.min(8, Math.max(0, parseInt(broadcastSchedule.day)))
      : 0;

  const [zoomInstructionsIsOpen, zoomInstructionsSetIsOpen] = useState(false);
  const zoomInstructionsOnClick = useCallback(() => zoomInstructionsSetIsOpen(true), []);
  const zoomInstructionsToggle = useCallback(
    () => zoomInstructionsSetIsOpen((zoomInstructionsIsOpen) => !zoomInstructionsIsOpen),
    []
  );

  useBalanceText();
  usePubNubSubscribe();
  return (
    <div className="page d-flex flex-column page-nosocial">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <FrnHeader
          shareUrl={shareUrl}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          logoUrl="https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-logo-white.svg"
          logoAlt="WHOLE Life Club Workshop logo"
          logoWidth={300}
          logoHeight={132}
        />

        <Section id="wlclw-broadcast-header" color="purple" innerClass="text-center text-white py-4" angle="none">
          <Container>
            <Row>
              <Col>
                <h1 className="section-heading text-h3 mb-3">
                  Welcome to the Thriving on a Plant-Based Diet: 10 Tips for Maximizing <i>Your</i> Health and Happiness
                  Live Workshop
                </h1>
                <h4 className="mt-0 mb-0">
                  {afterWorkshop || broadcastPhase === 'after' ? (
                    <>Access the Replay Below:</>
                  ) : (
                    <>Join LIVE June 1st, 2024</>
                  )}
                </h4>
                {beforeWorkshop && <CountdownTimer className="countdown-light mt-3" time={workshopDate} />}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          id="wlclw-broadcast-video"
          color="white"
          className="section-angle-first"
          innerClass="pt-0 pt-sm-4 pt-xl-5 my-auto"
        >
          {afterWorkshop || broadcastPhase === 'after' ? (
            <>
              <Container>
                <div className="text-center">
                  {days.map((day, i) => {
                    return (
                      <div key={'workshop-day-' + day.number} className={i === '2' ? 'mb-0' : 'mb-5'}>
                        <Video
                          url={
                            broadcastSchedule.youtubeUrl
                              ? broadcastSchedule.youtubeUrl
                              : 'https://www.youtube.com/watch?v=btM586fG8Rg'
                          }
                          label="WLC-LW - Broadcasts - Video"
                        />
                        <div
                          className={classNames(
                            `video-buttons`,
                            `text-center`,
                            `d-flex`,
                            `align-items-center`,
                            `justify-content-center`,
                            `mt-2`
                          )}
                        >
                          <Link
                            className="btn btn-medium-gray text-16 text-800 text-white px-4 py-2 mr-2"
                            activeClass="active"
                            spy={true}
                            offset={0}
                            to="comments"
                            smooth={true}
                            href="#"
                          >
                            Comment Now
                          </Link>
                          <ClosedCaptionModal />
                        </div>
                      </div>
                    );
                  })}
                  {afterWorkshop ? (
                    <h5 className="section-only-heading text-400">
                      The Thriving on a Plant-Based Diet Workshop has concluded, but please watch the replay above. If
                      you’re not enrolled in WHOLE Life Club yet, <a href="/workshop/whole/">check it out here</a>.
                    </h5>
                  ) : (
                    <h5 className="section-only-heading text-400">
                      The Thriving on a Plant-Based Diet Workshop has concluded, but please watch the replay above. If
                      you’re not enrolled in WHOLE Life Club yet, check out our{' '}
                      <a href="/workshop/whole/" target="_blank">
                        $1 risk-free trial
                      </a>
                      , which is only available until midnight Pacific Time on June 2nd, 2024.
                    </h5>
                  )}
                </div>
              </Container>
            </>
          ) : (
            <>
              <Container fluid>
                <Row className="px-xl-5">
                  <Col xs="12" lg="8" className="px-0 px-sm-3">
                    {broadcastSchedule.message && broadcastSchedule.message.text ? (
                      <Alert color={broadcastSchedule.message.color ? broadcastSchedule.message.color : 'primary'}>
                        {broadcastSchedule.message.text}
                      </Alert>
                    ) : null}
                    <Video
                      url={
                        broadcastSchedule.youtubeUrl
                          ? broadcastSchedule.youtubeUrl
                          : 'https://www.youtube.com/watch?v=btM586fG8Rg'
                      }
                      label="WLC-LW - Broadcasts - Video"
                      playing
                    />
                    <div
                      className={classNames(
                        `video-buttons`,
                        `text-center`,
                        `d-flex`,
                        `align-items-center`,
                        `justify-content-center`,
                        `mt-2`
                      )}
                    >
                      <ClosedCaptionModal />
                    </div>
                    <div className="text-center px-4 px-lg-5">
                      <p className="balance-text mt-5">
                        <b>How It Works:</b> On June 1st, 2024, we will begin the broadcast at 12 pm ET / 9 am PT / 4 pm
                        GMT.
                      </p>
                      <p className="balance-text">
                        After the workshop ends, it will be available on replay for a day. The workshop will run for
                        about 3 hours.
                      </p>
                      <p className="balance-text">
                        Join Food Revolution Network’s{' '}
                        <b>Ocean Robbins, Nichole Dandrea-Russert, RDN, Rosie Mackay, MS, Wilfredo Benitez, MScN,</b>{' '}
                        and special guest experts <b>Helen Powell-Stoddart, MD,</b> and <b>Joel Fuhrman, MD,</b> for a{' '}
                        <b>live, three-hour workshop</b> where you’ll learn about the foods proven to increase lifespan,
                        improve heart health, starve cancer, slash the danger of dementia, decrease type 2 diabetes
                        markers, and much more, and how to implement those foods into your life!
                      </p>
                      <h6 className="mt-4 mb-3">Click Here to Add This Event to Your Calendar</h6>
                      <div className="text-center">
                        <Button
                          title="Add to Calendar"
                          className="addeventstc"
                          data-id="ru21451273"
                          href="https://www.addevent.com/event/ru21451273"
                          target="_blank"
                          color="secondary"
                        >
                          Add to Calendar
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" lg="4" className="d-none d-lg-flex">
                    <div className="box-shadow-black-25 rounded p-3 w-100">
                      <CommentSectionInline
                        id="comments"
                        numPosts="10"
                        url={commentsUrl}
                        isBroadcastPage
                        shareUrl={shareUrl}
                        shareImage={ogImage}
                        facebookDescription={facebookDescription}
                        twitterDescription={twitterDescription}
                        pinterestDescription={pinterestDescription}
                        pinterestImage={pinterestImage}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </Section>

        <Section id="wlclw-broadcast-cta" color="purple" className="text-center text-white  text-h5">
          <Container>
            <Row>
              <Col>
                <div className="text-shadow-black-50">
                  <h2 className="section-heading">
                    Take the Next Step: Stand for Your Health with WHOLE Life Club! 🌱
                  </h2>
                  {workshopPhase === 'after' ? (
                    <>
                      <p className="balance-text">
                        Advance your journey to vibrant health today! Join WHOLE Life Club and tap into a world of
                        expert health guidance, amazing recipes, and an inspiring community ready to support you.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="balance-text">
                        Advance your journey to vibrant health today for <b>just $1!</b> Join WHOLE Life Club and tap
                        into a world of expert health guidance, over 1,200 plant-based recipes, and an inspiring
                        community ready to support you.
                      </p>
                      <p className="balance-text">
                        Special Offer for workshop participants only: Try it out for $1 for the first 30 days!{' '}
                        <i>After your trial, enjoy a special rate of just $197/year.</i>
                      </p>
                    </>
                  )}
                  <h2 className="mt-5 mb-3">Why Join?</h2>
                </div>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/health-coaches.jpg"
                  imageAlt="screenshot of cooking demo"
                >
                  Access Expert Health Insights: Weekly videos <br className="d-none d-xl-block" />
                  and actionable advice from top health experts.
                </ImgBulletCard>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/box-recipe.jpg"
                  imageAlt="whole foods bowl on table"
                >
                  Discover Delicious Recipes: Enjoy a variety of over 1,200 <br className="d-none d-xl-block" />
                  plant-based recipes that make healthy eating easy and enjoyable.
                </ImgBulletCard>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/box-community.jpg"
                  imageAlt="diverse group of people holding hands in a circle"
                >
                  Supportive Community: Connect with like-minded <br className="d-none d-xl-block" />
                  individuals who are all on their journey to better health.
                </ImgBulletCard>

                <p className="mt-3 mb-0">
                  <Button href="/workshop/whole/" color="cta" target="_blank">
                    Learn More
                  </Button>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <CommentSection
          id="comments"
          numPosts="10"
          url={commentsUrl}
          isBroadcastPage
          shareUrl={shareUrl}
          shareImage={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
        />
      </div>

      <Modal isOpen={zoomInstructionsIsOpen} toggle={zoomInstructionsToggle} size="lg">
        <Container className="px-4 px-sm-5 pt-0 pb-5" fluid>
          <Row className="d-flex justify-content-center">
            <Col>
              <h5 className="section-heading text-center">How to join meetings and webinars with the web client:</h5>
              <p>Users can join Zoom meetings or webinars from their web browser and bypass downloading Zoom.</p>
              <ol>
                <li>Locate the webinar or meeting link</li>
                <li>Click the join link to join the meeting</li>
                <li>If a pop-up window prompts you to open or install the Zoom desktop client, click Cancel</li>
                <li>At the bottom of the page, click the Join from your Browser link</li>
                <li>Click Join</li>
              </ol>
              <p>Note: If you don't see this option, make sure to enable Join From Browser.</p>
              <p>
                Note: If prompted, sign in to your Zoom account. If you do not have a Zoom account, create a new account
                here{' '}
                <a href="https://zoom.us/" target="_blank">
                  https://zoom.us/
                </a>
                . You will be prompted to enter your name and the meeting password if it was not included in the join
                link.
              </p>
            </Col>
          </Row>
        </Container>
      </Modal>

      <div className="footer-content mt-auto">
        <Footer />
      </div>
    </div>
  );
}
