import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import {faChevronCircleRight} from '@fortawesome/pro-light-svg-icons/faChevronCircleRight';
import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';
import useBeacon from 'utils/useBeacon';
import track from 'utils/track';

import PageMeta from 'components/PageMeta';
import Section, {SectionImage} from 'components/Elements/Section';
import HeaderVideo from 'components/Elements/HeaderVideo';
import CheckoutButton from 'components/CheckoutButton';
import WaitListForm, {WaitListFormTrigger} from 'components/WaitListForm';
import {SalesDisclaimer} from 'components/SalesCta';
import {ImgCard} from 'components/Elements/ImgBullet';
import {TestimonialBubble} from 'components/Testimonial';
import {GuaranteeBox} from 'components/Guarantee';
import CountdownTimer from 'components/Elements/CountdownTimer';
import IconList, {IconListItem, ListIcon} from 'components/Elements/IconList';
import {RecipeTileBox} from 'components/Elements/Tile';
import {ActionHourSpeakerList} from 'components/SpeakerList';
import ShareButtons from 'components/Social/ShareButtons';
import ModalExitSales from 'components/Whole/ModalExitSales';
import Footer from 'components/Footer';

// @ts-ignore
import {recipeTile} from 'data/recipe.json';
import {ExitModal} from 'components/Modal';

export default function SalesIndex({
  version = null,
  ctaOverride = null,
  ctaNoTimer = null,
  noExitIntent = false,
  noFixedVideo = false,
  pageTitle = 'Join WHOLE Life Club',
  pageDescription = 'Many people think that losing energy and getting saddled with chronic diseases is just part of getting older. But something very different is possible. WHOLE Life Club shows you the way.',
  shareUrl = 'https://wlc.foodrevolution.org/whole/',
  shareImage = 'https://cdn.foodrevolution.org/wlc/opengraph/WLC-opengraph-sales-1200x1200.png',
  facebookDescription = 'Discover the inspiration you need to embrace healthy, plant-based meals! Join WHOLE Life Club now to access 1200+ recipes, health coaches, dietitians, weekly guidance, and monthly guest experts. Don’t miss out! Embark on a nourishing journey!',
  twitterDescription = 'Discover the inspiration you need to embrace healthy, plant-based meals! Join WHOLE Life Club now to access 1200+ recipes, health coaches, dietitians, weekly guidance, and monthly guest experts. Don’t miss out! Embark on a nourishing journey!',
  pinterestDescription = 'Discover inspiration for healthy #PlantBasedMeals with 1200+ recipes, access to health coaches, dietitians, weekly guidance, and monthly guest experts. Don’t miss out! WHOLE Life Club enrollment is open. Embark on a nourishing journey! #HealthyEating #Wellness',
  pinterestImage = 'https://cdn.foodrevolution.org/wlc/opengraph/WLC-opengraph-sales-1000x1500.png',
  variant = 'default',
  ...props
}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: ''
      },
      sales: salesSchedule = {
        phase: ''
      },
      specialSales: specialSalesSchedule = {
        phase: ''
      },
      workshop: workshopSchedule = {
        phase: ''
      }
    }
  } = usePhaseState();

  const isRegularSale = salesSchedule.phase === 'during' || ctaOverride === 'isRegularSale';
  const isEvergreen = salesSchedule.phase === 'after';
  const isSales99 = ctaOverride === 'sales99';
  const isWorkshop = version === 'workshop';
  const isDollarSale = isRegularSale || isWorkshop;

  const isSales =
    isSales99 || isRegularSale || (isWorkshop && workshopSchedule.workshopSales === 'during') || isEvergreen;

  const salesVersion = version ? version : isSales99 ? 'sales99' : isDollarSale ? 'salesDollar' : 'salesFull';

  const dollarPrice = 1;
  const price99 = 99;
  const fullPriceYearly = 497;
  const fullPriceMonthly = 49;
  const discountPriceYearly = 197;
  const discountPriceMonthly = 29;
  const discountPennies = 0.54;

  const productSku = 'WLC';
  const productName = 'WHOLE Life Club';
  const productSlug = isSales99 ? 'wlc-99' : isDollarSale ? 'wlc-30daytrial-promo-197' : '497-wlc';
  const productValue = isSales99 ? price99 : isDollarSale ? dollarPrice : fullPriceYearly;

  const salesEnd = new Date(salesSchedule.salesEndDate).getTime();
  const sales99End = new Date(specialSalesSchedule.specialSalesEndDate).getTime();
  const workshopSalesEnd = new Date(workshopSchedule.workshopSalesEnd).getTime();
  const salesEndTime = isWorkshop ? workshopSalesEnd : isSales99 ? sales99End : salesEnd;
  const timerVisible = !ctaNoTimer && salesVersion != 'salesFull';

  const videoUrl = isWorkshop
    ? /* wlc-lw 202405 */ 'https://www.youtube.com/watch?v=6u8afD9OOmg'
    : isSales99
    ? /* sales price 99 */ 'https://www.youtube.com/watch?v=0_FbrZalxqQ'
    : isRegularSale
    ? /* 202401 launch 30day trial v1 */ 'https://www.youtube.com/watch?v=c11WbyOnJKY'
    : /* evergreen */ 'https://www.youtube.com/watch?v=G2XEpvMf3lU';

  const videoThumb = isWorkshop
    ? 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-thumb-sales-240514.gif'
    : 'https://cdn.foodrevolution.org/wlc/video-thumbs/wlc-dynamicthumb-202401.gif';

  useEffect(() => {
    track('Product Viewed', {
      product_id: productSku,
      sku: productSku,
      name: productName,
      value: Number(productValue).toFixed(2),
      variant: productSlug,
      currency: 'usd'
    });
  }, []);

  function SalesTimer({className = null}) {
    return (
      <div className={classNames(`timer`, className)}>
        <p className="balance-text mb-2">
          {
            {
              salesDollar: (
                <>
                  {
                    {
                      default: <>Your chance to join this inspiring community ends in:</>,
                      ad: <>Join this inspiring community before:</>
                    }[variant]
                  }
                </>
              ),
              sales99: <>Your chance to rejoin with 56% savings expires in:</>,
              workshop: <>This opportunity expires in:</>
            }[salesVersion]
          }
        </p>
        <CountdownTimer time={salesEndTime} />
      </div>
    );
  }

  function SalesSection({id, headline = null, trees = false, className = null, ...props}) {
    return (
      <Section id={id} color="purple" className={className} {...props}>
        <Container>
          <Row>
            <Col>{headline && <div className="cta-headline text-center text-white">{headline}</div>}</Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="10" xl="8">
              <div className="box-shadow-black-50 rounded">
                <div className="background-black text-white rounded-top p-3 p-lg-4">
                  <h3 className="text-uppercase text-center m-0">
                    {isSales99 ? <>Rejoin (And Save Big) Now!</> : <>Get Started Now!</>}
                  </h3>
                </div>
                <div className="background-white rounded-bottom px-4 px-lg-5 py-4">
                  {(() => {
                    switch (salesVersion) {
                      case 'salesDollar':
                      case 'workshop':
                        return (
                          <>
                            <h3 className="text-center text-green mt-0 mb-4">Join for just $1 today</h3>
                          </>
                        );
                      case 'sales99':
                        return (
                          <>
                            <h3 className="text-center text-green mt-0 mb-4">
                              Rejoin now for just <del>$29/month</del> $99 a year.
                            </h3>
                            <p className="balance-text text-center">
                              (This is a special “we want you back” rejoining offer for past members. It’s the lowest
                              price you’ll ever see.)
                            </p>
                          </>
                        );
                      case 'salesFull':
                        return (
                          <>
                            <h3 className="text-center text-green mt-0 mb-4">
                              Join for just ${fullPriceYearly}/year or just ${fullPriceMonthly}/month
                            </h3>
                          </>
                        );
                    }
                  })()}
                  <ListIcon>
                    <>
                      6 delicious, whole foods, <b>plant-powered recipes</b> per week.
                    </>
                    <>
                      <b>Weekly videos</b> from Ocean & friends, delivered to your inbox.
                    </>
                    <>
                      <b>Monthly Action Hour</b> events with health revolutionaries.
                    </>
                    <>
                      <b>Action Checklists</b> {variant === 'ad' ? 'to stay on track.' : 'to keep you on track.'}
                    </>
                    <>
                      <b>1,250+ fabulous recipes.</b>
                    </>
                    <>
                      A private, moderated <b>community.</b>
                    </>
                    <>
                      Live <b>Q&A and In the Kitchen calls</b> every month.
                    </>
                    <>
                      Access to <b>Health Coaches & Dietitians.</b>
                    </>
                    <>
                      Access to the entire library of <b>150+ videos.</b>
                    </>
                    <>
                      <b>500+ health articles.</b>
                    </>
                    <>
                      <b>Annual rates that will never go up</b> as long as you remain a member.
                    </>
                    <>
                      A <b>state-of-the-art membership site</b> that’s easy to navigate and much more!
                    </>
                  </ListIcon>
                  <div className="text-center">
                    {(() => {
                      switch (salesVersion) {
                        case 'salesDollar':
                        case 'workshop':
                          return (
                            <>
                              <h3 className="section-heading text-green mb-1">
                                Only ${dollarPrice} for a 30-day trial
                              </h3>
                              <h3 className="mt-0 mb-2 text-green">
                                Then <del>${fullPriceYearly}</del> ${discountPriceYearly}/year
                              </h3>
                              <h5 className="text-400 mt-0 mb-3">(That’s just ${discountPennies} a day)</h5>
                              <h5 className="mt-0 mb-4">
                                Or <del>${fullPriceMonthly}</del> ${discountPriceMonthly}/month
                              </h5>
                            </>
                          );
                        case 'sales99':
                          return (
                            <>
                              <h3 className="section-heading text-green mb-3">
                                <del>$29/month</del>
                              </h3>
                              <h5 className="mt-0 mb-4">
                                Save BIG when you rejoin with an annual membership — now (and now only) just
                                $99/year!(That’s less than $9/month!)
                              </h5>
                            </>
                          );
                        case 'salesFull':
                          return (
                            <>
                              <h3 className="section-heading text-green mb-1">${fullPriceYearly}/year</h3>
                              <h5 className="mt-0 mb-4">Or ${fullPriceMonthly}/month</h5>
                            </>
                          );
                      }
                    })()}
                    <CheckoutButton
                      className="offer-box-button"
                      sku={productSku}
                      price={Number(productValue).toFixed(2)}
                      slug={productSlug}
                      {...props}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        // @ts-ignore
                        icon={faCartPlus}
                      />{' '}
                      Buy Now
                    </CheckoutButton>
                    <SalesDisclaimer />
                    {(() => {
                      switch (salesVersion) {
                        case 'salesDollar':
                        case 'workshop':
                          return (
                            <>
                              <p className="text-small balance-text mt-4">
                                Join WHOLE Life Club for just ${dollarPrice} for your first 30 days. After that, you’ll
                                be enrolled for a special membership price of just ${discountPriceYearly} a year (that’s
                                ${discountPennies} cents per day) unless you cancel before your month is up. You can
                                also choose the ${discountPriceMonthly}/month option.
                              </p>
                            </>
                          );
                        case 'sales99':
                        case 'salesFull':
                          return null;
                      }
                    })()}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {trees && (
            <Row>
              <Col>
                <div className="mt-4">
                  <Row className="trees-for-future text-white d-flex align-items-center justify-content-center">
                    <Col xs="11" lg="8" className="mb-4 mb-lg-0">
                      <h6 className="section-only-heading text-center text-lg-start text-400">
                        For every new order, we make a donation to Trees for the Future, enabling them to plant another
                        organic fruit or nut tree in a low-income community.
                      </h6>
                    </Col>
                    <Col xs="11" lg="3" className="text-center">
                      <img
                        className={classNames(`logo-trees`)}
                        alt="Trees for the Future logo"
                        src="https://cdn.foodrevolution.org/global/trees-logo-white.png"
                        width="1080"
                        height="442"
                        style={{maxHeight: '100px', width: 'auto'}}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Section>
    );
  }

  useBalanceText();
  useBeacon();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImage} />

      <div className="page-content">
        {isSales && salesVersion != 'salesFull' ? (
          <Section id="whole-offer-banner" innerClass="py-4" color="purple" angle="none">
            <Container>
              <Row className="text-center text-white">
                <Col>
                  {
                    {
                      salesDollar: (
                        <>
                          <h3 className="section-heading mb-2">
                            <i>Special Opportunity</i>
                          </h3>
                          <h3 className="m-0">Try WHOLE Life Club for just $1</h3>
                        </>
                      ),
                      sales99: (
                        <>
                          <h3 className="section-heading">
                            Limited-Time WHOLE Life Club “We Want You Back” Membership Special You’ll Never See Again
                          </h3>
                          <h4 className="m-0">Don’t miss out!</h4>
                        </>
                      ),
                      workshop: (
                        <>
                          <h3 className="section-heading mb-2">
                            <i>Special Opportunity for Workshop participants!</i>
                          </h3>
                          <h3 className="m-0">Try WHOLE Life Club for just $1</h3>
                        </>
                      )
                    }[salesVersion]
                  }
                  {timerVisible && <SalesTimer className="mt-4" />}
                </Col>
              </Row>
            </Container>
          </Section>
        ) : null}

        <Section id="whole-header" className="section-angle-first" color="black" innerClass="text-white text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">
                  Why Most People Will Suffer From Pain, Brain Fog, and Chronic Disease as They Age — and How to Beat
                  Those Odds
                </h2>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <div className="px-3 px-sm-0">
                  <h6 className="section-heading text-uppercase">Watch This Important Message From Ocean Robbins:</h6>
                </div>
                <HeaderVideo
                  bgColor="black"
                  sku={productSku}
                  price={Number(productValue).toFixed(2)}
                  slug={productSlug}
                  url={videoUrl}
                  label="WLC - Sales"
                  sales
                  noFixed={isSales ? noFixedVideo : true}
                  thumbnail={videoThumb}
                  noButton
                />
              </Col>
            </Row>
            {isSales ? (
              <Row className="mt-5">
                <Col>
                  {(() => {
                    switch (salesVersion) {
                      case 'salesDollar':
                      case 'workshop':
                        return (
                          <>
                            <h3 className="section-heading mb-1">Only ${dollarPrice} for a 30-day trial</h3>
                            <h3 className="mt-0 mb-2">
                              Then <del>${fullPriceYearly}</del> ${discountPriceYearly}/year
                            </h3>
                            <h5 className="text-400 mt-0 mb-3">(That’s just ${discountPennies} a day)</h5>
                            <h5 className="mt-0 mb-4">
                              Or <del>${fullPriceMonthly}</del> ${discountPriceMonthly}/month
                            </h5>
                          </>
                        );
                      case 'sales99':
                        return (
                          <>
                            <h3 className="mt-0 mb-3">
                              Rejoin now for just <del>$29/month</del> $99 a year.
                            </h3>
                            <p className="balance-text">
                              (This is a special “we want you back” rejoining offer for past members. It’s the lowest
                              price you’ll ever see.)
                            </p>
                          </>
                        );
                      case 'salesFull':
                        return (
                          <>
                            <h2 className="mt-0 mb-2">Join WHOLE Life Club for ${fullPriceYearly}/year</h2>
                            <h4 className="mt-0 mb-4">or ${fullPriceMonthly}/month</h4>
                          </>
                        );
                    }
                  })()}
                  <CheckoutButton
                    sku={productSku}
                    price={Number(productValue).toFixed(2)}
                    slug={productSlug}
                    {...props}
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      // @ts-ignore
                      icon={faCartPlus}
                    />{' '}
                    Buy Now
                  </CheckoutButton>
                  <SalesDisclaimer iconColor="white" />
                  {(() => {
                    switch (salesVersion) {
                      case 'salesDollar':
                      case 'workshop':
                        return (
                          <>
                            <p className="balance-text mt-4">
                              Join WHOLE Life Club for just ${dollarPrice} for your first 30 days. After that, you’ll be
                              enrolled for a special membership price of just ${discountPriceYearly} a year (that’s $
                              {discountPennies} cents per day) unless you cancel before your 30 days are up. You can
                              also choose the ${discountPriceMonthly}/month option.
                            </p>
                          </>
                        );
                      case 'sales99':
                      case 'salesFull':
                        return null;
                    }
                  })()}
                </Col>
              </Row>
            ) : (
              <>
                <Row className="mt-4">
                  <Col>
                    <h3 className="section-heading mb-2">Registration opens twice per year.</h3>
                    <h5 className="mt-0">
                      Click the button below to be notified when we open the doors to registration next:
                    </h5>
                    <WaitListFormTrigger className="mb-4" />
                    <p className="balance-text mb-0">
                      Read on to find out how WHOLE Life Club works so you can join in when your time comes.
                    </p>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Section>

        <SectionImage
          id="whole-fact-1"
          color="white"
          imgPosition="right"
          // @ts-ignore
          imgUrl={require('static/backgrounds/1251719888.jpg')}
        >
          {
            {
              default: (
                <>
                  <h2 className="section-heading">Are you ready for it to be easy?</h2>
                  <p>
                    Instead of stressing when you are figuring out what’s for dinner, what if you had a one-stop-shop
                    for recipes you know will both taste delicious <i>and</i> do your body good?
                  </p>
                  <p>
                    Rather than being afraid of not getting the memo on some important health fact, what if you could
                    rest easy because you heard it directly from a world renowned expert with decades of experience and
                    thousands of success stories?
                  </p>
                  <p>
                    Most importantly, what if you felt <b>totally confident</b> knowing that you’re taking{' '}
                    <i>excellent</i> care of your day-to-day and long-term health?
                  </p>
                  <p>
                    Join WHOLE Life Club today and experience the freedom of effortless health. Say goodbye to
                    confusion, frustration, and guilt, and hello to a vibrant, thriving life. With the right tools and
                    support, you can make healthy living a reality. Are you ready to join us?
                  </p>
                </>
              ),
              ad: (
                <>
                  <h2 className="section-heading text-uppercase">It’s a Fact</h2>
                  <p>
                    <b>
                      The most cutting-edge and reputable scientific research shows that when we eat the right foods:
                    </b>
                  </p>
                  <IconList className="mb-0">
                    <IconListItem>
                      It’s possible to <b>slash the risk</b> of the world’s most dangerous health conditions.
                    </IconListItem>
                    <IconListItem>
                      Fatigue, sickness, and brain fog can <b>become a thing of the past.</b>
                    </IconListItem>
                    <IconListItem>
                      <b>Mental health can steadily and measurably improve.</b>
                    </IconListItem>
                    <IconListItem>
                      <b>Deep sleep</b> and <b>vibrant energy can become the norm,</b> and{' '}
                      <b>unhealthy food cravings can vanish.</b>
                    </IconListItem>
                    <IconListItem>
                      We <b>improve the planet,</b> thanks to fewer pesticides, GMOs, carbon emissions, and less soil
                      erosion.
                    </IconListItem>
                  </IconList>
                </>
              )
            }[variant]
          }
        </SectionImage>

        <Section id="whole-testimonial-1" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-3">What do members have to say?</h2>
                <p className="balance-text text-center text-16 mb-5">
                  <i>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. Their testimonials reflect their unique experiences. Your specific results will vary.
                  </i>
                </p>
                {[
                  'wlcKathyWShort',
                  'wlcRobinShort',
                  'wlcTina',
                  variant === 'ad' ? 'wlcHeidiAd' : 'wlcHeidi',
                  'wlcJean'
                ].map((key) => (
                  <TestimonialBubble bgColor="white" id={key} key={key} />
                ))}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-fact-2" color="white" className="text-center">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">
                  Millions of people are realizing that eating more plant-based and whole foods is the #1 thing we can
                  do for
                </h3>
                <div className="d-flex justify-content-center text-left mb-4">
                  <IconList className="m-0">
                    <IconListItem>
                      <h4 className="section-only-heading">health</h4>
                    </IconListItem>
                    <IconListItem>
                      <h4 className="section-only-heading">longevity</h4>
                    </IconListItem>
                    <IconListItem>
                      <h4 className="section-only-heading">
                        disease prevention <i>and</i> reversal
                      </h4>
                    </IconListItem>
                    <IconListItem>
                      <h4 className="section-only-heading">environmental benefits</h4>
                    </IconListItem>
                    <IconListItem>
                      <h4 className="section-only-heading">and animal welfare.</h4>
                    </IconListItem>
                  </IconList>
                </div>
                <h5 className="text-400 mt-0">
                  <i>But no matter how much {variant === 'ad' ? 'we' : 'you'} know...</i>
                </h5>
                <h4 className="mt-0">
                  It’s <i>what {variant === 'ad' ? 'we' : 'you'} DO</i> that matters most.
                </h4>
                {
                  {
                    default: (
                      <>
                        <p className="balance-text">
                          Think about it for a minute. Your body doesn’t care if you know what you “should” eat — but go
                          for pizza or fries because you’re tired and it’s easy. Your body doesn’t give you any points
                          for good intentions if you’re still eating foods that can leave you saddled with a chronic
                          illness and a shortened lifespan.
                        </p>
                        <p className="balance-text">
                          And if you are eating the wrong foods that sabotage your health because you simply don’t know
                          any better?
                        </p>
                        <p className="balance-text">
                          You might be lucky to get a second chance. But it’s going to be a lot easier if you find out{' '}
                          <i>before</i> major damage is done.
                        </p>
                        <p className="balance-text">
                          <b>
                            WHOLE Life Club gives you the best of both worlds: scientifically research-backed insights
                            AND tools to put them into <i>action.</i>
                          </b>
                        </p>
                      </>
                    ),
                    ad: (
                      <p className="balance-text">
                        WHOLE Life Club is both: the best scientifically research-backed insights AND tools to put them
                        into <i>action.</i>
                      </p>
                    )
                  }[variant]
                }
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-eating" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-5">
                  When it comes to eating for energy, optimal health, and feeling GOOD again…
                </h2>
              </Col>
            </Row>
            <ImgCard
              imgColLg="4"
              textColLg="8" // @ts-ignore
              img={require('static/eating-keep-up.jpg')}
            >
              <h4 className="section-heading">There’s a lot to keep up with.</h4>
              <p>“Where will I get my protein?”</p>
              <p>
                “I’m eating right, but I’m still overweight. <br className="d-none d-sm-block" />
                What am I doing wrong?”
              </p>
              <p>
                “Will I become iron-deficient <br className="d-none d-sm-block d-lg-none" />
                if I go plant-based?”
              </p>
            </ImgCard>
            <ImgCard
              imgColLg="4"
              textColLg="8" // @ts-ignore
              img={require('static/eating-information.jpg')}
            >
              <h4 className="section-heading">New information comes out every day.</h4>
              <p>“Hmmm… this article says tofu is good, but I also heard it increases estrogen.”</p>
              <p>
                “What about calcium? I’m concerned about osteoporosis <br className="d-none d-xl-block" />
                and weak bones.”
              </p>
            </ImgCard>
            <ImgCard
              imgColLg="4"
              textColLg="8" // @ts-ignore
              img={require('static/eating-on-board.jpg')}
            >
              <h4 className="section-heading">
                {variant === 'ad' ? (
                  <>Family might not be 100% on board, and say things like:</>
                ) : (
                  <>Your family might not be 100% on board, and you might hear things like:</>
                )}
              </h4>
              <p>“Black bean burgers? Again?”</p>
              <p>“Can’t we just get take out?”</p>
              <p>“C’mon, one bite won’t hurt you.”</p>
            </ImgCard>
            <ImgCard
              imgColLg="4"
              textColLg="8" // @ts-ignore
              img={require('static/eating-better.jpg')}
            >
              <h4 className="section-heading">
                {variant === 'ad' ? (
                  <>
                    We all <i>want</i> to do better, but...
                  </>
                ) : (
                  <>
                    You <i>want</i> to do better, but find yourself saying...
                  </>
                )}
              </h4>
              <p>
                “I can’t seem to get over cravings for <br className="d-none d-sm-block d-md-none" />
                cookies / potato chips / <br className="d-none d-xl-block" />
                my nighttime <br className="d-none d-sm-block d-md-none" />
                bowl of ice cream.”
              </p>
              <p>
                “Veggies aren’t as tasty as pizza. <br className="d-none d-sm-block d-md-none" />
                Can I really <br className="d-none d-xl-block" />
                learn to love them?”
              </p>
              <p>
                “How do I find time to cook <br className="d-none d-sm-block d-md-none" />
                when I’m already busy?”
              </p>
            </ImgCard>
          </Container>
        </Section>

        <SectionImage
          id="whole-track"
          color="white"
          imgPosition="right"
          // @ts-ignore
          imgUrl={require('static/backgrounds/woman-carrot-thinking-square.jpg')}
        >
          <h2 className="section-heading">Even when you know what {variant === 'ad' ? 'we' : 'you'} “should” do...</h2>
          <h4 className="mt-0">
            {variant === 'ad' ? (
              <>What’s the best way to stay inspired and on track?</>
            ) : (
              <>How do you stay inspired and on track?</>
            )}
          </h4>
          <IconList iconColor="purple">
            <IconListItem icon={faChevronCircleRight}>
              Cooking plant-based meals and keeping it interesting?
            </IconListItem>
            <IconListItem icon={faChevronCircleRight}>Staying on top of the most critical health topics?</IconListItem>
            <IconListItem icon={faChevronCircleRight}>
              {variant === 'ad' ? (
                <>Eating in a way that doesn’t contribute to climate pollution or animal cruelty?</>
              ) : (
                <>
                  Eating in a way that supports your values and doesn’t contribute to climate pollution or animal
                  cruelty?
                </>
              )}
            </IconListItem>
          </IconList>
          <h4 className="mt-0">
            {variant === 'ad' ? (
              <>
                Want the quickest way to go from simply <i>wanting</i> to eat healthier to actually <i>enjoying</i>{' '}
                foods that are recommended by top doctors and health experts?
              </>
            ) : (
              <>
                How do you go from simply <i>wanting</i> to eat healthier to actually <i>enjoying</i> foods that give
                you a long, vibrant life?
              </>
            )}
          </h4>
        </SectionImage>

        <Section id="whole-testimonial-2" color="black" innerClass="text-white">
          <Container>
            <Row>
              <Col>
                {['wlcJuanita'].map((key) => (
                  <TestimonialBubble bgColor="white" textColor="black" id={key} key={key} />
                ))}
                <p className="balance-text text-center text-small mt-5">
                  <i>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. Their testimonials reflect their unique experiences. Your specific results will vary.
                  </i>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-recipes" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-5">
                  Stay On Track & In Love With Over 1,200 Plant-Based Recipes
                </h2>
              </Col>
            </Row>
            <Row>
              {recipeTile
                .filter((recipeTile) => recipeTile.id != 'lemon-thyme-brussels-sprouts')
                .map((recipeTile, i) => (
                  <Col lg="6" className="mb-4" key={`recipeTile` + i}>
                    <RecipeTileBox id={recipeTile.id} key={recipeTile.id} recipeTile />
                  </Col>
                ))}
            </Row>
            <Row>
              <Col>
                <h3 className="text-center">And many, many more!</h3>
              </Col>
            </Row>
          </Container>
        </Section>

        {!isSales99 && (
          <>
            <Section id="whole-intro" color="white">
              <Container>
                <Row className="d-flex justify-content-center">
                  <Col lg="10">
                    <h4 className="section-heading text-center">Introducing...</h4>
                    <h2 className="section-heading text-center">WHOLE Life Club</h2>
                    <h5 className="mt-0 text-400 text-center">
                      <i>Motivation & Inspiration for {variant === 'ad' ? 'the' : 'your'} Healthiest Life</i>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img
                      src="https://cdn.foodrevolution.org/wlc/wlc-product-image-v20220329.png"
                      width="1200"
                      height="500"
                      alt="WHOLE Life Club graphic"
                    />
                    <h2 className="text-400 text-center mt-5">
                      Goodbye, bad habits. <br />
                      <b>Hello, good ones.</b>
                    </h2>
                    <h2 className="text-400 text-center mt-0">
                      Goodbye, confusion. <br />
                      <b>Hello, clarity.</b>
                    </h2>
                    <h2 className="text-400 text-center mt-0">
                      Goodbye, swimming upstream. <br />
                      <b>Hello, wind at your back.</b>
                    </h2>
                  </Col>
                </Row>
              </Container>
            </Section>
          </>
        )}

        <Section id="whole-more" color="green" innerClass="text-white">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <h2 className="section-only-heading text-center">
                  WHOLE Life Club is <i>so much more</i> than just food
                </h2>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-2" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Weekly Videos From Ocean Robbins & Friends
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img
                  className="img-border rounded"
                  // @ts-ignore
                  src={require('static/wlc-image-sales-page-ocean-nichole-aow.jpg')}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  {variant === 'ad' ? (
                    <>
                      No one is born with an instruction manual on how to eat and what to do, but in WHOLE Life Club,
                      there’s no missing out on the most important health information.
                    </>
                  ) : (
                    <>
                      No one is born with an instruction manual on how to eat and what to do, but when you{' '}
                      {isSales99 ? 'rejoin' : 'join'} WHOLE Life Club, you can be sure you won’t miss out on the most
                      important and life-changing information.
                    </>
                  )}
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>
                      Weekly Guides are like getting a private message from Ocean (and friends) each week, where he
                      shares the truth on MUST-KNOW topics.
                    </>
                  ) : (
                    <>
                      Weekly Guides are like getting a private message from Ocean (and friends) each week, where he
                      shares the truth on MUST-KNOW topics that can transform your life.
                    </>
                  )}
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>
                      Get weekly dispatches on topics like healthy versus unhealthy fats, adrenals, immune-supporting
                      herbs, adaptogens, fitness myths, foods that beat chronic disease, the truth about vitamins and
                      minerals, practical tips (like learning how to say no and staying positive), and so much more.
                    </>
                  ) : (
                    <>
                      You’ll get weekly dispatches on topics like healthy versus unhealthy fats, adrenals,
                      immune-supporting herbs, adaptogens, fitness myths, cancer-kicking foods, the truth about vitamins
                      and minerals, practical tips (like learning how to say no and stay positive), and so much more.
                    </>
                  )}
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>With Ocean and friends by your side, it’s easy to stay motivated.</>
                  ) : (
                    <>
                      With Ocean and friends by your side, you’ll stay vibrant and motivated and keep taking action to
                      be your best YOU.
                    </>
                  )}
                </p>
                <TestimonialBubble id="wlcGenevieve" className="mt-5" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-2" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">1,200+ Plant-Based Recipes</h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img // @ts-ignore
                  src={require('static/recipe.png')}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  {variant === 'ad' ? (
                    <>
                      No more staying stuck in food ruts with the same meals over and over. Get new recipes each week
                      that dovetails perfectly with Ocean’s weekly guidance.
                    </>
                  ) : (
                    <>
                      No more staying stuck in food ruts with the same meals over and over. Each week, you’ll get new
                      recipes that dovetail perfectly with Ocean’s weekly guidance.
                    </>
                  )}
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>
                      Discover superstars of nutrition with exciting recipes that showcase new ways to delight taste
                      buds (and the family’s, too)!
                    </>
                  ) : (
                    <>
                      Discover superstars of nutrition with exciting recipes that showcase new ways to delight your
                      taste buds (and your family, too)!
                    </>
                  )}
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>
                      For example, when we learn about zinc and how it has been found to shorten colds, that week’s
                      recipes will be zinc-rich classics like
                    </>
                  ) : (
                    <>
                      For example, when you learn about zinc and how it has been found to shorten colds, you’ll get
                      zinc-rich recipes like
                    </>
                  )}{' '}
                  <i>Ginger Miso Dressing,</i> <i>Creamy Lentil Spinach Dahl,</i> and <i>Chocolate Almond Mousse.</i>
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>
                      When the topic is avoiding anemia, enjoy adding more iron with recipes like{' '}
                      <i>Orange-Miso-Glazed Tofu</i> and <i>Chocolate Raspberry Smoothie.</i>
                    </>
                  ) : (
                    <>
                      When the topic is avoiding anemia, you’ll love adding more iron to your plate with recipes like{' '}
                      <i>Orange-Miso-Glazed Tofu</i> and <i>Chocolate Raspberry Smoothie.</i>
                    </>
                  )}
                </p>
                <p>
                  Each recipe is a super-healthy crowd pleaser — always vegan, with gluten-free / soy-free / salt-free
                  options — and mega-delicious, so everyone can partake.
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>
                      Get the entire collection of over 500 WHOLE Life Club recipes instantly with membership to WHOLE
                      Life Club.
                    </>
                  ) : (
                    <>
                      The instant you {isSales99 ? 'rejoin' : 'join'}, you’ll have the entire collection of WHOLE Life
                      Club recipes at your fingertips.
                    </>
                  )}
                </p>
                <TestimonialBubble bgColor="white" id="wlcLydia" className="mt-5" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-3" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Monthly Action Hours with Leading Experts
                </h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  {variant === 'ad' ? (
                    <>
                      Get front-row seats to the latest science from doctors who are New York Times best-selling authors
                      and world-famous health experts.
                    </>
                  ) : (
                    <>
                      Would you like front-row seats to the latest science from doctors who are New York Times
                      best-selling authors and world-famous health experts?
                    </>
                  )}
                </p>
                <p>
                  We’re talking about training from true masters in their fields with serious credentials behind their
                  names and the success stories to back it up — not lifestyle bloggers, uncertified influencers, or
                  marketers who are trying to sell you something.
                </p>
                <p>
                  <b>
                    As a WHOLE Life Club member, you’ll be treated to a new monthly Action Hour every month, plus
                    instant access to our entire library of {variant === 'ad' ? '50+' : 'past'} Action Hours with health
                    heroes like:
                  </b>
                </p>
                <Row className="justify-content-center my-4">
                  <ActionHourSpeakerList />
                </Row>
                <h4 className="text-center mt-0">Get the scoop on topics such as:</h4>
                <div className="d-flex justify-content-center mb-4">
                  <IconList className="m-0">
                    <IconListItem>Longer lifespans</IconListItem>
                    <IconListItem>
                      {variant === 'ad' ? <>Eating to defeat free radicals</> : <>Eating to defeat cancer</>}
                    </IconListItem>
                    <IconListItem>Ending food addiction</IconListItem>
                    <IconListItem>Conquering autoimmune disease</IconListItem>
                    <IconListItem>Healthy hormones</IconListItem>
                    <IconListItem>Calming anxiety naturally</IconListItem>
                    <IconListItem>How to eat plant-based on a budget</IconListItem>
                    <IconListItem>Better sleep</IconListItem>
                    <IconListItem>{variant === 'ad' ? <>Gut health</> : <>How to heal your gut</>}</IconListItem>
                    <IconListItem>Dental health</IconListItem>
                    <IconListItem>Your food and your mood</IconListItem>
                    <IconListItem>How to master diabetes with food & lifestyle</IconListItem>
                    <IconListItem>{variant === 'ad' ? <>Heart disease</> : <>Avoiding heart disease</>}</IconListItem>
                    <IconListItem>And more</IconListItem>
                  </IconList>
                </div>
                <p>
                  {variant === 'ad' ? (
                    <>
                      All members have the opportunity to ask the featured experts questions in advance so they can
                      address them during their sessions.
                    </>
                  ) : (
                    <>
                      As a member, you’ll have the opportunity to ask the featured experts questions in advance so they
                      can address them during their sessions. Plus, the instant you {isSales99 ? 'rejoin' : 'join'} the
                      WHOLE Life Club, you’ll have access to the entire library of 65+ Action Hours.
                    </>
                  )}
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>
                      Each session includes the recorded audio to download, plus handouts, implementation checklists,
                      and articles to learn more — and if you prefer to read, you’ll love getting the beautifully
                      written transcripts of all Action Hours.
                    </>
                  ) : (
                    <>
                      You’ll get the recorded audio to download, plus handouts, implementation checklists, and articles
                      to learn more — and if you prefer to read, you’ll love getting the written transcripts.
                    </>
                  )}
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-events" color="light-gray" className="text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase">Monthly Live Events</h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center mb-5">
              <Col sm="10" lg="8">
                <img
                  className="img-border rounded"
                  // @ts-ignore
                  src={require('static/wlc-image-sales-page-monthly-live-events.jpg')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="balance-text">
                  As a WHOLE Life Club member, you'll have the exclusive opportunity to connect with both your fellow
                  members and our dedicated WLC team during our monthly live events. These events are the heart of our
                  community, designed to support you on your journey to better health and vitality.
                </p>
                <h5 className="mt-4">What can you expect from our Monthly Live Events?</h5>
                <p className="balance-text">
                  <b>Culinary Demo Events:</b> Dive into the world of delicious, nutritious cooking as you gain valuable
                  culinary skills and explore new recipes that will nourish your body and soul.
                </p>
                <p className="balance-text">
                  <b>Culinary and Health Coaching Q&A Calls:</b> Get your burning questions answered by our seasoned
                  dietitians, nutritionists, and coaches. Whether it’s about food, fitness, or lifestyle, our team is
                  here to provide you with personalized support.
                </p>
                <p className="balance-text">
                  <b>Community Connection Calls:</b> Loneliness and isolation can make it tough to stick to a healthy
                  lifestyle. Our Community Connection Calls offer you the chance to build meaningful relationships with
                  like-minded individuals who share your passion for a healthier life. Forge connections, exchange
                  experiences, and feel the power of belonging to a supportive community.
                </p>
                <p className="balance-text">
                  Dietary and lifestyle changes don’t have to be a solitary journey. Our Monthly Live Events are your
                  gateway to finding the support, guidance, and camaraderie you need to thrive.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-4a" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Access to Registered Dietitians, Licensed Nutritionists, and Brilliant Health Coaches
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img
                  className="img-border rounded"
                  // @ts-ignore
                  src={require('static/health-coaches.jpg')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 className="mt-0 text-center">
                  {variant === 'ad' ? (
                    <>We know how difficult it can be to navigate health questions or new diagnoses alone.</>
                  ) : (
                    <>We know how difficult it can be to navigate health questions or new diagnoses on your own.</>
                  )}
                </h4>
                <h5 className="mt-0 text-center text-400">
                  {variant === 'ad' ? (
                    <>
                      That’s why our WHOLE Life Club coaches are here... to give answers to the most pressing nutrition
                      questions!
                    </>
                  ) : (
                    <>
                      That’s why our WHOLE Life Club coaches are here to hold your hand... so you get answers to your
                      most pressing nutrition questions!
                    </>
                  )}
                </h5>
                <div className="d-flex justify-content-center">
                  <IconList iconColor="purple" className="mb-0">
                    <IconListItem icon={faQuestionCircle}>
                      Wondering which foods are good for heart health?
                    </IconListItem>
                    <IconListItem icon={faQuestionCircle}>
                      Unclear about the difference between (and optimal ratio for) <br className="d-none d-lg-block" />
                      omega-3s and omega-6s?
                    </IconListItem>
                    <IconListItem icon={faQuestionCircle}>
                      Have a loved one with a disturbing diagnosis, and wonder <br className="d-none d-lg-block" />
                      what the studies say about how food can help?
                    </IconListItem>
                  </IconList>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="whole-more-4b"
          color="white" // @ts-ignore
          imgUrl={require('static/backgrounds/1276385699.jpg')}
        >
          <h3 className="section-heading">
            {variant === 'ad' ? (
              <>Anyone who’s ever tried to find answers</>
            ) : (
              <>If you’ve ever tried to find answers</>
            )}
          </h3>
          <p>
            {variant === 'ad' ? (
              <>
                on YouTube or random blogs knows how frustrating it can be to read ten articles that all seem to
                contradict each other or don’t go deep enough.
              </>
            ) : (
              <>
                on YouTube or random blogs, then you know how frustrating it can be to read ten articles that all seem
                to contradict each other or don’t go deep enough.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Leery of placing your health in the hands of the peanut gallery in Facebook groups? Can’t ever seem to
                squeeze in all your questions at the doctor’s office? We understand.
              </>
            ) : (
              <>
                If you are leery of placing your health in the hands <br className="d-none d-xl-block" />
                of the peanut gallery in Facebook groups, or can never seem to squeeze in all your questions with your
                doctor, <br className="d-none d-xl-block" />
                we understand.
              </>
            )}
          </p>
        </SectionImage>

        <Section id="whole-more-4c" color="white">
          <Container>
            <Row>
              <Col>
                <h3 className="section-only-heading text-center">
                  {variant === 'ad' ? (
                    <>
                      That’s why we created a way to get ALL those big and small questions answered by our experienced
                      Community Success team.
                    </>
                  ) : (
                    <>
                      That’s why we created a way for you to get ALL your questions answered by our experienced
                      Community Success team.
                    </>
                  )}
                </h3>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center my-5">
              <Col xs="4" md="auto" className="px-2">
                <img
                  className="img-border rounded-circle"
                  src="https://cdn.foodrevolution.org/global/team/frn-guest_expert-portrait-square-wilfredobenitez.jpg"
                  style={{maxHeight: '200px'}}
                />
              </Col>
              <Col xs="4" md="auto" className="px-2">
                <img
                  className="img-border rounded-circle"
                  src="https://cdn.foodrevolution.org/global/team/frn-guest_expert-portrait-square-roselynnemackay.jpg"
                  style={{maxHeight: '200px'}}
                />
              </Col>
              <Col xs="4" md="auto" className="px-2">
                <img
                  className="img-border rounded-circle"
                  src="https://cdn.foodrevolution.org/global/team/nichole-d.jpg"
                  style={{maxHeight: '200px'}}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center text-h5 mb-5">
              <Col xs="12" lg="5" className="mb-4 mb-lg-0">
                <img // @ts-ignore
                  src={require('static/coaches.png')}
                />
              </Col>
              <Col xs="12" lg="7">
                <p className="balance-text">
                  {variant === 'ad' ? (
                    <>
                      Our team includes{' '}
                      <b>registered dietitians, licensed nutritionists, and professional health coaches</b> and has
                      encountered just about everything and will give you feedback and{' '}
                      <b>insights based on the latest studies and research.</b>
                    </>
                  ) : (
                    <>
                      Our team includes{' '}
                      <b>registered dietitians, licensed nutritionists, and professional health coaches</b> and has
                      encountered just about everything and will provide feedback and{' '}
                      <b>insights based on the latest studies and research.</b>
                    </>
                  )}
                </p>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center text-h5">
              <Col xs="12" lg="5" className="order-lg-2 mb-4 mb-lg-0">
                <img // @ts-ignore
                  src={require('static/articles.png')}
                />
              </Col>
              <Col xs="12" lg="7" className="order-lg-1">
                <p>Want to dig deeper?</p>
                <p className="balance-text">
                  {variant === 'ad' ? (
                    <>
                      Our team can direct you to one of <b>our 500+ articles</b> on <b>just about any health issue</b>{' '}
                      that pops up. This kind of help can be a true lifesaver. Our team is ready to offer you{' '}
                      <b>exceptional support.</b>
                    </>
                  ) : (
                    <>
                      Our team can direct you to one of <b>our 500+ articles</b> so you can get the{' '}
                      <b>best insights on just about any health issue</b> that pops up. This kind of help can be a true
                      lifesaver. Our team is ready to offer you <b>exceptional support.</b>
                    </>
                  )}
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="whole-more-5"
          color="light-gray" // @ts-ignore
          imgUrl={require('static/img-community.jpg')}
        >
          <h2 className="section-heading text-uppercase">Community (Uplifting Support)</h2>
          <p>
            {variant === 'ad' ? (
              <>No naysayers here! We know it can be tough to stick to goals alone. </>
            ) : (
              <>
                No naysayers here! We know it can be tough to stick to goals if you’re on your own. You deserve a safe
                space where you are supported by others who are on the same journey as you.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                In a world where the status quo revolves around toxic food and encourages us to put up with feeling
                worse each year, we need to surround ourselves with others who share our values of healthy food,
                delicious recipes, and making a better planet for future generations.
              </>
            ) : (
              <>
                In a world where the status quo revolves around toxic food and encourages you to put up with feeling
                worse each year, we need to surround ourselves with others who share our values of healthy food,
                delicious recipes, and making a better planet for future generations.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Find encouragement in this warm and welcoming group and a space to ask questions and cheer each other on
                as we celebrate wins — and the truth is, we celebrate often.
              </>
            ) : (
              <>
                In this warm and welcoming group, you’ll find encouragement and a space to ask questions and cheer each
                other on as we celebrate wins — and the truth is, we celebrate often.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>What a difference this level of support makes!</>
            ) : (
              <>You simply have to experience for yourself what a difference this kind of support will make!</>
            )}
          </p>
        </SectionImage>

        <Section id="" color="light-gray" innerClass="pt-0">
          <Container>
            <Row>
              <Col>
                <TestimonialBubble bgColor="white" id="wlcBarbara" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="whole-more-6"
          color="white"
          imgPosition="right"
          // @ts-ignore
          imgUrl={require('static/backgrounds/606196416.jpg')}
        >
          <h2 className="section-heading text-uppercase">Cutting-Edge Science</h2>
          <p>We are fortunate to live in the most scientifically advanced period in the history of the world.</p>
          <p>
            However, there are too many poorly conducted scientific studies cited in articles that are nothing more than
            hyped-up marketing…making it nearly impossible to find accurate information on the topics you care about.
            This is wrong. You deserve the truth.
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Getting the right information makes all the difference when it comes to topics you care about. That’s
                why we explain the science in language you can understand and provide links to credible research so you
                can be sure it’s grounded in solid evidence.
              </>
            ) : (
              <>
                Getting the right information makes all the difference when it comes to topics like cancer, autoimmune
                disorders, heart disease, thyroid health, weight and metabolism, pain, Alzheimer’s, and more. That’s why
                we explain the science in language you can understand and provide links to credible research so you can
                be sure it’s grounded in solid evidence.
              </>
            )}
          </p>
          <p>
            New discoveries are made daily — and you can count on us to keep you up-to-date with breaking news from
            reputable sources.
          </p>
        </SectionImage>

        <SectionImage
          id="whole-themes-1"
          color="light-gray"
          imgPosition="left"
          imgUrl="https://cdn.foodrevolution.org/eattherainbow/images/1435001922-square.jpg"
        >
          <h3 className="section-heading">Here are some recent WHOLE Life Club topics</h3>
          <p>
            <i>
              You’ll get them all instantly — the <br className="d-none d-lg-block d-xl-none" />
              moment you join!
            </i>
          </p>
          <IconList>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  <b>Foods to Thrive in Every Cell</b> — see how it’s possible to prevent and heal.
                </>
              ) : (
                <>
                  <b>Foods to Fight Cancer and Thrive in Every Cell</b> — see how it’s possible to prevent and even take
                  steps that help beat cancer.
                </>
              )}
            </IconListItem>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  <b>Preventing Cognitive Decline</b> — employ the new breakthroughs in nutrition and lifestyle to keep
                  the brain sharp and memories intact.
                </>
              ) : (
                <>
                  <b>Preventing Dementia</b> — employ the new breakthroughs in nutrition and lifestyle to keep your
                  brain sharp and avoid devasting brain diseases.
                </>
              )}
            </IconListItem>
            <IconListItem>
              <b>Probiotics and Gut Health</b> — this is one of the newest and most promising health discoveries of our
              time, and we share all the breaking updates as they roll in.
            </IconListItem>
            <IconListItem>
              <b>Hidden Dangers</b> —{' '}
              {variant === 'ad' ? (
                <>get the truth about pesticides, glyphosate, food safety, GMOs, toxins, and more.</>
              ) : (
                <>
                  pesticides, glyphosate, food safety, GMOs, toxins: what you need to know to protect yourself and your
                  family.
                </>
              )}
            </IconListItem>
            <IconListItem>
              {variant === 'ad' ? (
                <>
                  <b>Heart Health</b> — learn how to switch to heart health superfoods and adopt positive lifestyle
                  routines.
                </>
              ) : (
                <>
                  <b>Stopping Heart Disease</b> — learn how to avoid the #1 cause of death by switching to heart-healthy
                  superfoods and adopting positive lifestyle routines you’ll love.
                </>
              )}
            </IconListItem>
            <IconListItem>
              <b>Detoxing and Cleansing</b> —{' '}
              {variant === 'ad' ? <>use food to feel incredible again.</> : <>use food to heal your body naturally.</>}
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="whole-themes-2"
          color="light-gray"
          imgPosition="right"
          // @ts-ignore
          imgUrl={require('static/backgrounds/1179985039.jpg')}
        >
          <IconList>
            <IconListItem>
              <b>Reversing Chronic Inflammation</b> — implement scientifically proven ways to{' '}
              {variant === 'ad' ? 'sooth' : 'reduce chronic inflammation and help your body to heal.'}
            </IconListItem>
            <IconListItem>
              <b>Weight Loss and Food Freedom</b> —{' '}
              {variant === 'ad' ? (
                <>rewire the brain to make great choices using the newest neuroscience.</>
              ) : (
                <>discover how to rewire your brain to eat what’s good for you.</>
              )}
            </IconListItem>
            <IconListItem>
              <b>Outdoor and Indoor Gardening</b> —{' '}
              {variant === 'ad' ? (
                <>
                  grow fresh fruits, veggies, and herbs for the most delicious flavor and maximum nutrient levels (even
                  in small spaces).
                </>
              ) : (
                <>
                  grow your own fresh fruits, veggies, and herbs for the most delicious flavor and maximum nutrient
                  levels (even in small spaces).
                </>
              )}
            </IconListItem>
            <IconListItem>
              <b>Creating Joy and Dealing With Stress</b> —{' '}
              {variant === 'ad' ? (
                <>get true mind-body techniques for happiness.</>
              ) : (
                <>get truly effective mind-body techniques so you can become the happiest version of yourself.</>
              )}
            </IconListItem>
            <IconListItem>
              <b>Mythbusting</b> —{' '}
              {variant === 'ad' ? (
                <>stop wondering about soy, carbs, protein, fats, and other hotly debated topics.</>
              ) : (
                <>
                  no longer wonder whether you’re doing the right thing when it comes to soy, carbs, protein, fats, and
                  other hotly debated topics.
                </>
              )}
            </IconListItem>
            <IconListItem>
              <b>Mental Health</b> —{' '}
              {variant === 'ad' ? (
                <>
                  find out what landmark studies show us about how neuroscience and nutrition can help create balance,
                  resilience, and less suffering.
                </>
              ) : (
                <>
                  find out what landmark studies show us about how neuroscience and nutrition can help create balance,
                  resilience, and less suffering.
                </>
              )}
            </IconListItem>
          </IconList>
          <h5 className="mt-0">
            {variant === 'ad' ? (
              <>And there will be new topics every week!</>
            ) : (
              <>And there will be new topics for you every week!</>
            )}
          </h5>
        </SectionImage>

        <Section id="whole-testimonial-3" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-3">What do members have to say?</h2>
                <p className="text-center text-16 mb-5">
                  <em>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. <br className="d-none d-xl-block" />
                    Their testimonials reflect their unique experiences. <br className="d-none d-sm-block d-md-none" />
                    Your specific results will vary.
                  </em>
                </p>
                {['wlcMaureen', 'wlcAlexaP', variant === 'ad' ? 'wlcNerinaSalesAd' : 'wlcNerinaSales', 'wlcKathyW'].map(
                  (key) => (
                    <TestimonialBubble id={key} key={key} />
                  )
                )}
                <h3 className="mt-4 text-center">
                  No overwhelm.
                  <br />
                  No confusion.
                  <br />
                  No boredom in the kitchen.
                </h3>
                <h3 className="m-0 text-center">
                  Just{variant === 'ad' ? null : ' you'} making strides, one day at a time. Deliciously!
                </h3>
              </Col>
            </Row>
          </Container>
        </Section>

        {isSales && <SalesSection id="whole-cta-1" />}

        <Section id="whole-wont-1" color="light-gray">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <h2 className="section-only-heading text-center">
                  What You <i>Won’t</i> Find In WHOLE Life Club
                </h2>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="whole-wont-2"
          color="light-gray"
          imgPosition="right"
          // @ts-ignore
          imgUrl={require('static/backgrounds/1351843740.jpg')}
        >
          <IconList iconColor="red" className="mt-0">
            <IconListItem icon={faTimes}>
              <h3 className="section-only-heading">
                {variant === 'ad' ? <>Another book that sits on the shelf.</> : <>A book you buy but don’t read.</>}
              </h3>
            </IconListItem>
          </IconList>
          <p>
            {variant === 'ad' ? (
              <>
                A good book is wonderful, but it can take ten hours — maybe more — to read it cover to cover. Who has
                time to spend weeks learning everything there is to know about a single health topic, even if it’s one
                that will majorly impact your life?
              </>
            ) : (
              <>
                A good book is wonderful, but it can take ten hours — maybe more to read it cover to cover. Who has time
                to spend weeks learning everything there is to know about a single health topic, even if it’s one that
                will majorly impact your life?
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                That’s why we provide short, compact lessons each week, with links for further reading for deep dives.
              </>
            ) : (
              <>
                That’s why you’ll get short, compact lessons each week, with links for further reading so you can do as
                many deep dives as you wish.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Then, during Action Hours, get the “greatest hits” from world-class experts on must-have topics that are
                too important to miss.
              </>
            ) : (
              <>
                Then, during Action Hours, you’ll get the “greatest hits” from world-class experts so you can be sure
                you’re not missing vital insights that could literally change your life overnight.
              </>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="whole-wont-3"
          color="white"
          imgPosition="left"
          // @ts-ignore
          imgUrl={require('static/backgrounds/1249628154.jpg')}
        >
          <IconList iconColor="red" className="mt-0">
            <IconListItem icon={faTimes}>
              <h3 className="section-only-heading">A bunch of guesswork.</h3>
            </IconListItem>
          </IconList>
          <p>
            Don’t want to go down internet rabbit holes or wait for the next available appointment? We don’t, either!
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                That’s why the WHOLE Life Club moderated forum is the place to go for scientifically-backed information
                that’s shown to work in long-running, peer-reviewed studies.
              </>
            ) : (
              <>
                That’s why the WHOLE Life Club moderated forum is the place to go when you need scientifically-backed
                information that’s shown to work in long-running, peer-reviewed studies.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Our team of health coaches is standing by to help troubleshoot unique health challenges, answer
                nutrition questions, debunk fads, and point to recipes for reducing stress, mood support, disease
                prevention, and any other important health issue that arises.
              </>
            ) : (
              <>
                Our team of health coaches is standing by to help you troubleshoot your unique health challenges, answer
                nutrition questions, debunk fads, and point you to recipes for reducing stress, mood support, disease
                prevention, and any other important topic that’s on your mind.
              </>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="whole-wont-4"
          color="light-gray"
          imgPosition="right"
          // @ts-ignore
          imgUrl={require('static/backgrounds/1284549946.jpg')}
        >
          <IconList iconColor="red" className="mt-0">
            <IconListItem icon={faTimes}>
              <h3 className="section-only-heading">Another Facebook group.</h3>
            </IconListItem>
          </IconList>
          <p>Not everyone loves Facebook.</p>
          {variant === 'ad' ? (
            <p>
              So, we created a warm space to connect with WHOLE Life Club registered dietitians and certified health
              coaches, and new friends who are on the same path as you and share your enthusiasm.
            </p>
          ) : (
            <>
              <p>
                So, we created a warm space where you can focus on what you want, when you want (without having your
                eyeballs sold to advertisers).
              </p>
              <p>
                Connect with WHOLE Life Club registered dietitians and certified health coaches, and new friends who are
                on the same path as you and share your enthusiasm.
              </p>
            </>
          )}
          <p>
            {variant === 'ad' ? (
              <>Conversations are sorted by category, making it easy to ask questions and find answers quickly.</>
            ) : (
              <>Conversations are sorted by category, making it easy to ask your questions and find answers.</>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="whole-wont-5"
          color="white"
          imgPosition="left"
          // @ts-ignore
          imgUrl={require('static/backgrounds/1323957894.jpg')}
        >
          <IconList iconColor="red" className="mt-0">
            <IconListItem icon={faTimes}>
              <h3 className="section-only-heading">A solo adventure.</h3>
            </IconListItem>
          </IconList>
          <p>
            {variant === 'ad' ? (
              <>
                Ever try to make a New Year’s resolution without someone else who’s committed to the same journey? Most
                might make it a week or two before slipping back to old ways.
              </>
            ) : (
              <>
                Have you ever tried to make a New Year’s resolution without someone else who’s committed to the same
                journey? If you try really hard, you might make it a while before you slip back to your old ways, but it
                can be hard to make it stick on your own.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                The WHOLE Life Club community is a life raft. Find cheerleaders, brainstorm ideas, meet accountability
                partners, and stay motivated. THIS is how to create healthy habits that last a lifetime.
              </>
            ) : (
              <>
                The WHOLE Life Club community is your life raft to the next level of you. Post whenever you need a
                cheerleader or need help brainstorming ideas. Find accountability partners and stay motivated. THIS is
                how you create healthy habits that you enjoy and adopt for life.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Plus, bring your loved ones along for the ride by making new recipes with them, watching the Action
                Hours together, and inspiring them to take great care of themselves, too.
              </>
            ) : (
              <>
                Plus, you’ll be able to bring your loved ones along for the ride as you enjoy new recipes, watch the
                Action Hours together, and inspire them to take great care of themselves, too.
              </>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="whole-wont-6"
          color="light-gray"
          imgPosition="right"
          // @ts-ignore
          imgUrl={require('static/backgrounds/186546449.jpg')}
        >
          <IconList iconColor="red" className="mt-0">
            <IconListItem icon={faTimes}>
              <h3 className="section-only-heading">Another night of uninspired dinners.</h3>
            </IconListItem>
          </IconList>
          <p>There’s so much more to eating healthy than frozen bean burritos and boring salads.</p>
          <p>
            {variant === 'ad' ? (
              <>
                Enjoy hundreds of mouthwatering recipes to whip up quickly and inexpensively, including healthy,
                veggie-forward versions of comfort classics, as well as new dishes that may become go-to favorites.
              </>
            ) : (
              <>
                You’ll discover mouthwatering recipes you can whip up quickly and inexpensively, including healthy,
                veggie-forward versions of comfort classics, as well as new dishes that may become your go-to favorites.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>Go ahead, tantalize your taste buds — even picky eaters will want these recipes on heavy rotation!</>
            ) : (
              <>
                Go ahead, tantalize your taste buds — even the pickiest eater will want these recipes on heavy rotation!
              </>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="whole-wont-7"
          color="white"
          imgPosition="left"
          // @ts-ignore
          imgUrl={require('static/backgrounds/976657866.jpg')}
        >
          <IconList iconColor="red" className="mt-0">
            <IconListItem icon={faTimes}>
              <h3 className="section-only-heading">
                A drill sergeant{variant === 'ad' ? '.' : <> telling you what you’re “supposed” to do.</>}
              </h3>
            </IconListItem>
          </IconList>
          {variant === 'ad' ? (
            <p>Life’s more fun with positivity and gentle encouragement.</p>
          ) : (
            <>
              <p>
                When you have people cheering you on, as well as health tips and health hacks spoonfed to you, it all
                adds up!
              </p>
              <p>You’ll be able to do a little bit better every day and truly enjoy the process.</p>
            </>
          )}
          <p>
            {variant === 'ad' ? (
              <>
                The entire focus of WHOLE Life Club is to get results, do a little bit better every day, and truly enjoy
                the process.
              </>
            ) : (
              <>
                The entire focus of WHOLE Life Club is to help open your eyes, get results, and have fun, so you keep{' '}
                coming back.
              </>
            )}
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                Ocean’s weekly videos are filled with encouragement, action tips, and health hacks. He breaks down
                complicated subjects, so they’re easy to understand, turning healthy choices into second nature.
              </>
            ) : (
              <>
                You’ll be hearing from Ocean regularly, too, with videos and action tips and potent resources — and
                trust us, these are emails you’ll actually want to read!
              </>
            )}
          </p>
        </SectionImage>

        <Section id="whole-testimonial-4" color="black" innerClass="text-white">
          <Container>
            <Row>
              <Col>
                <TestimonialBubble id="wlcLaurieC" bgColor="white" textColor="black" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="whole-motivation"
          color="white"
          imgPosition="right"
          // @ts-ignore
          imgUrl={require('static/backgrounds/couple-happy-bowls-square.jpg')}
          className="text-center text-lg-left"
        >
          <h2 className="section-heading mb-3">
            Motivation To Live {variant === 'ad' ? <>a Healthy Life</> : <>Your Healthiest Life</>}
          </h2>
          <h4 className="text-400 mt-0">
            WHOLE Life Club doesn’t just {variant === 'ad' ? <>offer information… </> : <>give you information…</>}
          </h4>
          <h4 className="text-400 mt-0">{variant === 'ad' ? <>It creates power.</> : <>It gives you power.</>}</h4>
          <h4 className="text-400 my-4">
            <i>
              One meal,
              <br />
              One discovery,
              <br />
              One conversation,
              <br />
              One new friend…
            </i>
          </h4>
          <h4 className="text-400 mt-0">At a time.</h4>
        </SectionImage>

        <SectionImage
          id="whole-pressure"
          color="light-gray"
          borderColor="light-gray"
          className="section-angle-desktop-none"
          // @ts-ignore
          imgUrl={require('static/backgrounds/woman-cellphone-square.jpg')}
        >
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">A Few Words About Social Pressure</h2>
                <p>
                  <i>Ever given in to peer pressure?</i> It’s quite common.
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>It’s harder to have a healthy lifestyle when friends and family aren’t fully on board.</>
                  ) : (
                    <>It’s harder to have a healthy lifestyle when your friends and family aren’t fully on board.</>
                  )}
                </p>
                <p>
                  This stress becomes even more powerful when {variant === 'ad' ? 'there’s' : 'you have'} an underlying
                  illness or condition that no one else seems to understand.
                </p>
                {variant === 'ad' ? (
                  <>
                    <p>
                      When people around you are bonding over unhealthy foods, it’s common to feel sad, disconnected, or
                      disappointed.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      When people around you are bonding over unhealthy foods, you may feel sad, disconnected, or
                      disappointed.
                    </p>
                    <p>They might also express discomfort that you’re not joining in.</p>
                  </>
                )}
                <p>
                  {variant === 'ad' ? (
                    <>Worst of all, it’s tempting to compromise in order to fit in.</>
                  ) : (
                    <>
                      Worst of all, you might compromise your food values to fit in or for fear of hurting someone’s
                      feelings — and ultimately, you might regret it.
                    </>
                  )}
                </p>
                {variant === 'ad' ? null : (
                  <p className="mb-0">
                    Unless you have ample support, your social life can pull you in unhealthy directions.
                  </p>
                )}
              </Col>
            </Row>
          </Container>
        </SectionImage>

        <SectionImage
          id="whole-pressure-2"
          color="light-gray"
          imgPosition="right"
          className="section-image-offset-top-desktop"
          // @ts-ignore
          imgUrl={require('static/backgrounds/woman-yellow-sweater.jpg')}
        >
          <h2 className="section-heading">The Solution: A Welcoming, Supportive Community</h2>
          <p>
            {variant === 'ad' ? (
              <>
                Become part of a moderated, thoughtful community of health-minded people who are ready to overcome
                whatever challenges arise — now or in the future.
              </>
            ) : (
              <>
                The instant you {ctaOverride === 'sales99' ? 'rejoin' : 'join'}, you’ll gain access to a moderated,
                thoughtful community of health-minded people who are ready to help you overcome whatever challenges you
                may face — now or in the future.
              </>
            )}
          </p>
          <p>This is why Ocean offers ample tips to help you navigate any social situation with ease.</p>
          <p>
            {variant === 'ad' ? (
              <>This is a solid community, ready to celebrate victories and milestones...</>
            ) : (
              <>This is your community, ready to cheer you on and celebrate your victories and milestones...</>
            )}
          </p>
          <h3 className="mt-4">
            And to motivate you from “I know I <i>should...</i>” to “I’m doing it!”
          </h3>
        </SectionImage>

        {isSales && (
          <SalesSection
            id="whole-cta-2"
            headline={
              <Row className="text-center text-white mb-5">
                <Col>
                  <h2 className={classNames(`section-heading`, !ctaNoTimer && !isEvergreen ? `` : `mb-0`)}>
                    Make It Happen For You!
                  </h2>
                  {!ctaNoTimer && !isEvergreen ? <SalesTimer className="mt-4" /> : null}
                </Col>
              </Row>
            }
          />
        )}

        <Section
          id="whole-why"
          color="white"
          className={classNames(!isSales && 'section-angle-last')}
          angle={isSales ? 'bottom' : 'none'}
        >
          <Container>
            <Row className="d-flex justify-content-center">
              <Col>
                <h2 className="section-heading text-center mb-5">
                  Why {ctaOverride === 'sales99' ? 'Rejoin' : 'Join'} WHOLE Life Club?
                </h2>
                <ImgCard
                  imgColLg="4"
                  textColLg="8" // @ts-ignore
                  img={require('static/why-money.jpg')}
                >
                  <h4 className="section-heading">Save money.</h4>
                  <p>
                    {variant === 'ad' ? (
                      <>
                        Get inspired to cook more meals at home. With just one less takeout meal a month, membership
                        pays for itself. And with fewer trips to the doctor and pharmacy aisle in the future, that could
                        add up to <i>thousands</i> of dollars saved.
                      </>
                    ) : (
                      <>
                        You’ll be inspired to cook more meals at home. With just one less takeout meal a month, your
                        membership will pay for itself. With fewer trips to the doctor and pharmacy aisle in your
                        future, you could save <i>thousands.</i>
                      </>
                    )}
                  </p>
                </ImgCard>
                <ImgCard
                  imgColLg="4"
                  textColLg="8" // @ts-ignore
                  img={require('static/why-time.jpg')}
                >
                  <h4 className="section-heading">Save time.</h4>
                  <p>
                    {variant === 'ad' ? (
                      <>Learn the essential time-saving cooking techniques that most people are never taught.</>
                    ) : (
                      <>Learn the must-know food techniques you were never taught to save time while cooking.</>
                    )}
                  </p>
                  <p>
                    {variant === 'ad' ? (
                      <>
                        Plus, all WHOLE Life Club recipes can be adapted for gluten-, sugar-, oil-, and/or salt-free
                        diets, so it’s a cinch to find meals to accommodate everyone.
                      </>
                    ) : (
                      <>
                        Plus, all recipes can be adapted for gluten-, sugar-, oil-, and/or salt-free diets, so you won’t
                        waste time hunting for meals you can eat.
                      </>
                    )}
                  </p>
                </ImgCard>
                <ImgCard
                  imgColLg="4"
                  textColLg="8" // @ts-ignore
                  img={require('static/why-health.jpg')}
                >
                  <h4 className="section-heading">Save{variant === 'ad' ? null : ' your'} health.</h4>
                  <p>Don’t miss out on critical health topics that every single person needs to understand.</p>
                  <p>
                    {variant === 'ad' ? (
                      <>
                        WHOLE Life Club is like a total health encyclopedia, providing the complete health education
                        that’s otherwise easy to miss.
                      </>
                    ) : (
                      <>
                        WHOLE Life Club is like a total health encyclopedia, giving you the resources you need when you
                        need them.
                      </>
                    )}
                  </p>
                </ImgCard>
                <ImgCard
                  imgColLg="4"
                  textColLg="8" // @ts-ignore
                  img={require('static/why-hassle.jpg')}
                >
                  <h4 className="section-heading">Save {variant === 'ad' ? 'the' : 'yourself'} hassle.</h4>
                  <p>
                    {variant === 'ad' ? (
                      <>
                        From seasoned chef to total beginner, WHOLE Life Club divulges tips to make cooking and menu
                        planning fast and fun.
                      </>
                    ) : (
                      <>
                        Whether you’re a seasoned chef or a beginner, you’ll get tips and tricks to help you make
                        cooking and menu planning fun.
                      </>
                    )}
                  </p>
                </ImgCard>
                <ImgCard
                  imgColLg="4"
                  textColLg="8" // @ts-ignore
                  img={require('static/why-planet.jpg')}
                >
                  <h4 className="section-heading">Save the planet.</h4>
                  <p>
                    {variant === 'ad' ? (
                      <>
                        Eating more veggies is one of the best things we can do to save soil and water, reduce
                        pesticides, fight climate change, and preserve the beautiful Earth we call home.
                      </>
                    ) : (
                      <>
                        Putting more veggies on your plate is one of the best things you can do to save soil and water,
                        reduce pesticides, fight climate change, and preserve the beautiful Earth we call home.
                      </>
                    )}
                  </p>
                </ImgCard>
                <ImgCard
                  imgColLg="4"
                  textColLg="8"
                  img="https://cdn.foodrevolution.org/eattherainbow/images/1299818810.jpg"
                >
                  <h4 className="section-heading">Save animals from harm.</h4>
                  <p>
                    Feel good knowing that {variant === 'ad' ? 'our' : 'your'} food choices are as good for{' '}
                    {variant === 'ad' ? 'us' : 'you'} as they are for other creatures, big and small. We’re all in this
                    together!
                  </p>
                </ImgCard>
              </Col>
            </Row>
          </Container>
        </Section>

        {isSales && (
          <>
            <Section id="whole-guarantee" color="light-gray">
              <Container>
                <Row>
                  <Col>
                    <GuaranteeBox title={<>Our 60-Day, Money-Back, Risk-Free Guarantee</>}>
                      <p>Take up to 60 days to see what WHOLE Life Club is all about.</p>
                      {variant === 'ad' ? null : (
                        <>
                          <p className="mb-4">We guarantee that you and your family will:</p>
                          <IconList>
                            <>
                              Have the tools and information to improve your health right away, helping you eat better
                              and enjoy more vitality.
                            </>
                            <>Feel empowered knowing the truth about the foods you eat.</>
                            <>Make meaningful progress towards YOUR healthy eating goals.</>
                          </IconList>
                        </>
                      )}
                      <p>
                        {variant === 'ad' ? (
                          <>
                            Your satisfaction is important to us! If not 100% thrilled, request a prompt, total refund
                            within 60 days. Email us at{' '}
                            <a className="link-black text-underline" href="mailto:support@foodrevolution.org">
                              support@foodrevolution.org
                            </a>{' '}
                            or phone us at{' '}
                            <a className="link-black text-underline" href="tel:8318244779">
                              (831) 824-4779
                            </a>
                            , or reply to any email we send with your request.
                          </>
                        ) : (
                          <>
                            Your satisfaction is important to us! If for some reason you aren’t 100% thrilled with your
                            purchase, you have 60 days to request a prompt, total refund. Email us at{' '}
                            <a className="link-black text-underline" href="mailto:support@foodrevolution.org">
                              support@foodrevolution.org
                            </a>{' '}
                            or phone us at{' '}
                            <a className="link-black text-underline" href="tel:8318244779">
                              (831) 824-4779
                            </a>
                            . You can also hit reply to any email we send you with your request.
                          </>
                        )}
                      </p>
                      <p className="mb-0">
                        {variant === 'ad' ? (
                          <>Keep everything already downloaded. That’s why we call our guarantee “risk-free!”</>
                        ) : (
                          <>
                            If for any reason you request a refund, everything you've already downloaded is still yours
                            to keep. That’s why we call our guarantee “risk-free!”
                          </>
                        )}
                      </p>
                    </GuaranteeBox>
                  </Col>
                </Row>
              </Container>
            </Section>

            <SalesSection id="whole-cta-3" className="section-angle-last" angle="none" trees />
          </>
        )}
      </div>

      <Footer />
      <ShareButtons
        shareUrl={shareUrl}
        shareImage={shareImage}
        facebookDescription={facebookDescription}
        twitterDescription={twitterDescription}
        pinterestDescription={pinterestDescription}
        pinterestImage={pinterestImage}
        floating
      />
      {!noExitIntent && (
        <ExitModal
          exitDelay={60000}
          content={
            <>
              {isWorkshop && isSales ? (
                <>
                  <h5 className="section-heading">Almost there, but don’t let this opportunity slip away!</h5>
                  <p>
                    Enrollment for WHOLE Life Club’s $1 trial <br className="d-none d-xl-block" />
                    is closing on June 2nd. Act now to secure <br className="d-none d-xl-block" />
                    your spot.
                  </p>
                </>
              ) : (
                <>
                  <h3 className="section-heading">
                    This little popup <br className="d-none d-xl-block" />
                    has a BIG message…
                  </h3>
                  <p>You’re about to miss out on WHOLE Life Club!</p>
                  <IconList className="icon-list-small">
                    <IconListItem>
                      6 delicious <b>plant-powered recipes</b> per week
                    </IconListItem>
                    <IconListItem>
                      <b>Weekly videos</b> from Ocean Robbins
                    </IconListItem>
                    <IconListItem>
                      <b>Monthly Action Hours</b> with health revolutionaries
                    </IconListItem>
                    <IconListItem>
                      <b>Action Checklists</b> to keep you on track
                    </IconListItem>
                    <IconListItem>
                      A supportive, moderated <b>community</b>
                    </IconListItem>
                    <IconListItem>
                      Access to <b>Health Coaches & Dietitians</b>
                    </IconListItem>
                    <IconListItem>
                      <b>And much more</b>
                    </IconListItem>
                  </IconList>
                </>
              )}
            </>
          }
          image={{
            url: isWorkshop
              ? 'https://cdn.foodrevolution.org/wlc/workshop/1477118902-exit.jpg'
              : 'https://cdn.foodrevolution.org/wlc/images/1314850492-exit.jpg',
            alt: isWorkshop
              ? 'man making healthy smoothie in gym clothing'
              : 'woman preparing healthy smoothie as part of her morning routine',
            width: 1000,
            height: 800
          }}
          positiveButtonText={
            <>
              {isWorkshop ? (
                <>
                  Yes, I want <br className="d-none d-lg-block" />a $1 trial
                </>
              ) : (
                <>Yes, I want in</>
              )}
            </>
          }
          negativeButtonText="No Thanks"
          scrollHref="whole-cta-3"
          scrollOffset={0}
        />
      )}
      <WaitListForm />
    </div>
  );
}
