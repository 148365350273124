

  
// Template Map
export default {
  '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404': require('/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/whole.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/whole.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-l/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-l/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-l/whole.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-l/whole.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-l/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-l/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-m/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-m/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-m/whole.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-m/whole.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-m/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-m/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/whole.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/whole.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/ac-upsell.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/ac-upsell.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/ac18.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/ac18.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/broadcasts.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/broadcasts.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/replays.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/replays.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/welcome.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/welcome.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/whole-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/whole-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/whole-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/whole-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/whole.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/whole.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/broadcast.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/broadcast.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/broadcasts.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/broadcasts.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/replay.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/replay.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/resources.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/resources.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/welcome.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/welcome.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/whole.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/whole.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/wlc/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/wlc/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/wlc/welcome.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/wlc/welcome.jsx').default
}

export const notFoundTemplate = '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404'

