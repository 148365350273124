import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import SalesIndex from './whole';

export default function () {
  const {
    phases: {
      specialSales: specialSalesSchedule = {
        phase: '',
        specialSalesEndDate: ''
      }
    }
  } = usePhaseState();

  return <SalesIndex ctaOverride={specialSalesSchedule.phase === 'during' ? 'sales99' : 'salesFull'} />;
}
