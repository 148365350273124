import React, {useState, useEffect} from 'react';
import {Location} from '@reach/router';
import {Col, Container, Row, Button} from 'reactstrap';
import qs from 'qs';
import {usePhaseState} from 'funnel-schedule/phase-context';
import usePubNubSubscribe from 'utils/usePubNubSubscribe';
import useBeacon from 'utils/useBeacon';
import PageMeta from 'components/PageMeta';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ShareButtons from 'components/Social/ShareButtons';
import CommentSection from 'components/CommentSection';
import Tile, {RecipeTile} from 'components/Elements/Tile';
import {WlcReplayTabNav, WlcReplayTabMap} from 'components/Replays/ReplayTabs';
import Section from 'components/Elements/Section';
import CountdownTimer from 'components/Elements/CountdownTimer';
import {recipe} from 'data/recipe.json';

export default (props) => (
  <Location>
    {({location}) => {
      const addProps = {};
      if (location && location.search) {
        const {day} = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (day && isNaN(parseInt(location.search.day))) addProps.day = parseInt(day);
      }
      return <Replays {...props} {...addProps} location={location} />;
    }}
  </Location>
);

function Replays({day = null, location, ...props}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: ''
          }
        ]
      },
      sales: salesSchedule = {
        phase: 'before',
        salesEndDate: '',
        salesCtaCopy: ''
      }
    }
  } = usePhaseState();

  const isSeries = defaultSchedule.actionHours.length > 1;
  const currentActionHour = defaultSchedule.currentActionHour;
  const notFinalActionHour = currentActionHour !== 2;

  const salesEnd = new Date(salesSchedule.salesEndDate).getTime();

  const [activePanel, setActivePanel] = useState(0);

  useBeacon();
  return (
    <div className="page">
      <PageMeta title="Replays" description="Make FOOD the foundation of YOUR health" />

      <div className="page-content">
        <Header className="background-black header-wrap" logoWhite style="dark" logo={isSeries ? 'plural' : null} />

        <section className="section-broadcast section-broadcast-header background-gradient-indigo-purple-right text-white">
          <Container>
            <Row className="d-flex justify-content-center text-center">
              <Col lg="10">
                <h2 className="section-only-heading">Action Hour Replays</h2>
              </Col>
            </Row>
          </Container>
        </section>

        <Section
          id="replay-videos"
          color="white"
          innerClass={isSeries ? 'pt-5' : 'pt-5 pb-3'}
          angle={isSeries ? 'bottom' : 'none'}
        >
          <Container>
            {isSeries && defaultSchedule.phase === 'before' && currentActionHour === 0 ? (
              <Row className="d-flex justify-content-center text-center my-5">
                <Col>
                  <h4 className="section-heading">
                    Click below to access the <br className="d-none d-sm-block" />
                    Action Hour Series going on soon:
                  </h4>
                  <Button color="cta" size="lg" href="/broadcasts/">
                    Join Us!
                  </Button>
                </Col>
              </Row>
            ) : isSeries && defaultSchedule.phase === 'during' && currentActionHour === 0 ? (
              <Row className="d-flex justify-content-center text-center my-5">
                <Col>
                  <h4 className="section-heading">
                    Click below to access the <br className="d-none d-sm-block" />
                    Action Hour Series going on now:
                  </h4>
                  <Button color="cta" size="lg" href="/broadcasts/">
                    Watch Now!
                  </Button>
                </Col>
              </Row>
            ) : defaultSchedule.phase != 'before' || currentActionHour > 0 || !isSeries ? (
              <Row className="d-flex align-items-stretch">
                <Col lg="8">
                  <WlcReplayTabMap activePanel={activePanel} />
                </Col>
                <Col lg="4">
                  <div className="replay-tab-wrap">
                    <WlcReplayTabNav setActivePanel={setActivePanel} activePanel={activePanel} />
                  </div>
                </Col>
              </Row>
            ) : null}
          </Container>
        </Section>

        {isSeries ? (
          <Section id="replay-cta" className="section-ep text-white" color="gradient-indigo-purple-right">
            <Container>
              <Row className="d-flex justify-content-center text-center">
                <Col>
                  <h4 className="section-heading">Get RESULTS. Join WHOLE Life Club.</h4>
                </Col>
              </Row>
              <Row className="text-center mb-4">
                <Col>
                  <img
                    className="background-white px-1 py-4 rounded"
                    src="https://cdn.foodrevolution.org/wlc/wlc-product-image-v20220329.png"
                    width="1200"
                    height="500"
                    alt="WHOLE Life Club graphic"
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center text-center">
                <Col lg="10">
                  <p>
                    Do you want <b>ongoing</b> access to dozens of Action Hours, <br className="d-none d-lg-block" />
                    featuring <b>trustworthy wisdom, </b>
                    including recordings, <br className="d-none d-md-block" />
                    transcripts, and follow-up <b>Action Checklists?</b>
                  </p>
                  <p>
                    Do you want to be able to <b>submit questions</b> <br className="d-none d-sm-block d-lg-none" />
                    for future Action Hours?
                  </p>
                  <p>
                    Do you want a steady flow of delicious, plant-powered <b>recipes,</b>{' '}
                    <br className="d-none d-lg-block" />
                    weekly action step videos and checklists, <br className="d-none d-sm-block d-md-none" />
                    and a{' '}
                    <b>
                      supportive <br className="d-none d-lg-block" />
                      community
                    </b>{' '}
                    to help you <br className="d-none d-sm-block d-md-none" />
                    implement everything you’re learning?
                  </p>
                  {isSeries ? (
                    <>
                      <p className="mb-4">
                        <b>
                          For the LAST time in 2022, the doors to WHOLE Life Club{' '}
                          <br className="d-none d-md-block d-lg-none" />
                          are <br className="d-none d-lg-block" />
                          open for direct entry.
                        </b>{' '}
                        (The ONLY other way to join, <br className="d-none d-sm-block d-lg-none" />
                        for the rest of the <br className="d-none d-lg-block" />
                        year, will be after purchase of <br className="d-none d-sm-block d-lg-none" />
                        FRN courses and other resources.)
                      </p>
                      <Button color="cta" size="lg" href="/whole/" target="_blank">
                        Join WHOLE Life Club Now
                      </Button>
                      <h4 className="timer-heading mt-4">Your Discount Offer Expires In:</h4>
                      <CountdownTimer time={salesEnd} />
                    </>
                  ) : (
                    <>
                      <Button color="cta" size="lg" href="/whole/" target="_blank">
                        Join WHOLE Life Club Now
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Section>
        ) : null}

        {isSeries ? (
          <section className="section section-broadcast-banner">
            <div className="section-inner">
              <Container>
                <Row className="d-flex align-items-stretch">
                  {notFinalActionHour ? (
                    <>
                      {currentActionHour === 0 ? (
                        <Col md="6" className="tile-broadcast-half mb-4 mb-md-0">
                          <Tile type="broadcast" />
                        </Col>
                      ) : (
                        <Col md="6" lg="4" className="mb-4 mb-lg-0">
                          <Tile type="broadcast" />
                        </Col>
                      )}
                    </>
                  ) : null}
                  {recipe
                    .slice(0, defaultSchedule.phase === 'before' ? 1 : currentActionHour + 1)
                    .reverse()
                    .map(({id}) => (
                      <>
                        {currentActionHour === 0 ? (
                          <Col md="6" className="mb-4 mb-md-0">
                            <RecipeTile id={id} />
                          </Col>
                        ) : (
                          <Col md="6" lg="4" className="mb-4 mb-lg-0">
                            <RecipeTile id={id} />
                          </Col>
                        )}
                      </>
                    ))}
                </Row>
              </Container>
            </div>
          </section>
        ) : null}

        <CommentSection className={isSeries ? 'section-broadcast' : 'section-broadcast pt-0'} numPosts="10" />
      </div>

      <Footer />
      <ShareButtons floating />
    </div>
  );
}
