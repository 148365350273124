import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import BroadcastIndex from 'pages/workshop/broadcast';

export default function (props) {
  useEffect(() => {
    navigate('/workshop/broadcast/' + location.search);
  }, []);
  return <BroadcastIndex />;
}
