import React, {useCallback, useState, useEffect} from 'react';
import {Button, Col, Row} from 'reactstrap';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import {usePhaseState} from 'funnel-schedule/phase-context';
import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent.js';

export default function ModalExitLanding({children, ...props}) {
  const {isOpen, toggle} = useExitIntent(30000);
  return (
    <Modal className="modal-exit-intent modal-exit-intent-landing" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h3 className="section-heading">Before you go!</h3>
          <p>
            {props.launch ? (
              <>
                Make sure you don’t miss the <br className="d-none d-xl-block" />
                WHOLE Life Action Hour Series!
              </>
            ) : (
              <>Make sure you don’t miss the WHOLE Life Action Hour!</>
            )}
          </p>
          {props.launch ? (
            <>
              It’s a <b>brand new</b> series, and this is <br className="d-none d-xl-block" />
              your <b>exclusive</b> opportunity to join (for free!).
            </>
          ) : null}
          <Button className="btn-exit text-uppercase text-white text-h5" color="cta" onClick={toggle} block>
            Find Out More
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div className="exit-modal-image background-image-landing-exit-v1" />
        </Col>
      </Row>
    </Modal>
  );
}
