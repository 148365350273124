import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik, Field} from 'formik';
import {Alert, Button, FormGroup, Label} from 'reactstrap';
import * as Yup from 'yup';

import {useUserDispatch} from 'contexts/user';
import {submitForm} from 'utils/forms';
import CustomInputField from '../CustomInputField';
import classNames from 'classnames';
import track, {identify} from 'utils/track';

const {SF_API_URL} = process.env;
const {WAITLIST_FORM_ID} = process.env;
const {SITE_ID} = process.env;

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  website: Yup.string().test('is-empty', 'Leave this empty.', (value) => !value)
});

export default function ({id, linkColor = 'purple', onComplete = () => {}}) {
  const userDispatch = useUserDispatch();
  return (
    <>
      <p className="text-center mb-4">
        We only open our doors to join WHOLE Life Club <br className="d-none d-sm-block d-lg-none" />
        twice a year. <br className="d-none d-lg-block" />
        Would you like to be notified the next <br className="d-none d-sm-block d-lg-none" />
        time WHOLE Life Club will be open to join?
      </p>

      <div className="optin-wrap mb-3">
        <Formik
          initialValues={{
            email: '',
            firstName: ''
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            return submitForm(values, WAITLIST_FORM_ID)
              .then(({data}) => {
                identify(data.proxy_data.user.uuid, {
                  uuid: data.proxy_data.user.uuid,
                  firstName: values.firstName,
                  email: values.email
                });
                return userDispatch({
                  type: 'SET_USER',
                  payload: {
                    firstName: values.firstName,
                    email: values.email,
                    uuid: data.proxy_data.user.uuid
                  }
                });
              })
              .then(() =>
                track('User Registered', {
                  property: SITE_ID,
                  entryPoint: 'Wait List',
                  label: `${SITE_ID} - Wait List`
                })
              )
              .then(() => onComplete())
              .catch((e) => {
                actions.setStatus({error: e});
                actions.setSubmitting(false);
              });
          }}
          render={({values, handleSubmit, isSubmitting, isValid, status}) => (
            <Form onSubmit={handleSubmit}>
              {status && status.error && <Alert color="danger">{status.error}</Alert>}
              <FormGroup>
                <Label for="firstName" hidden>
                  First Name
                </Label>
                <Field
                  name="firstName"
                  type={'text'}
                  component={CustomInputField}
                  placeholder="What is your first name?"
                  bsSize="lg"
                />
              </FormGroup>
              <FormGroup>
                <Label for="email" hidden>
                  Email
                </Label>
                <Field
                  name="email"
                  type={'email'}
                  component={CustomInputField}
                  placeholder="What is your best email?"
                  bsSize="lg"
                />
              </FormGroup>

              <FormGroup className="d-none">
                <Label for="Website" hidden>
                  Should be left blank
                </Label>
                <Field name="website" type={'text'} component={CustomInputField} placeholder="" bsSize="lg" />
              </FormGroup>

              <Button
                className="text-uppercase text-h4 text-condensed text-800"
                color="cta"
                type="submit"
                disabled={isSubmitting || !isValid}
                block
              >
                {isSubmitting ? 'Loading...' : 'Sign Up Now'}
              </Button>
            </Form>
          )}
        />
      </div>
      <p className="text-small text-left">
        By entering your information here, you are agreeing to receive messages from Food Revolution Network. Your email
        address will NEVER be shared or sold. You are always free to easily unsubscribe at any time. See our{' '}
        <a
          className={classNames(`text-underline link-${linkColor}`)}
          href="https://foodrevolution.org/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>{' '}
        for more info.
      </p>
    </>
  );
}
