import React from 'react';
import {Button, Col, Modal, ModalHeader, ModalBody, Row} from 'reactstrap';
import {Link} from 'react-scroll';
import classNames from 'classnames';
import useExitIntent from 'utils/useExitIntent.js';
import {OptinModalTrigger} from 'components/OptinModal';
import './Modal.scss';

export default function WiredModal({children, isOpen, toggle, ...props}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} returnFocusAfterClose={false} {...props}>
      <ModalHeader toggle={toggle} />
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
}

export function ExitModal({
  className = undefined,
  exitDelay,
  content,
  positiveButtonText,
  positiveButtonColor = 'exit-orange',
  positiveButtonClass = undefined,
  positiveOptin = false,
  negativeButtonText,
  negativeButtonColor = 'exit-gray',
  negativeButtonClass = undefined,
  imageBackground = true,
  image,
  imagePosition = 'center',
  scrollHref = undefined,
  scrollOffset = -55
}) {
  const {isOpen, toggle} = useExitIntent(exitDelay);
  return (
    <WiredModal className={classNames(`modal-exit`, className)} isOpen={isOpen} toggle={toggle}>
      <Row className={classNames(`text-center`, `text-lg-left`, `align-items-stretch`, `m-0`)}>
        <Col xs="12" lg="7" className={classNames('modal-exit-copy-col', `order-lg-2`, `my-auto`)}>
          {content}
          <Row className={classNames('modal-exit-buttons', `align-items-center`, `mt-5`)}>
            <Col xs="12" lg="6" className={classNames(`mb-4`, `mb-lg-0`)}>
              {scrollHref ? (
                <Button
                  className={classNames('btn-exit', 'btn-exit-pos', positiveButtonClass)}
                  color={positiveButtonColor}
                  onClick={toggle}
                  block
                  activeClass="active"
                  spy={true}
                  offset={scrollOffset}
                  to={scrollHref}
                  smooth={true}
                  href="#"
                  tag={Link}
                >
                  {positiveButtonText}
                </Button>
              ) : positiveOptin ? (
                <OptinModalTrigger
                  className={classNames('btn-exit', 'btn-exit-pos', `d-block`, `w-100`, positiveButtonClass)}
                  color={positiveButtonColor}
                  classOverride
                  btnText={positiveButtonText}
                />
              ) : (
                <Button
                  className={classNames('btn-exit', 'btn-exit-pos', positiveButtonClass)}
                  color={positiveButtonColor}
                  onClick={toggle}
                  block
                >
                  {positiveButtonText}
                </Button>
              )}
            </Col>
            <Col xs="12" lg="6">
              <Button
                className={classNames('btn-exit', 'btn-exit-neg', negativeButtonClass)}
                color={negativeButtonColor}
                onClick={toggle}
                block
              >
                {negativeButtonText}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="5" className={classNames('modal-exit-image-col', `order-lg-1`, `flex-column`)}>
          {imageBackground ? (
            <img
              className={classNames(
                `modal-exit-image`,
                `modal-exit-image-background`,
                `modal-exit-image-${imagePosition}`
              )}
              src={image.url}
              alt={image.alt}
            />
          ) : (
            <div className={classNames(`my-auto`, `px-3`, `px-lg-1`, `py-5`)}>
              <img className={classNames('modal-exit-image')} src={image.url} alt={image.alt} />
            </div>
          )}
        </Col>
      </Row>
    </WiredModal>
  );
}
