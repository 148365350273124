import React from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {Col, Container, Row} from 'reactstrap';
import useBeacon from 'utils/useBeacon';
import PageMeta from 'components/PageMeta';
import HeaderVideo from 'components/Elements/HeaderVideo';
import Section, {SectionImage} from 'components/Elements/Section';
import {SalesActionCollection} from 'components/SalesCta';
import {AcSpeakerList} from 'components/SpeakerList';
import Footer from 'components/Footer';
import acTranscript from 'static/ac-transcripts.png';
import acChecklist from 'static/ac-checklists.png';
import acInterview from 'static/ac-interview.png';

export default function () {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: ''
          }
        ]
      }
    }
  } = usePhaseState();

  const isSeries = defaultSchedule.actionHours.length > 1;
  const seriesName = `Action Hour${isSeries ? ' Series' : ''}`;
  useBeacon();
  return (
    <div className="page page-nosocial">
      <PageMeta title="The Food Revolution Action Collection" description="Make FOOD the foundation of YOUR health" />
      <div className="page-content">
        <section className="section-header background-black text-white py-4">
          <Container>
            <Row>
              <Col className="text-center">
                <h4 className="section-heading mt-3 mb-2">
                  Discover 40+ Revolutionary Actions You Can <br className="d-none d-md-block d-xl-none" />
                  Take for Your Health RIGHT NOW
                </h4>
              </Col>
            </Row>
          </Container>
        </section>

        <Section
          id="ac-header"
          className="section-header"
          innerClass="text-white text-shadow pt-0 pt-sm-3"
          color="black"
        >
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <HeaderVideo
                  bgColor="black"
                  sku="WLC"
                  price="67"
                  slug="wlc-ac18"
                  url="https://www.youtube.com/watch?v=nC0f2pAmXV4"
                  actionCollection
                  playing
                />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="ac-steps" color="gradient-indigo-purple-right">
          <Container>
            <Row className="text-center text-white mb-4">
              <Col>
                <h2 className="section-heading">
                  Know Exactly What Steps to <br className="d-none d-lg-block" />
                  Take to Improve Your Healthy Lifestyle
                </h2>
                <h5 className="mt-0">
                  If you want trustworthy knowledge you can put into ACTION...{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  this special offer is for you!
                </h5>
              </Col>
            </Row>
            <SalesActionCollection noTrees />
          </Container>
        </Section>

        <SectionImage
          id="section-ac-info"
          imgPosition="right"
          color="white"
          imgUrl={require('static/backgrounds/woman-lamp-square.jpg')}
          imgUrlMobile={require('static/backgrounds/woman-lamp.jpg')}
        >
          <h3 className="section-heading">
            The Food Revolution <br className="d-none d-lg-block d-xl-none" />
            Action Collection
          </h3>
          <h6 className="mt-0 mb-3">
            Will Help You Take Action on Cutting-Edge, Health-Boosting WISDOM from Popular Food Revolution Summit
            Interviews…
          </h6>
          <p>
            Every year, we host an online <strong>Food Revolution Summit</strong> where{' '}
            <strong>bestselling author</strong> (and Food Revolution Network co-founder), <strong>John Robbins</strong>,
            interviews 24 of the <strong>world’s leading health revolutionaries</strong>.
          </p>
          <p>
            <strong>More than a million people</strong> have attended these Summits and many thousands have told us how
            the knowledge they gained has <strong>transformed their health and their lives</strong>.
          </p>
          <p>
            And, sometimes, we also hear that all the incredible information can be overwhelming… and even if you pay
            close attention or take notes, you might still <strong>struggle with deciding</strong> which actions you
            should take.
          </p>
          <h6 className="my-3">That’s why the brand-new Food Revolution Action Collection is focused on ACTION</h6>
          <p>
            <em>
              {' '}
              — to be sure you know exactly what steps to take to improve your healthy lifestyle, so you can truly get
              the results you want.
            </em>
          </p>
        </SectionImage>

        <Section id="ac-get" color="light-gray">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col>
                <h2 className="section-only-heading mb-5">Here’s What You'll Get…</h2>
                <Row className="d-flex align-items-center justify-content-center mb-5">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src={acInterview} />
                  </Col>
                  <Col xs="12" lg="9">
                    <h2 className="section-heading text-center text-lg-left">10 Action-Focused Interviews:</h2>
                    <p>
                      We scoured every Food Revolution Summit presentation to hand-select 10 of the most useful,
                      action-centric interviews EVER.
                    </p>
                    <p>
                      <strong>
                        If you want knowledge you need to help you get real, lasting health results, these are the
                        interviews you want to own and absorb for life...
                      </strong>
                    </p>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center mb-5">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src={acTranscript} />
                  </Col>
                  <Col xs="12" lg="9">
                    <h2 className="section-heading text-center text-lg-left">10 Transcripts:</h2>
                    <p>
                      In addition to the audio files, you’ll get full written transcripts for each interview so you can
                      see exactly what was said.
                    </p>
                    <p>
                      Each transcript includes a table of contents so you can navigate easily through the specific
                      discussion points of the interview, as well as hyperlinks you can easily click to find and read
                      specific studies mentioned by the speakers for further inquiry or research.
                    </p>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src={acChecklist} />
                  </Col>
                  <Col xs="12" lg="9">
                    <h2 className="section-heading text-center text-lg-left">10 Action Checklists:</h2>
                    <p>
                      And then, we took it a step further... We created an <strong>ACTION CHECKLIST</strong> for each
                      interview.
                    </p>
                    <p>
                      Each Action Checklist gives you{' '}
                      <em>
                        <strong>the core takeaways + specific action steps</strong>
                      </em>{' '}
                      from each presentation, making it easier than ever for you to APPLY all the benefits in your life.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          id="ac-note"
          className="section-angle-none-image background-image-fruit-veggies background-image-center background-cover background-fixed background-overlay-black-50"
          angle="none"
        >
          <div className="section-inner">
            <Container>
              <Row>
                <Col>
                  <div className="background-white px-3 py-4 px-lg-5 py-lg-5 my-3">
                    <h3 className="section-heading text-center mb-5">
                      Start Learning from These <br className="d-none d-sm-block d-xl-none" />
                      10 Health Revolutionaries TODAY
                    </h3>
                    <Row>
                      <AcSpeakerList />
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Section>

        <Section id="ac-collection" color="white" angle="both">
          <Container>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading">
                  When you buy the FOOD REVOLUTION ACTION COLLECTION, <br className="d-none d-xl-block" />
                  you’ll instantly get all this for only $67!
                </h3>
                <p>Even if you’ve attended Food Revolution Summits in the past…</p>
                <p>
                  … and even if you already own some of these interviews in the Empowerment Packages{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  from past Summits (though you’d have to own several Summits, for $197 each, to have{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  all of these in your collection)…
                </p>
                <p>
                  You’ll get <em>immediate</em> value from the Action Checklists, which you can’t currently get any
                  other way.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="ac-cta" className="section-angle-last" color="gradient-indigo-purple-right" angle="none">
          <Container>
            <SalesActionCollection />
          </Container>
        </Section>
      </div>

      <Footer />
    </div>
  );
}
