import React from 'react';
import {Col, Container, Row} from 'reactstrap';

import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Section from 'components/Elements/Section';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Footer';

export default function () {
  const pageTitle = 'Welcome to the Thriving on a Plant-Based Diet Workshop';
  const pageDescription =
    'Get actionable insights from world-leading experts on how to optimize your health with a plant-based diet. Join this free live workshop and start your journey towards better health and happiness!';
  const shareUrl = 'https://wlc.foodrevolution.org/workshop/';
  const ogImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-facebook-202405.png';
  const pinterestImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-pinterest-202405.png';
  const facebookDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const twitterDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const pinterestDescription =
    'Join us in this free live workshop to discover the benefits of a plant-based diet! Get practical tips from nutrition experts to improve your health and happiness. Register now for free and take your first step towards a healthier you!';

  useBalanceText();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />
      <div className="page-content d-flex flex-column flex-grow-1">
        <Section
          id="welcome-header"
          color="light-gray"
          className="background-cover background-fixed background-image-1524823226 text-center text-white d-flex flex-column flex-grow-1 section-angle-first"
          innerWrapClass="d-flex flex-column flex-grow-1"
          innerClass="py-5 my-auto"
          angle="none"
        >
          <Container>
            <Row>
              <Col>
                <div className="background-black-90 rounded p-4 p-lg-5">
                  <h1 className="section-heading text-h2">
                    Thank You for Joining the Thriving on a Plant-Based Diet: 10 Tips for Maximizing Your Health and
                    Happiness WORKSHOP!
                  </h1>
                  <h4 className="mt-0">You’ll get an email with your next steps in a few minutes.</h4>
                  <p className="text-h5">We can’t wait 🌱</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons
          shareUrl={shareUrl}
          shareImage={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          floating
        />
      </div>
    </div>
  );
}
