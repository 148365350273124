import React from 'react';
import {navigate} from '@reach/router';
import {ModalBody, Button} from 'reactstrap';
import classNames from 'classnames';

import {useModal} from 'contexts/modal';

import Modal from 'components/Modal';
import OptInForm from 'components/OptInForm';
import OptInFormWorkshop from 'components/OptInFormWorkshop';

export default function OptinModal({entry = 'default', disableNavigate = false, children, ...props}) {
  const modalProps = useModal();
  const [navigateOnClose, setNavigateOnClose] = React.useState(false);
  const onComplete = React.useCallback(() => {
    modalProps.toggle();
    !disableNavigate && navigate('/welcome/', {state: {fromEntry: entry}});
  }, []);
  const onStepComplete = React.useCallback((step) => step === 1 && setNavigateOnClose(true), []);
  const toggle = React.useCallback(() => {
    if (navigateOnClose && !disableNavigate) navigate('/welcome/', {state: {fromEntry: entry}});
    modalProps.toggle();
  }, [navigateOnClose]);
  return (
    <Modal {...props} isOpen={modalProps.isOpen} toggle={toggle} size="lg">
      <ModalBody className="px-4 pt-0 pb-4">
        <OptInForm onComplete={onComplete} onStepComplete={onStepComplete} id="modalOptInForm" entry={entry} />
      </ModalBody>
    </Modal>
  );
}

export function OptinModalWorkshop({
  entry = 'default',
  navigateUrl,
  disableNavigate = false,
  formHeader = undefined,
  btnText = undefined,
  ...props
}) {
  const modalProps = useModal();
  const [navigateOnClose, setNavigateOnClose] = React.useState(false);
  const onComplete = React.useCallback(() => {
    modalProps.toggle();
    !disableNavigate && navigate('/welcome/', {state: {fromEntry: entry}});
  }, []);
  const onStepComplete = React.useCallback((step) => step === 1 && setNavigateOnClose(true), []);
  const toggle = React.useCallback(() => {
    if (navigateOnClose && !disableNavigate) navigate(navigateUrl, {state: {fromEntry: entry}});
    modalProps.toggle();
  }, [navigateOnClose]);
  return (
    <Modal {...props} isOpen={modalProps.isOpen} toggle={toggle} size="lg">
      <ModalBody className="px-4 pt-0 pb-4">
        <OptInFormWorkshop
          onComplete={onComplete}
          onStepComplete={onStepComplete}
          id="modalOptInForm"
          entry={entry}
          navigateUrl={navigateUrl}
          formHeader={formHeader}
          btnText={btnText}
        />
      </ModalBody>
    </Modal>
  );
}

export function OptinModalTrigger({children = null, ...props}) {
  const className = classNames('btn-size-350 text-uppercase text-white text-condensed text-800', props.className);
  const modalProps = useModal();
  return (
    <Button color="cta" size="lg" {...props} className={className} onClick={modalProps.toggle}>
      {children ? children : 'Sign Up Now!'}
    </Button>
  );
}
