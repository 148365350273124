import React from 'react';
import {Form, Formik, Field} from 'formik';
import {Alert, Button, FormGroup, Input, Label} from 'reactstrap';

import {useUserDispatch, useUserState} from 'contexts/user';
import {submitForm} from 'utils/forms';

import CustomRadioField from '../CustomRadioField';
import CustomInputField from '../CustomInputField';

const {SEGMENTATION_FORM_ID} = process.env;

export default function({onComplete = () => {}}) {
  const userDispatch = useUserDispatch();
  const {email} = useUserState();
  return (
    <>
      <p className="text-center mb-2">One more question:</p>
      <div className="optin-wrap">
        <Formik
          initialValues={{
            summithope: ''
          }}
          onSubmit={(values, actions) => {
            if (!values || !values.summithope) {
              return onComplete();
            }
            return submitForm({email, summithope: values.summithope}, SEGMENTATION_FORM_ID)
              .then(() => onComplete())
              .catch(e => {
                actions.setStatus({error: e});
                actions.setSubmitting(false);
              });
          }}
          render={({values, handleSubmit, isSubmitting, isValid, handleReset, status, ...props}) => (
            <Form onSubmit={handleSubmit}>
              {status && status.error && <Alert color="danger">{status.error}</Alert>}
              <FormGroup tag="fieldset" value={values.summithope}>
                <p className="text-center mb-4">
                  <strong>
                    What are you most hoping to get <br className="d-none d-md-block d-lg-none" />
                    out of the Summit?
                  </strong>
                </p>
                <FormGroup className="mb-0">
                  <Label for="summithope" hidden>
                    What are you most hoping to get out of the Summit?
                  </Label>
                  <Field
                    name="summithope"
                    type={'text'}
                    component={CustomInputField}
                    placeholder="Type your answer here (optional)"
                    bsSize="lg"
                  />
                </FormGroup>
              </FormGroup>

              <div className="btn-wrap">
                <Button
                  className="btn-submit text-uppercase text-h4 text-condensed text-800"
                  color="cta"
                  type="submit"
                  disabled={isSubmitting}
                  block
                >
                  {isSubmitting ? 'Loading...' : 'Join In Now'}
                </Button>
                <Button className="btn-skip text-h4" color="outline" onClick={onComplete} block>
                  Skip
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    </>
  );
}
