import React, {Component, useState} from 'react';
import {Col, Container, Row, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';
import FacebookLikeButton from 'components/Social/FacebookLikeButton';
import ShareButtons from 'components/Social/ShareButtons';
import logo from 'static/logo/frn-wlc-ah.svg';
import logoWhite from 'static/logo/frn-wlc-ah-white.svg';
import logoPlural from 'static/logo/frn-wlc-ah-plural.svg';
import logoWhitePlural from 'static/logo/frn-wlc-ah-plural-white.svg';
import classNames from 'classnames';
import './Header.scss';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <header className={classNames(`header ${this.props.className}`)} role="banner">
        <Container className="py-2">
          <Navbar className={classNames(`p-0 navbar-${this.props.style}`)} expand="lg">
            <NavbarBrand className="p-0 m-0">
              <div className="logo-img">
                {this.props.logoWhite ? (
                  <>
                    {this.props.logo === 'plural' ? (
                      <img className="logo-img-plural" src={logoWhitePlural} />
                    ) : (
                      <img className="logo-img-single" src={logoWhite} />
                    )}
                  </>
                ) : (
                  <>
                    <img src={logo} />
                  </>
                )}
              </div>
            </NavbarBrand>

            <div className="d-flex align-items-center fb-wrap mr-sm-2" style={{maxHeight: '50px'}}>
              <div className="d-none d-lg-inline mr-1">
                <ShareButtons {...this.props} noDescription />
              </div>
              <FacebookLikeButton />
            </div>

            <NavbarToggler onClick={this.toggle} />

            <Collapse className="mt-3 mt-lg-0" isOpen={this.state.isOpen} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink href="https://support.foodrevolution.org/" target="_blank">
                    Support
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://community.foodrevolution.org/" target="_blank">
                    Login
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
        {this.props.covidBanner ? (
          <div className="header-banner-announcement background-green text-white py-3">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col>
                  <p className="text-center mb-0">
                    <strong>In the time of COVID-19, your health is more important than ever.</strong>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </header>
    );
  }
}

Header.defaultProps = {
  className: ''
};

export const withHeader = (BaseComponent) => (props) =>
  (
    <div>
      <Header />
      <BaseComponent {...props} />
    </div>
  );

export function FrnHeader({
  shareUrl = '',
  facebookDescription = '',
  twitterDescription = '',
  twitterHashtags = undefined,
  pinterestDescription = '',
  pinterestImage = '',
  logoUrl = 'https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-white.svg',
  logoAlt = 'Food Revolution Network logo',
  logoWidth = 300,
  logoHeight = 126,
  bgColor = 'black',
  textColor = 'white',
  className = undefined
}) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <header
      className={classNames(`header`, `background-${bgColor}`, `text-${textColor}`, `d-flex`, `flex-column`, className)}
    >
      <Container className={classNames(`headerContainer`, `my-auto`)}>
        <Navbar className={classNames(`headerNavbar`, `p-0`)} expand="lg" container={false}>
          <div className={classNames(`headerNavbarBrand`)}>
            <img
              className={classNames(`headerLogo`)}
              alt={logoAlt}
              src={logoUrl}
              width={logoWidth}
              height={logoHeight}
            />
          </div>

          <div className={classNames(`headerFbWrap`, `d-flex`, `align-items-center`, `me-sm-2`)}>
            <FacebookLikeButton />
            <div className={classNames(`d-none`, `d-lg-inline`, `ms-1`)}>
              <ShareButtons
                shareUrl={shareUrl}
                facebookDescription={facebookDescription}
                twitterDescription={twitterDescription}
                twitterHashtags={twitterHashtags}
                pinterestDescription={pinterestDescription}
                pinterestImage={pinterestImage}
              />
            </div>
          </div>

          <NavbarToggler onClick={toggleNavbar} />

          <Collapse className={classNames(`headerCollapse`, `mt-2`, `mt-lg-0`)} isOpen={!collapsed} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink href="https://support.foodrevolution.org/" target="_blank">
                  Support
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://community.foodrevolution.org/" target="_blank">
                  Login
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
