import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';

const {LETREACH_API_KEY} = process.env;
const {LETREACH_DEFAULT_SEGMENT} = process.env;

export default function useLetReach({apiKey = LETREACH_API_KEY, segment = LETREACH_DEFAULT_SEGMENT} = {}) {
  if (!apiKey) return null;
  return useEffect(() => {
    const scriptEl = document.createElement('script');
    const inputEl = document.createElement('input');
    scriptEl.src = `//cdn.letreach.com/js/main/${apiKey}.js`;

    if (segment) {
      inputEl.id = 'letreach_post_category';
      inputEl.type = 'hidden';
      inputEl.name = 'letreach_post_category';
      inputEl.value = segment;
      document.body.appendChild(inputEl);
    }

    document.body.appendChild(scriptEl);
    return () => {
      scriptEl.remove();
      inputEl.remove();
    };
  }, [apiKey, segment]);
}
