import React, {useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import Video from 'components/Video';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import {days} from 'data/speaker.json';
import './ReplayTabs.scss';
import {usePhaseState} from 'funnel-schedule/phase-context';

export default function ReplayTabs({day = 0, activePanel = 0, replayUrl = '', dailyWrapUrl = ''}) {
  const {title, theme, number} = days[day];
  return activePanel === 0 ? (
    <ReplayPanel title={title} theme={theme} number={number} youtubeUrl={replayUrl} />
  ) : (
    <ReplayWrapPanel dailyWrapUrl={dailyWrapUrl} />
  );
}

export function WlcReplayTabMap({activePanel = 0, ...props}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: ''
          }
        ]
      }
    }
  } = usePhaseState();
  const {
    title,
    video,
    speaker: {name}
  } = defaultSchedule.actionHours.slice(0, defaultSchedule.currentActionHour + 1).reverse()[activePanel];
  return (
    <div className={classNames(`replay-panel text-center mb-5 mb-lg-0`, props.className)}>
      <div className="mb-4">
        {video ? (
          <Video url={video} />
        ) : (
          <>
            <strong>The Action Hour Replay will be available after it broadcasts!</strong>
          </>
        )}
      </div>
      <div className="replay-headings">
        <h3 className="replay-panel-title section-heading mb-2">{title}</h3>
        <h5 className="text-400 mt-0">With Health Revolutionary {name}</h5>
        <p className="text-16">
          Please note: The YouTube player on this page may <br className="d-none d-sm-block d-md-none" />
          start with a few minutes of <br className="d-none d-md-block" />
          music. You can skip it by using the <br className="d-none d-sm-block d-md-none" />
          controls on the bottom of the player.
        </p>
      </div>
    </div>
  );
}

export function WlcReplayTabNav({activePanel = 0, setActivePanel}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: ''
          }
        ]
      }
    }
  } = usePhaseState();
  return defaultSchedule.actionHours
    .slice(0, defaultSchedule.currentActionHour + 1)
    .reverse()
    .map((actionHour, i) => (
      <>
        <div
          className={
            activePanel === i
              ? 'd-flex align-items-center replay-tab replay-tab-active'
              : 'd-flex align-items-center replay-tab'
          }
          onClick={() => setActivePanel(i)}
        >
          <div className="replay-tab-icon">
            {activePanel === i ? (
              <div className="replay-icon replay-icon-play">
                <FontAwesomeIcon icon={faPlay} />
              </div>
            ) : (
              <div className="replay-icon replay-icon-number">{i + 1}</div>
            )}
          </div>
          <div className="replay-tab-image">
            <img src={actionHour.thumb} />
          </div>
          <div className="replay-tab-title d-flex flex-column">
            <div className="replay-title">
              {actionHour.title} <br />
              <span className="text-400">with {actionHour.speaker.name}</span>
            </div>
          </div>
        </div>
      </>
    ));
}

export function ReplayTabNav({day = 0, activePanel = 0, setActivePanel}) {
  const {title, thumb, dailyWrapThumb, number} = days[day];
  const tabs = [{name: `Replay Day #${number}: ${title}`, thumb: thumb}];
  day !== 8 &&
    tabs.push({
      name: (
        <>
          <em>Daily Wrap</em> with John, Ocean, and Dr. Joel Kahn
        </>
      ),
      thumb: dailyWrapThumb
    });
  return tabs.map(({name, thumb}, i) => (
    <div
      className={
        activePanel === i
          ? 'd-flex align-items-center replay-tab replay-tab-active'
          : 'd-flex align-items-center replay-tab'
      }
      onClick={() => setActivePanel(i)}
      key={i}
    >
      <div className="resource-tab-icon">
        {activePanel === i ? (
          <div className="resource-icon resource-icon-play">
            <FontAwesomeIcon icon={faPlay} />
          </div>
        ) : (
          <div className="resource-icon resource-icon-number">{i + 1}</div>
        )}
      </div>
      <div className="replay-tab-image">
        <img src={thumb} />
      </div>
      <div className="replay-tab-title d-flex flex-column">
        <div className="replay-title">{name}</div>
      </div>
    </div>
  ));
}

export function ReplayTabWrapNav({activePanel = 0, setActivePanel}) {
  return days.map(({title, thumb, number}, i) => (
    <div
      className={
        activePanel === i
          ? 'd-flex align-items-center replay-tab replay-tab-active'
          : 'd-flex align-items-center replay-tab'
      }
      onClick={() => setActivePanel(i)}
    >
      <div className="resource-tab-icon">
        {activePanel === i ? (
          <div className="resource-icon resource-icon-play">
            <FontAwesomeIcon icon={faPlay} />
          </div>
        ) : (
          <div className="resource-icon resource-icon-number">{i + 1}</div>
        )}
      </div>
      <div className="replay-tab-image">
        <img src={thumb} />
      </div>
      <div className="replay-tab-title d-flex flex-column">
        <div className="replay-title">
          <em>Daily Wrap</em> with John, Ocean, and Dr. Joel Kahn
        </div>
      </div>
    </div>
  ));
}

export function ReplayPanel({className = '', ...props}) {
  return (
    <div className={classNames(`replay-panel text-center mb-5 mb-lg-0`, className)}>
      <div className="mb-4">
        {props.youtubeUrl ? <Video url={props.youtubeUrl} /> : <strong>Today’s replay will be available soon!</strong>}
      </div>
      <div className="replay-headings">
        <h2 className="section-only-heading mb-2">
          Replay <span className="text-h4">(so you don’t miss out!)</span>
        </h2>
        <h4 className="mt-0">
          Day #{props.number}: {props.title}
        </h4>
        <p className="text-16">
          Please note: The YouTube player on this page may <br className="d-none d-sm-block d-md-none" />
          start with a few minutes of <br className="d-none d-md-block" />
          music. You can skip it by using the <br className="d-none d-sm-block d-md-none" />
          controls on the bottom of the player.
        </p>
      </div>
    </div>
  );
}

export function ReplayWrapPanel({className = '', ...props}) {
  return (
    <div className={classNames(`replay-panel text-center mb-5 mb-lg-0`, className)}>
      <div className="mb-4">
        {props.dailyWrapUrl ? (
          <Video url={props.dailyWrapUrl} />
        ) : (
          <strong>
            Today’s <em>Daily Wrap</em> will be available soon!
          </strong>
        )}
      </div>
      <div className="daily-wrap-headings">
        <h2 className="section-heading mb-2">
          Daily Wrap <span className="text-h4">(savor, reflect, and digest)</span>
        </h2>
        <p>with John, Ocean, and Dr. Joel Kahn</p>
      </div>
    </div>
  );
}
