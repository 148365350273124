import React from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {DateTime} from 'luxon';
import ReactMarkdown from 'react-markdown';
import CheckoutButton from 'components/CheckoutButton';
import CountdownTimer from '../Elements/CountdownTimer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faCartPlus} from '@fortawesome/pro-light-svg-icons';
import './SalesCta.scss';
import logoTrees from 'static/logo/logo-trees-for-future-white.png';
import classNames from 'classnames';
import SalesTimerSection from 'components/Whole/TimerSection';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {usePhaseState} from 'funnel-schedule/phase-context';

function CtaList({variant = null, ...props}) {
  return (
    <>
      <IconList className="text-base">
        <IconListItem>
          6 delicious, whole foods,{' '}
          <b>
            plant-powered <br className="d-none d-sm-block d-xl-none" />
            recipes
          </b>{' '}
          per week.
        </IconListItem>
        <IconListItem>
          {variant === 'ad' ? (
            <>
              <b>Weekly videos</b> from Ocean Robbins
            </>
          ) : (
            <>
              <b>Weekly videos</b> from Ocean Robbins delivered <br className="d-none d-sm-block d-xl-none" />
              to your inbox.
            </>
          )}
        </IconListItem>
        <IconListItem>
          <b>Monthly Action Hour</b> events with health revolutionaries.
        </IconListItem>
        <IconListItem>
          {variant === 'ad' ? (
            <>
              <b>Action Checklists</b> to stay on track.
            </>
          ) : (
            <>
              <b>Action Checklists</b> to keep you on track.
            </>
          )}
        </IconListItem>
        <IconListItem>
          <b>100s of fabulous recipes</b> with new options <br className="d-none d-sm-block d-xl-none" />
          added all the time.
        </IconListItem>
        <IconListItem>
          A private, moderated <b>community.</b>
        </IconListItem>
        <IconListItem>
          Access to <b>Health Coaches & Dieticians.</b>
        </IconListItem>
        <IconListItem>
          Access to the entire library of <b>150+ videos.</b>
        </IconListItem>
        <IconListItem>
          <b>500+ health articles.</b>
        </IconListItem>
        <IconListItem>
          <b>Annual rates that will never go up</b> as long as <br className="d-none d-sm-block" />
          you remain a member.
        </IconListItem>
        <IconListItem>
          A <b>state-of-the-art membership site</b> that’s easy <br className="d-none d-sm-block d-md-none" />
          to navigate and much more!
        </IconListItem>
      </IconList>
    </>
  );
}

export function SalesDisclaimer({iconColor = 'green', ...props}) {
  return (
    <div className="disclaimer-wrap">
      <div className="disclaimer-cards">
        <img
          className="img-fluid img-cc"
          src="https://cdn.foodrevolution.org/global/images/payments-accepted-20240424.svg"
          width={1800}
          height={175}
        />
      </div>
      <div className="disclaimer-copy text-small">
        <p className={props.noGuaranteeText ? 'mb-0' : 'mb-2'}>
          <FontAwesomeIcon className={classNames(`offer-box-security-icon text-${iconColor} mr-2`)} icon={faLock} /> For
          your security, all orders are processed on a secured server.
        </p>
        {props.noGuaranteeText ? null : (
          <p className="mb-0">Your purchase is protected by our 60-day money back guarantee.</p>
        )}
      </div>
    </div>
  );
}

export function TreesForTheFuture(props) {
  return (
    <Row
      className={classNames(
        `trees-for-future text-white d-flex align-items-center justify-content-center`,
        props.className
      )}
    >
      <Col xs="11" lg="8" className="mb-4 mb-lg-0">
        <h6 className="section-only-heading text-center text-lg-left text-400">
          For every new order, we make a donation to Trees for the Future, enabling them to plant another organic fruit
          or nut tree in a low-income community.
        </h6>
      </Col>
      <Col xs="11" lg="3" className="text-center">
        <img className="logo-trees" src={logoTrees} />
      </Col>
    </Row>
  );
}

export function SalesBox({
  offerBoxBackground = 'white',
  offerBoxBorder = 'border-dashed border-color-green-100 border-2',
  btnText = 'Buy Now',
  imgSrc = 'https://cdn.foodrevolution.org/wlc/wlc-product-image.png',
  imgWidth = '1200',
  imgHeight = '778',
  imgAlt = 'WHOLE Life Club Package graphic',
  brainOffer = false,
  ...props
}) {
  return (
    <div className={classNames(`cta-sales cta-sales-${props.className}`)}>
      {props.title ? <>{props.title}</> : null}
      {props.subTitle ? <>{props.subTitle}</> : null}

      <div
        className={classNames(
          `offer-box offer-box-shadow px-3 py-4 px-sm-4 px-md-5 py-md-5 text-center background-${offerBoxBackground} ${offerBoxBorder}`
        )}
      >
        {props.noImage ? null : (
          <Row className="d-flex justify-content-center">
            <Col xs="10" md="8" xl="6">
              <img src={imgSrc} width={imgWidth} height={imgHeight} alt={imgAlt} />
            </Col>
          </Row>
        )}

        {props.heading ? (
          <ReactMarkdown
            className="offer-box-heading"
            source={props.heading || 'Get instant access — even at 2 am – guaranteed!'}
            skipHtml={true}
          />
        ) : null}

        {props.offerPrice ? (
          <>
            <div className="offer-box-offer-text-regular">{props.regularOffer}</div>
            <div className="offer-box-regular-price">{props.offerText}</div>
          </>
        ) : (
          <div className="offer-box-regular-price">{props.regularOffer}</div>
        )}

        {props.timerText ? (
          <div className="offer-box-timer">
            <div className="offer-box-timer-text">{props.timerText}</div>
            <div className="countdown-dark">
              <CountdownTimer time={props.date} />
            </div>
          </div>
        ) : null}

        <CheckoutButton
          className="offer-box-button"
          sku={props.sku}
          price={props.offerPrice ? props.offerPrice : props.price}
          slug={props.slug}
        >
          <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> {btnText}
        </CheckoutButton>

        <SalesDisclaimer />

        {props.paymentPlanPrice ? (
          <div className="offer-box-payment-plan mt-3">
            Prefer a 3-month payment plan? We’ve got you covered!
            <CheckoutButton
              color="link"
              className="btn-link-checkout ml-1"
              sku={props.sku}
              price={props.paymentPlanPrice}
              slug={props.slug}
              queryParams={{opt: 1}}
            >
              Join now for just ${props.paymentPlanPrice}/month!
            </CheckoutButton>
          </div>
        ) : null}
      </div>

      {props.trees ? <TreesForTheFuture className="mt-5" /> : null}

      {brainOffer ? (
        <Row className="text-white d-flex align-items-center justify-content-center mt-5">
          <Col xs="12" md="10" lg="7" className="mb-4 mb-lg-0">
            <h5 className="section-heading mb-2">Plus, if you join now — from this page ONLY</h5>
            <h5 className="mt-0 text-400">
              You’ll get instant access to your{' '}
              <strong>
                Brain <br className="d-none d-sm-block d-xl-none" />
                Power Collection…
              </strong>
            </h5>
            <p>
              3 brain-boosting <strong>expert interviews</strong> and <strong>Action Checklists</strong> with{' '}
              <br className="d-none d-sm-block d-md-none d-xl-block" />
              simple steps you can follow to <strong>protect your brain today</strong> — and for{' '}
              <br className="d-none d-sm-block d-md-none d-xl-block" />
              the rest of your life.
            </p>
          </Col>
          <Col xs="10" md="8" lg="4" className="text-center">
            <img src="https://cdn.foodrevolution.org/wlc/brain-power-collection.png" />
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

export function CtaCard({
  cardTitle = <h3 className="purchase-card-title text-uppercase text-center m-0">Get Started Now!</h3>,
  salesOffer,
  sku,
  price,
  slug,
  ...props
}) {
  return (
    <div className="purchase-card">
      <div className="purchase-card-header background-black text-white rounded-top p-3 p-lg-4">{cardTitle}</div>
      <div className="purchase-card-content background-white rounded-bottom px-4 px-lg-5 py-4">
        {salesOffer}
        <div className="text-center">
          <CheckoutButton className="offer-box-button" sku={sku} price={price} slug={slug} />
        </div>
        <SalesDisclaimer />
        {props.extraDisclaimer}
      </div>
    </div>
  );
}

export function SalesCardWholeDynamic({variant = null, props}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [],
        isSalesDay: true,
        registrationWindow: '',
        salesHeaderMessage: '',
        salesCtaBoxCopy: '',
        salesCtaSectionHeadline: ''
      },
      sales: salesSchedule = {
        phase: 'before',
        salesEndDate: ''
      }
    }
  } = usePhaseState();
  const isSeries = defaultSchedule.actionHours.length > 1;
  const isSpecialSale = salesSchedule.phase === 'during';
  const isDollarTrial = salesSchedule.offerFlag === 'dollarTrial';
  return (
    <CtaCard
      sku="WLC"
      price={isDollarTrial ? '1' : isSeries || isSpecialSale ? '127' : '247'}
      slug={isDollarTrial ? 'wlc-promo-30daytrial' : isSeries || isSpecialSale ? 'wlc-promo' : 'wlc'}
      {...props}
      salesOffer={
        <>
          <div className="text-center mb-4">
            {isDollarTrial ? (
              <h3 className="text-green m-0">Join For Just $1 Today</h3>
            ) : isSeries || isSpecialSale ? (
              <>
                <h3 className="text-green m-0">
                  Join now for just $127/year <br />
                  (or $19/month)
                </h3>
              </>
            ) : (
              <>
                <h3 className="text-green m-0">Join for just $247/year or just $19/month</h3>
              </>
            )}
          </div>
          <div className="list-simple-check-wrap">
            <CtaList variant={variant} />
          </div>
          <div className="text-center mb-3">
            <p className="my-4">
              <b>
                The doors to WHOLE Life Club are open with this special offer for just a few days. Get in now with a
                stellar group of cohorts (and save big!) today.
              </b>
            </p>
            <h3 className="section-heading text-green mb-2">
              {isDollarTrial ? (
                <>
                  Only $1 for a month trial
                  <br />
                  Then <del>$247</del> $99/year
                </>
              ) : isSeries || isSpecialSale ? (
                <>
                  Only <del>$247</del> $127/year
                </>
              ) : (
                <>Only $247/year</>
              )}
            </h3>
            <h5 className="text-400 mt-0 mb-3">
              (That’s just {isDollarTrial ? '$0.27' : isSeries || isSpecialSale ? '$0.35' : 'pennies'} a day)
            </h5>
            <h5 className="m-0">{isDollarTrial || isSeries || isSpecialSale ? 'Or $19/month' : 'Or $29/month'}</h5>
          </div>
        </>
      }
      extraDisclaimer={
        isDollarTrial ? (
          <p className="text-small text-center mt-2">
            Join WHOLE Life Club for just $1 for your first month. <br className="d-none d-sm-block d-lg-none" />
            After that, <br className="d-none d-lg-block" />
            you’ll be enrolled for a special membership price <br className="d-none d-sm-block d-lg-none" />
            of just $99 a year <br className="d-none d-lg-block" />
            (that’s 27 cents per day) unless you <br className="d-none d-sm-block d-lg-none" />
            cancel before your month is up.
          </p>
        ) : null
      }
    />
  );
}

export function SalesCard99({variant = null, ...props}) {
  const {
    phases: {
      specialSales: specialSalesSchedule = {
        phase: '',
        specialSalesEndDate: ''
      }
    }
  } = usePhaseState();
  const dateObj = DateTime.fromISO(specialSalesSchedule.specialSalesEndDate, {zone: 'America/Los_Angeles'});
  return (
    <CtaCard
      sku="WLC"
      price="99"
      slug="wlc-99"
      {...props}
      cardTitle={<h3 className="purchase-card-title text-uppercase text-center m-0">Rejoin (And Save Big) Now!</h3>}
      salesOffer={
        <>
          <div className="text-center mb-4">
            <h4 className="mt-0 mb-2">Save 56%</h4>
            <h3 className="text-green mt-0 mb-3">
              Rejoin now for just <br className="d-none d-sm-block d-md-none d-xl-block" />
              <del>$29/month</del> $99 a year.
            </h3>
            <p>
              {props.ctaNoTimer ? (
                <>
                  (This is a special “we want you back” rejoining offer for <br className="d-none d-sm-block" />
                  past members. It’s the lowest price you’ll ever see.)
                </>
              ) : (
                <>
                  (This is a special “we want you back” rejoining offer <br className="d-none d-sm-block d-md-none" />
                  for past members. <br className="d-none d-md-block d-lg-none" />
                  It’s the lowest price you’ll ever see, <br className="d-none d-sm-block d-md-none" />
                  only available until {dateObj.setZone('America/Los_Angeles').toFormat('MMMM dd')}.)
                </>
              )}
            </p>
          </div>
          <div className="list-simple-check-wrap">
            <CtaList variant={variant} />
          </div>
          <div className="text-center mb-3">
            <h3 className="section-heading text-green mb-3">
              <del>$29/month</del>
            </h3>
            <h5 className="m-0">
              Save BIG when you rejoin with <br className="d-none d-xl-block" />
              an annual membership — now (and now only) <br className="d-none d-xl-block" />
              just $99/year! (That’s less than $9/month!)
            </h5>
          </div>
        </>
      }
    />
  );
}

// Special Sales or Launch Offer Box
export function Sales127({brainOffer = false, ...props}) {
  return (
    <SalesBox
      className="offer-127"
      heading="Get instant access — even at 2 am – guaranteed!"
      regularPrice="247"
      regularOffer={
        <>
          All for just $29/month (that’s $348 annually) <br className="d-none d-sm-block" />
          or save big for $247/year
        </>
      }
      offerPrice="127"
      offerText={
        <>
          Special Offer: <br className="d-none d-sm-block" />
          $19/month or $127/year!
        </>
      }
      sku="WLC"
      slug={brainOffer ? 'wlc-promo-special' : 'wlc-promo'}
      brainOffer={brainOffer}
      {...props}
    />
  );
}

// Welcome Page Sales Offer Box
export function Sales147({brainOffer = true, ...props}) {
  return (
    <SalesBox
      className="offer-147"
      heading="Get instant access — even at 2 am – guaranteed!"
      regularPrice="247"
      regularOffer={
        <>
          All for just $29/month (that’s $348 annually) <br className="d-none d-lg-block" />
          or save big for $247/year
        </>
      }
      offerPrice="147"
      offerText={
        <>
          Special Offer: <br className="d-none d-sm-block" />
          $19/month or $147/year!
        </>
      }
      sku="WLC"
      slug="wlc-special-brain"
      brainOffer={brainOffer}
      {...props}
    />
  );
}

// Full Price Offer Box
export function Sales247(props) {
  return (
    <SalesBox
      className="offer-247"
      heading="Get instant access — even at 2 am – guaranteed!"
      regularPrice="247"
      regularOffer={
        <>
          All for just $29/month (that’s $348 annually) <br className="d-none d-lg-block" />
          or save big for $247/year
        </>
      }
      sku="WLC"
      slug="wlc"
      trees
      {...props}
    />
  );
}

export default function CtaSales(props) {
  const offerBox = classNames(
    'offer-box offer-box-shadow px-3 py-4 px-sm-4 px-md-5 py-md-5 text-center',
    props.offerBoxBackground,
    props.offerBoxBorder
  );

  const date = new Date(`${props.timerDate}`).getTime();

  return (
    <div className={classNames(`cta-sales cta-sales-${props.className}`)}>
      <div className={offerBox}>
        {props.heading ? (
          <ReactMarkdown
            className="offer-box-heading"
            source={props.heading || 'Get instant access — even at 2 am – guaranteed!'}
            skipHtml={true}
          />
        ) : null}

        {props.offerPrice ? (
          <>
            {props.offerTextInline ? (
              <div className="offer-box-offer-text-inline">
                {props.offerTextInline} <del className="regular-price">${props.regularPrice}</del>{' '}
                <span className="offer-price">${props.offerPrice}</span>
              </div>
            ) : (
              <div className="offer-box-offer">
                <div className="offer-box-offer-text-regular">{props.regularOffer}</div>
                <div className="offer-box-offer-text">{props.offerText}</div>
              </div>
            )}
          </>
        ) : (
          <div className="offer-box-regular-price">
            <div className="offer-box-offer-text">{props.regularOffer}</div>
          </div>
        )}

        {props.noImage ? null : (
          <Row className="d-flex justify-content-center">
            <Col xs="10" md="8" xl="6">
              <img src={props.imgSrc} width={props.imgWidth} height={props.imgHeight} alt={props.imgAlt} />
            </Col>
          </Row>
        )}

        {props.savingsText ? (
          <>
            {props.savingsTextLineTwoOfferPrice ? (
              <>
                <ReactMarkdown className="offer-box-savings-text line-one" source={props.savingsText} />
                <div className="offer-box-savings-text line-two">
                  Or sign up for the monthly rate of just <del>{props.savingsTextLineTwoRegularPrice}</del>{' '}
                  {props.savingsTextLineTwoOfferPrice} per month
                </div>
              </>
            ) : (
              <ReactMarkdown className="offer-box-savings-text" source={props.savingsText} />
            )}
          </>
        ) : null}

        {props.timerText ? (
          <div className="offer-box-timer">
            <div className="offer-box-timer-text">{props.timerText}</div>
            <div className="countdown-dark">
              <CountdownTimer time={date} />
            </div>
          </div>
        ) : null}

        {props.offerPrice ? (
          <CheckoutButton className="offer-box-button" sku={props.sku} price={props.offerPrice} slug={props.slug}>
            <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> {props.btnText}
          </CheckoutButton>
        ) : (
          <CheckoutButton className="offer-box-button" sku={props.sku} price={props.regularPrice} slug={props.slug}>
            <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> {props.btnText}
          </CheckoutButton>
        )}

        <div className="offer-box-details">
          <div className="offer-box-cards">
            <img
              className="img-fluid img-cc"
              src="https://cdn.foodrevolution.org/global/images/payments-accepted-20240424.svg"
              width={1800}
              height={175}
            />
          </div>
          <div className="offer-box-security">
            <FontAwesomeIcon className="offer-box-security-icon mr-2" icon={faLock} />
            For your security, all orders are processed on a secured server.
          </div>
          <div className="offer-box-protected d-flex justify-content-center">
            Your purchase is protected by our 60-day money back guarantee.
          </div>
        </div>

        {props.paymentPlanText ? (
          <div className="offer-box-payment-plan">
            {props.paymentPlanText}
            <CheckoutButton
              color="link"
              className="btn-link-checkout ml-1"
              sku={props.sku}
              price={props.paymentPlanPrice}
              slug={props.slug}
              queryParams={{opt: 1}}
            >
              Join now for just ${props.paymentPlanPrice}/month!
            </CheckoutButton>
          </div>
        ) : null}
      </div>

      {props.salesTimer ? (
        <Row className="text-white text-center mt-5">
          <Col>
            <SalesTimerSection />
          </Col>
        </Row>
      ) : null}

      {props.noTrees ? null : (
        <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
          <Col xs="11" lg="8" className="mb-4 mb-lg-0">
            <h4 className="section-heading mb-3 text-center text-lg-left">
              Do you want a world where everyone <br className="d-none d-md-block d-lg-none" />
              has safe <br className="d-none d-xl-block" />
              and healthy food?
            </h4>
            <h6 className="section-only-heading text-center text-lg-left text-400">
              {props.wlcTrees ? (
                <>
                  We do, too! For <strong>every</strong> WHOLE Life Club{' '}
                  <strong>
                    membership <br className="d-none d-md-block d-lg-none" />
                    purchased,
                  </strong>{' '}
                  we’ll make a donation to Trees for the Future, enabling them to{' '}
                  <strong>plant another organic fruit or nut tree</strong>{' '}
                  <br className="d-none d-md-block d-lg-none" />
                  in a low-income community.
                </>
              ) : (
                <>
                  We do, too! For every new order, we make a donation to Trees for the Future, enabling them to plant
                  another organic fruit or nut tree in a <br className="d-none d-xl-block" />
                  low-income community.
                </>
              )}
            </h6>
          </Col>
          <Col xs="11" lg="3" className="text-center">
            <img className="logo-trees" src={logoTrees} />
          </Col>
        </Row>
      )}

      {props.brainOffer ? (
        <Row className="text-white d-flex align-items-center justify-content-center mt-5">
          <Col xs="12" md="10" lg="7" className="mb-4 mb-lg-0">
            <h5 className="section-heading mb-2">Plus, if you join now — from this page ONLY</h5>
            <h5 className="mt-0 text-400">
              You’ll get instant access to your{' '}
              <strong>
                Brain <br className="d-none d-sm-block d-xl-none" />
                Power Collection…
              </strong>
            </h5>
            <p>
              3 brain-boosting <strong>expert interviews</strong> and <strong>Action Checklists</strong> with{' '}
              <br className="d-none d-sm-block d-md-none d-xl-block" />
              simple steps you can follow to <strong>protect your brain today</strong> — and for{' '}
              <br className="d-none d-sm-block d-md-none d-xl-block" />
              the rest of your life.
            </p>
          </Col>
          <Col xs="10" md="8" lg="4" className="text-center">
            <img src="https://cdn.foodrevolution.org/wlc/brain-power-collection.png" />
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

CtaSales.defaultProps = {
  offerBoxBackground: 'background-white',
  offerBoxBorder: 'border-dashed border-green border-2',
  imgSrc: 'https://cdn.foodrevolution.org/wlc/wlc-product-image.png',
  imgWidth: '1200',
  imgHeight: '778',
  imgAlt: 'WHOLE Life Club Package graphic',
  btnText: 'Buy Now'
};

export function WelcomeCta147(props) {
  return (
    <CtaSales
      className="offer-147"
      regularPrice="247"
      offerPrice="147"
      offerText="Special Offer: $19/month or $147/year!"
      regularOffer="Your Price: $29/month (that’s $348 annually) or $247/year"
      savingsText="(That’s a **$100 savings off the regular annual price.**)"
      savingsTextLineTwoRegularPrice="$29"
      savingsTextLineTwoOfferPrice="$19"
      sku="WLC"
      slug="wlc-special-brain"
      wlcTrees
      {...props}
    />
  );
}

export function WelcomeCta127(props) {
  return (
    <CtaSales
      className="offer-127"
      regularPrice="247"
      offerPrice="127"
      offerText="Special Offer: $19/month or $127/year!"
      regularOffer="Your Price: $29/month (that’s $348 annually) or $247/year"
      savingsText="(That’s a **$120 savings off the regular annual price.**)"
      savingsTextLineTwoRegularPrice="$29"
      savingsTextLineTwoOfferPrice="$19"
      sku="WLC"
      slug="wlc2005-special"
      wlcTrees
      {...props}
    />
  );
}

export function SalesCta127(props) {
  return (
    <CtaSales
      className="offer-127"
      regularPrice="247"
      offerPrice="127"
      offerText="Special Offer: $19/month or $127/year!"
      regularOffer="Your Price: $29/month (that’s $348 annually) or $247/year"
      savingsText="(That’s a **$120 savings off the regular annual price.**)"
      savingsTextLineTwoRegularPrice="$29"
      savingsTextLineTwoOfferPrice="$19"
      sku="WLC"
      slug="wlc2005"
      wlcTrees
      {...props}
    />
  );
}

export function SalesCta247(props) {
  return (
    <CtaSales
      className="offer-247"
      regularPrice="247"
      regularOffer="All for just $29/month (that’s $348 annually) or save big for $247/year"
      sku="WLC"
      slug="wlc"
      wlcTrees
      {...props}
    />
  );
}

export function SalesActionCollection(props) {
  return (
    <CtaSales
      className="action-collection"
      regularPrice="67"
      regularOffer="Your Price: $67"
      heading="The Food Revolution Action Collection"
      sku="WLC"
      slug="wlc-ac18"
      noImage
      {...props}
    />
  );
}

export function UpsellSales({
  offerBoxBackground = 'background-white',
  offerBoxBorder = 'border-dashed border-green border-2 offer-box-shadow',
  offerBoxPadding = 'px-3 py-4 px-sm-4 px-md-5 py-md-5',
  ...props
}) {
  const offerBox = classNames('offer-box text-center', offerBoxPadding, offerBoxBackground, offerBoxBorder);

  return (
    <div className={classNames(`cta-sales cta-sales-${props.className}`)}>
      <div className={offerBox}>
        {props.heading ? <div className="upsell-box-heading-wrap">{props.heading}</div> : null}

        {props.imgSrc ? (
          <Row className="d-flex justify-content-center my-4">
            <Col xs="10" md="8" xl="6">
              <img
                className={props.imgClass}
                src={props.imgSrc}
                width={props.imgWidth}
                height={props.imgHeight}
                alt={props.imgAlt}
              />
            </Col>
          </Row>
        ) : null}

        <div className="upsell-box-confirm">
          <div className="mb-3">
            <CheckoutButton
              className="offer-box-button offer-box-button-upsell"
              sku={props.sku}
              price={props.offerPrice}
              slug={props.slug}
            >
              <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Yes, Add to Order
            </CheckoutButton>
          </div>
          {props.text ? <div className="upsell-box-text">{props.text}</div> : null}
        </div>

        <div className="upsell-box-no">
          <div className="mb-3">
            <Button className="btn-upsell-gray">No, thanks.</Button>
          </div>
          <div className="upsell-box-no-text">
            {props.upsellNoText
              ? props.upsellNoText
              : 'Clicking the “No” button above will take you to the products already in your cart.'}
          </div>
        </div>

        {props.noDisclaimer ? <SalesDisclaimer noGuaranteeText /> : <SalesDisclaimer />}
      </div>

      {props.noTrees ? null : (
        <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
          <Col xs="11" lg="8" className="mb-4 mb-lg-0">
            <h6 className="section-only-heading text-center text-lg-left text-400">
              For every new order, we make a donation to Trees for the Future, enabling them to plant another organic
              fruit or nut tree in a low-income community.
            </h6>
          </Col>
          <Col xs="11" lg="3" className="text-center">
            <img className="logo-trees" src={logoTrees} />
          </Col>
        </Row>
      )}
    </div>
  );
}

export function ActionCollectionUpsell(props) {
  return (
    <UpsellSales
      className="offer-ac"
      heading={
        <>
          <h3 className="section-heading">One-Time Offer Only!</h3>
          <h4 className="mt-0 mb-4">
            Click the button below, and this premium <br className="d-none d-lg-block" />
            collection will be yours, instantly.
          </h4>
        </>
      }
      upsellNoText={
        <>
          <p>
            <i>Please click one of the buttons above.</i> Clicking the orange button will add the{' '}
            <br className="d-none d-xl-block" />
            Food Revolution Action Collection to your purchase for an additional, one-time cost of $47.
          </p>
          <p>
            <b>
              And, of course, your purchase comes with our 60-day, <br className="d-none d-sm-block d-lg-none" />
              total money-back guarantee.
            </b>
          </p>
        </>
      }
      sku="ac"
      slug="wlc-ac18"
      noDisclaimer
      {...props}
    />
  );
}
