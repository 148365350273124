import React from 'react';
import {Collapse} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown} from '@fortawesome/pro-light-svg-icons/faChevronCircleDown';
import {faChevronCircleUp} from '@fortawesome/pro-light-svg-icons/faChevronCircleUp';
import classNames from 'classnames';
//@ts-ignore
import {faq} from 'data/faq.json';
import './FaqList.scss';

function FaqItem({id, question, answer, borderColor, ...props}) {
  return (
    <div className={classNames(`faq-item faq-item-border-${borderColor}`)}>
      <h3 className="section-heading faq-item-question">Q: {question}</h3>
      <p className="faq-item-answer">{answer}</p>
    </div>
  );
}

FaqItem.defaultProps = {
  borderColor: 'black-15'
};

export default function FaqList({id, ...props}) {
  return faq.map((faq) => <FaqItem {...faq} key={faq.id} {...props} />);
}

export function ListFaq({faqlist = faq, borderColor = 'black-15', slice = 0, ...props}) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return faqlist.slice(slice).map((faq, index) => {
    return (
      <div className={classNames(`faq-item faq-item-border-${borderColor}`)} key={index}>
        <div className="faq-item-header d-flex align-items-center justify-content-between" onClick={onClick(index)}>
          <div className="faq-item-header-text">
            <ReactMarkdown className="faq-item-header-title" source={faq.question || 'faq question'} />
          </div>
          <div className="faq-item-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row">
            <FontAwesomeIcon
              className="faq-item-icon mb-1 mb-lg-0 mr-lg-1"
              //@ts-ignore
              icon={activeCards.includes(index) ? faChevronCircleUp : faChevronCircleDown}
            />
            <div className="faq-item-header-icon-text text-small text-uppercase">
              {activeCards.includes(index) ? (
                <>Close</>
              ) : (
                <>
                  <span className="d-none d-lg-inline">Learn</span> More
                </>
              )}
            </div>
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="faq-item-content">
            <ReactMarkdown
              source={faq.answer || 'faq answer'}
              renderers={{
                sub: 'sub',
                sup: 'sup'
              }}
            />
          </div>
        </Collapse>
      </div>
    );
  });
}

export function FaqListFull({faqlist = faq, borderColor = 'black-15', slice = 0, ...props}) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return faqlist.slice(slice).map((faq, index) => {
    return (
      <div className={classNames(``)} key={index}>
        <ReactMarkdown className="faq-item-header-title mb-3" source={faq.question || 'faq question'} />
        <ReactMarkdown
          className="mb-5"
          source={faq.answer || 'faq answer'}
          renderers={{
            sub: 'sub',
            sup: 'sup'
          }}
        />
      </div>
    );
  });
}
