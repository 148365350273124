import React, {useEffect} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import track from 'utils/track';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Section, {SectionImage} from 'components/Elements/Section';
import HeaderVideo, {ScrollingVideo} from 'components/Elements/HeaderVideo';
import CheckoutButton from 'components/CheckoutButton';
import {SalesDisclaimer} from 'components/SalesCta';
import {ListIcon} from 'components/Elements/IconList';
import {ImgBulletCard} from 'components/Elements/ImgBullet';
import {GuaranteeBox} from 'components/Guarantee';
import {TestimonialBubble} from 'components/Testimonial';
import {RecipeTileBox} from 'components/Elements/Tile';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Footer';

// @ts-ignore
import {wlcRecipe} from 'data/recipe.json';
// @ts-ignore
import {ahSpeakers} from 'data/speaker.json';
import {ExitModal} from 'components/Modal';

export default function WorkshopWelcomeIndex({
  noFixedVideo = false,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = 'default',
  ...props
}) {
  const pageTitle = 'Welcome to the Thriving on a Plant-Based Diet Workshop';
  const pageDescription =
    'Get actionable insights from world-leading experts on how to optimize your health with a plant-based diet. Join this free live workshop and start your journey towards better health and happiness!';
  const shareUrl = 'https://wlc.foodrevolution.org/workshop/';
  const ogImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-facebook-202405.png';
  const pinterestImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-pinterest-202405.png';
  const facebookDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const twitterDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const pinterestDescription =
    'Join us in this free live workshop to discover the benefits of a plant-based diet! Get practical tips from nutrition experts to improve your health and happiness. Register now for free and take your first step towards a healthier you!';

  const {
    phases: {
      workshop: workshopSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const phase = workshopSchedule.phase;

  const dollarPrice = 1;
  const fullPriceYearly = 497;
  const fullPriceMonthly = 49;
  const discountPriceYearly = 197;
  const discountPriceMonthly = 29;
  const discountDailyPennies = 0.54;
  const discountMonthlyDollars = 16.42;
  const discountPercent = '60%';

  const productSku = 'WLC';
  const productName = 'WHOLE Life Club';
  const productSlug = 'wlc-30daytrial-promo-special-197';
  const productValue = dollarPrice;

  useEffect(() => {
    track('Product Viewed', {
      product_id: productSku,
      sku: productSku,
      name: productName,
      value: Number(productValue).toFixed(2),
      variant: productSlug,
      currency: 'usd'
    });
  }, []);

  function SalesSection({id, headline, trees = false, ...props}) {
    return (
      <Section id={id} color="purple" {...props}>
        <Container>
          {headline && <div className="cta-headline text-center text-white">{headline}</div>}
          <Row className="d-flex justify-content-center">
            <Col lg="10" xl="8">
              <div className="box-shadow-black-50 rounded">
                <div className="background-black text-white rounded-top p-3 p-lg-4">
                  <h3 className="text-uppercase text-center m-0">Get Started Now!</h3>
                </div>
                <div className="background-white rounded-bottom px-4 px-lg-5 py-4">
                  <h3 className="text-center text-green mt-0 mb-4">Join For Just $1 Today</h3>
                  <ListIcon>
                    <>6 delicious, whole foods, plant-powered recipes per week</>
                    <>Weekly videos from Ocean Robbins and friends delivered to your inbox</>
                    <>Monthly Action Hour events with health revolutionaries</>
                    <>Action Checklists to keep you on track</>
                    <>Recipe bank with hundreds of fabulous recipes</>
                    <>A private, moderated community</>
                    <>Access to health coaches & dieticians</>
                    <>Access to the entire library of 150+ videos</>
                    <>Annual rates that will never go up as long as you remain a member</>
                    <>A state-of-the-art membership site that’s easy to navigate</>
                    <>
                      <b>
                        SPECIAL ONE-TIME OFFER: Join from this page only and get the Brain Power Collection for FREE!
                      </b>
                    </>
                  </ListIcon>
                  <div className="text-center">
                    <p className="balance-text">
                      <b>
                        For just a few weeks, the doors to WHOLE Life Club are open for direct entry. Get in now with a
                        stellar group of cohorts (and save big!) today.
                      </b>
                    </p>
                    <h3 className="section-heading text-green mb-1">Only ${dollarPrice} for a 30-day trial</h3>
                    <h3 className="mt-0 mb-2 text-green">
                      Then <del>${fullPriceYearly}</del> ${discountPriceYearly}/year
                    </h3>
                    <h5 className="text-400 mt-0 mb-3">(That’s just ${discountDailyPennies} a day)</h5>
                    <h5 className="mt-0 mb-4">
                      Or <del>${fullPriceMonthly}</del> ${discountPriceMonthly}/month
                    </h5>
                    <CheckoutButton
                      className="offer-box-button"
                      sku={productSku}
                      price={Number(productValue).toFixed(2)}
                      slug={productSlug}
                      {...props}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        // @ts-ignore
                        icon={faCartPlus}
                      />{' '}
                      Buy Now
                    </CheckoutButton>
                    <SalesDisclaimer />
                    <p className="balance-text text-small mt-4">
                      Join WHOLE Life Club for just ${dollarPrice} for your first 30 days. After that, you’ll be
                      enrolled for a special membership price of just ${discountPriceYearly} a year unless you cancel
                      before your 30-day trial is up.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {trees && (
                <div className="mt-4">
                  <Row className="trees-for-future text-white d-flex align-items-center justify-content-center">
                    <Col xs="11" lg="8" className="mb-4 mb-lg-0">
                      <h6 className="section-only-heading text-center text-lg-left text-400">
                        For every new order, we make a donation to Trees for the Future, enabling them to plant another
                        organic fruit or nut tree in a low-income community.
                      </h6>
                    </Col>
                    <Col xs="11" lg="3" className="text-center">
                      <img
                        className={classNames(`logo-trees`)}
                        alt="Trees for the Future logo"
                        src="https://cdn.foodrevolution.org/global/trees-logo-white.png"
                        width={1080}
                        height={442}
                        style={{maxHeight: '100px', width: 'auto'}}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }

  useBalanceText();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />
      <div className="page-content">
        <Section
          id="wlclw-welcome-header"
          className="section-angle-first background-cover background-fixed background-image-1524823226 section-angle-both-offset"
          color="black"
          innerClass="text-white text-center"
          angle="none"
        >
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" className="video-col">
                <div className="background-black-90 rounded-top p-4 p-lg-5">
                  <h1 className="section-heading text-h3 mb-3">
                    Thank You for Joining the Thriving on a Plant-Based Diet: 10 Tips for Maximizing <i>Your</i> Health
                    and Happiness WORKSHOP!
                  </h1>
                  <p>You’ll get an email with your next steps in a few minutes.</p>
                  <p className="balance-text mb-0">
                    <b>But first,</b> watch this video to find out how to get the most out of this three-hour live
                    workshop AND receive a unique, one-time opportunity you’ll only see on this page.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <ScrollingVideo
                  bgColor="black"
                  noFixed={noFixedVideo}
                  video={{
                    url: 'https://www.youtube.com/watch?v=aIQYigGIBlE',
                    label: 'WLC-LW - Welcome',
                    thumbnail: undefined,
                    noButton: false
                  }}
                  text={
                    <>
                      <h5 className="section-heading">
                        Get your 30-day WHOLE Life Club <br className="d-none d-xl-block" />
                        trial membership TODAY!
                      </h5>
                      <CheckoutButton
                        sku={productSku}
                        price={Number(productValue).toFixed(2)}
                        slug={productSlug}
                        {...props}
                      >
                        Buy Now
                      </CheckoutButton>
                    </>
                  }
                />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="wlclw-welcome-cta-1" color="purple" angle="both">
          <Container>
            <Row>
              <Col>
                <div className="background-white box-shadow-black-50 rounded p-4 p-lg-5 text-center">
                  <h4 className="section-heading mb-3">EXCLUSIVE OFFER for WORKSHOP PARTICIPANTS:</h4>
                  <p>In this workshop, you’ll learn potent ways to optimize your health.</p>
                  <p>
                    Take the next step, to lock in ongoing support to help you implement, sustain, and optimize all that
                    you learn — for life!
                  </p>
                  <p>Join WHOLE Life Club now.</p>
                  <h2 className="text-green mt-0 mb-4">One month. One dollar. Full access.</h2>
                  <p className="mb-4">
                    ${dollarPrice} for 30 days, then: <del>${fullPriceYearly}</del> ${discountPriceYearly}/year
                  </p>
                  <CheckoutButton
                    className="offer-box-button"
                    sku={productSku}
                    price={Number(productValue).toFixed(2)}
                    slug={productSlug}
                    {...props}
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      // @ts-ignore
                      icon={faCartPlus}
                    />{' '}
                    Buy Now
                  </CheckoutButton>
                  <SalesDisclaimer />
                  <p className="balance-text text-small mt-4">
                    And today only, get the Brain Power Collection as a FREE bonus! Join WHOLE Life Club for just $
                    {dollarPrice} for your first 30 days. After that, you’ll be enrolled for a special membership price
                    of just ${discountPriceYearly} a year unless you cancel before your 30-day trial is up.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          id="wlclw-welcome-message"
          color="light-gray"
          className="background-image-175234125 background-image-center background-cover background-fixed section-angle-both-offset"
          angle="none"
        >
          <Container>
            <Row>
              <Col>
                <div className="background-white rounded p-4 p-lg-5">
                  <h3 className="section-heading text-center">
                    A Message From Food Revolution Network Co-founder Ocean Robbins
                  </h3>
                  <p>
                    I’m thrilled you’ve chosen to join the Thriving on a Plant-Based Diet: 10 Tips for Maximizing{' '}
                    <i>Your</i>
                    Health and Happiness live workshop. We’re going to have so much fun together in these three
                    power-packed hours!
                  </p>
                  <p>
                    Millions of people, doctors, scientists, and researchers have concluded that a diet based on whole
                    plant foods is the #1 thing we can do for:
                  </p>
                  <ListIcon>
                    <>health</>
                    <>longevity</>
                    <>disease prevention and reversal</>
                    <>brain power</>
                    <>environmental benefit</>
                    <>animal welfare</>
                  </ListIcon>
                  <p>
                    The facts are in, and we know that food can hurt us. But food can also heal us! And every bite
                    matters.
                  </p>
                  <p>At Food Revolution Network, we hear stories of healing, hope, and transformation almost daily.</p>
                  <p>
                    We’ve also seen that for many people, the biggest challenge isn’t how much they know —{' '}
                    <i>it’s putting their goals into action. Consistently.</i>
                  </p>
                  <p>
                    Over time, even with the best intentions, many people slide back into old habits and fail to make
                    the progress they want.
                  </p>
                  <p>
                    And I don’t want that to happen to you a few months after the inspiring and exciting Thriving on a
                    Plant-Based Diet: 10 Tips for Maximizing <i>Your</i> Health and Happiness Live Workshop — or ever!
                  </p>
                  <p>That’s why I invite you to join FRN’s signature membership community, WHOLE Life Club.</p>
                  <p>
                    This private online membership will help you move from knowing what to do to doing what you know.
                    Easily, effortlessly, and deliciously.
                  </p>
                  <p>
                    You’ll see how good life gets when you have all the support and science-based nutrition insights you
                    need.
                  </p>
                  <p>
                    I can’t wait to hear what optimizing your food and lifestyle habits does for <i>you!</i>
                  </p>
                  <Row className="d-flex align-items-center justify-content-center mt-3">
                    <Col xs="12" lg="8" xl="9" className="mb-4 mb-lg-0 text-center text-lg-left">
                      <img
                        className="mb-2"
                        src="https://cdn.foodrevolution.org/global/team/ocean-signature-firstname.png"
                        alt="ocean signature"
                        width={1000}
                        height={249}
                        style={{maxHeight: '60px', width: 'auto'}}
                      />
                      <h3 className="mt-0 mb-2 text-uppercase">Ocean Robbins</h3>
                      <h5 className="mt-0 mb-2">Co-Founder, Food Revolution Network</h5>
                      <h5 className="mt-0 mb-2">Host, WHOLE Life Club</h5>
                    </Col>
                    <Col xs="10" sm="8" lg="4" xl="3">
                      <img
                        src="https://cdn.foodrevolution.org/global/team/ocean-robbins-headshot.jpg"
                        alt="headshot of ocean"
                        width={1000}
                        height={1000}
                        className="rounded-circle"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="wlclw-welcme-testimonials-1" color="white" angle="both">
          <Container>
            <Row>
              <Col>
                <p className="balance-text text-center text-small mb-5">
                  <i>
                    Because you just signed up for the Thriving on a Plant-Based Diet: 10 Tips for Maximizing Your
                    Health and Happiness Workshop, you can join FRN’s membership community, WHOLE Life Club. Many of our
                    WLC participants have experienced profound results. Each of them is unique. And so are you! Your
                    experiences will vary, of course.
                  </i>
                </p>
                {['wlcSylvieW', 'wlcRobin', 'wlcJuanitaWelcome', 'wlcCindy'].map((id) => (
                  <TestimonialBubble id={id} key={id} />
                ))}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="wlclw-welcome-get" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center mb-5">
                  Here’s what you’ll get when you join WHOLE Life Club today:
                </h3>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/box-recipe.jpg"
                  imageAlt="whole foods bowl on table"
                >
                  A library of over 1,250 delicious, healthy, and easily searchable plant-based recipes, plus six new
                  fabulous recipes delivered every single week.
                </ImgBulletCard>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/box-video.jpg"
                  imageAlt="screenshot of action video"
                >
                  A new short action video every week on the most critical health topics, plus hundreds of action videos
                  from our entire content library.
                </ImgBulletCard>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/monthly-action-hours-headshots-square.png"
                  imageAlt="headshots of four experts"
                  className="objectFit ahSpeakers"
                >
                  Monthly Action Hours with world-class guest experts sharing the hottest health news and essentials.
                  (Plus our library of 65+!)
                </ImgBulletCard>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/box-community.jpg"
                  imageAlt="diverse group of people holding hands in a circle"
                >
                  A lively and moderated online community — so you can celebrate wins, share breakthroughs, and learn
                  from others.
                </ImgBulletCard>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/health-coaches.jpg"
                  imageAlt="screenshot of cooking demo"
                >
                  Access to expert nutritionists and health coaches — ask questions and get answers to your pressing
                  health and nutrition questions anytime!
                </ImgBulletCard>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/eattherainbow/images/1026486992.jpg"
                  imageAlt="blue pen marking checkbox with checkmark"
                >
                  Action checklists and resources so you can steadily progress and stay motivated as you create new
                  healthy habits that last a lifetime.
                </ImgBulletCard>
                <ImgBulletCard
                  img="https://cdn.foodrevolution.org/wlc/brain-power-collection.png"
                  imageAlt="brain power collection product image"
                  bgColor="purple"
                  textColor="white"
                  className="objectFit bonusCard"
                >
                  <p>
                    <b>Free Bonus today only!</b>
                  </p>
                  <p>
                    <b>ON THIS PAGE ONLY!</b> The Brain Power Collection — interviews with three leading brain health
                    experts sharing what to eat and do for maximum mental health, focus, and memory power — and what to
                    do <i>now</i> to prevent Alzheimer’s.
                  </p>
                </ImgBulletCard>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="wlclw-welcome-topics-1"
          color="white"
          imgUrl="https://cdn.foodrevolution.org/eattherainbow/images/1435001922-square.jpg"
          imgDescription="man cooking healthy dinner at home"
          imgPosition="right"
          angleBottomDisplay="lg-none"
        >
          <h3 className="section-heading">Here are some recent WHOLE Life Club topics</h3>
          <p>
            <i>You’ll get them all instantly — the moment you join!</i>
          </p>
          <ListIcon>
            <>
              <b>Foods to Fight Cancer and Thrive in Every Cell</b> — see how it’s possible to prevent and even take
              steps that help beat cancer.
            </>
            <>
              <b>Hidden Dangers</b> — food safety, pesticides, glyphosate, GMOs, toxins: what you need to know to
              protect yourself and your family.
            </>
            <>
              <b>Probiotics and Gut Health</b> — this is one of the newest and most promising health discoveries of our
              time, and we share all the breaking updates as they roll in.
            </>
            <>
              <b>Weight Loss and Food Freedom</b> — discover how to rewire your brain to eat what’s good for you.
            </>
            <>
              <b>Stopping Heart Disease</b> — learn how to avoid the #1 cause of death by switching to heart-healthy
              superfoods and adopting positive lifestyle routines you’ll love.
            </>
            <>
              <b>Preventing Dementia</b> — employ new breakthroughs in nutrition and lifestyle to keep your brain sharp
              and avoid devasting brain diseases.
            </>
          </ListIcon>
        </SectionImage>

        <SectionImage
          id="wlclw-welcome-topics-2"
          color="white"
          imgUrl="https://cdn.foodrevolution.org/eattherainbow/images/1179985039-square.jpg"
          imgDescription="woman carrying bowl of fresh vegetables in her garden"
          imgPosition="left"
          innerClass="section-padding-lg"
        >
          <ListIcon>
            <>
              <b>Detoxing and Cleansing</b> — use food to heal your body naturally.
            </>
            <>
              <b>Reversing Chronic Inflammation</b> — implement scientifically proven ways to reduce chronic
              inflammation and help your body to heal.
            </>
            <>
              <b>Outdoor and Indoor Gardening</b> — grow your own fresh fruits, veggies, and herbs for the most
              delicious flavor and maximum nutrient levels (even in small spaces).
            </>
            <>
              <b>Creating Joy and Dealing with Stress</b> — get truly effective mind-body techniques so you can become
              the happiest version of yourself.
            </>
            <>
              <b>Mythbusting</b> — no longer wonder whether you’re doing the right thing when it comes to soy, carbs,
              protein, fats, and other hotly debated topics.
            </>
            <>
              <b>Mental Health</b> — find out what landmark studies show us about how neuroscience and nutrition can
              help create balance, resilience, and less suffering.
            </>
          </ListIcon>
          <p>
            <b>And there will be new ones for you every week!</b>
          </p>
        </SectionImage>

        <SectionImage
          id="wlclw-welcome-manual"
          color="light-gray"
          imgUrl="https://cdn.foodrevolution.org/eattherainbow/images/1350164916-square.jpg"
          imgDescription="senior mother and adult daughter smiling at a phone while at a table"
          imgPosition="right"
        >
          <h3 className="section-heading">Like an instruction manual for how to be healthy</h3>
          <p>
            Overcoming obstacles to being healthy is equal parts information and inspiration. That’s why each week,
            you’ll get a short video from Ocean Robbins and other FRN leaders where you’ll learn what to eat and do to
            take excellent care of yourself and your loved ones. With each video, you’ll get an easy action step to make
            steady progress toward your goal to get healthier. You get access to the entire library the instant you
            join, so you can dive right into the topics that have stumped you for years.
          </p>
        </SectionImage>

        <Section id="wlclw-welcome-speakers" color="white" className="text-center">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Learn directly from top-tier experts during monthly Action Hours</h3>
                <h5 className="mt-0">
                  Get instant access to all of these Action Hours PLUS dozens of others the instant you enroll
                </h5>
                <p className="balance-text">
                  Keeping up with all the recent research (and not getting overwhelmed) is a massive part of getting —
                  and staying — healthy. There’s no need to do the research on your own. Each month, we bring in
                  world-renowned health experts, from doctors who are multiple <i>New York Times</i> bestselling authors
                  to award-winning podcast hosts and more, to share their decades of hard-won wisdom with you.
                </p>
                <p className="balance-text">
                  As soon as you join, you’ll be able to dive into the entire archive of 65+ Action Hours so you can go
                  from just getting started to soaring past your personal best in the easiest way possible. No trial and
                  error. No more confusion about what’s healthy and what’s not. Just you, making huge strides one hour
                  at a time.
                </p>
              </Col>
            </Row>
            <Row className="align-items-stretch mt-4">
              {ahSpeakers.map((speaker, i) => (
                <Col sm="6" lg="3" id={'speaker-' + speaker.id} key={speaker.id} className="mb-3">
                  <img
                    className="rounded mb-3"
                    src={speaker.image.url}
                    alt={speaker.image.alt}
                    width={speaker.image.width}
                    height={speaker.image.height}
                  />
                  <h6 className="section-heading text-base mb-2">{speaker.name}</h6>
                  <h6 className="text-400 text-small mt-0 mb-0">{speaker.presentation}</h6>
                </Col>
              ))}
            </Row>
            <Row>
              <Col>
                <h4>And dozens more!</h4>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="wlclw-welcome-questions"
          color="light-gray"
          imgUrl="https://cdn.foodrevolution.org/eattherainbow/images/1463499949-square.jpg"
          imgDescription="senior man with hand on chin with questioning expression"
          imgPosition="left"
        >
          <h3 className="section-heading">
            Got burning questions about <i>YOUR</i> unique health challenge or nutrition?
          </h3>
          <h4 className="mt-0">
            <i>WHOLE Life Club has answers.</i>
          </h4>
          <p className="balance-text">
            One of the best parts of WHOLE Life Club is how interactive it is! As soon as you join, you’ll have access
            to WHOLE Life Club’s team of nutritionists and health coaches ready to answer your questions. Looking to
            find out if you should take supplements and which ones? Want to know the best foods for mental health? Need
            some sleep tips to get some Zzzz? It’s as easy as popping into the WHOLE Life Club forum to get the answers
            you’re looking for.
          </p>
        </SectionImage>

        <Section id="wlclw-welcome-recipes" color="white" className="text-center">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Never wonder what’s for dinner again!</h3>
                <p className="balance-text">
                  Have fun exploring over a thousand delicious and nutrient-packed plant-based recipes you can trust. If
                  you’ve ever searched for recipes online, you know it can be hard to find whole foods recipes that are
                  truly good for you. From healthy dinners that please the whole family to desserts that do the trick
                  but don’t leave you with a sugar hangover, you’ll find your new favorite 5-star recipes that come
                  together in minutes.
                </p>
                <h5 className="mt-4">Choose from 1,250+ mind-blowingly delicious recipes!</h5>
              </Col>
            </Row>
            <Row>
              {wlcRecipe.map((recipe) => (
                <Col lg="6" className="mb-4" key={recipe.id}>
                  <RecipeTileBox id={recipe.id} key={recipe.id} recipeTile recipeList={wlcRecipe} />
                </Col>
              ))}
            </Row>
          </Container>
        </Section>

        <Section id="wlclw-welcome-brain" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center mb-3">
                  Plus, If You Join Right Now, from this Page Only — You’ll Get Our Brain Power Collection Bonus!
                </h3>
                <div className="text-center mb-4">
                  <img
                    src="https://cdn.foodrevolution.org/wlc/brain-power-collection.png"
                    alt="brain power collection product image"
                    width={960}
                    height={515}
                    style={{maxHeight: '400px'}}
                  />
                </div>
                <p>
                  Whether you know a thing or two about brain health or you’ve never given it much thought,{' '}
                  <br className="d-none d-xl-block" />
                  here’s the truth:
                </p>
                <p>
                  <b>Everything you do depends on how well your brain works.</b> And eating the right food and taking{' '}
                  <br className="d-none d-xl-block" />
                  the right steps to protect your brain NOW is essential to safeguard your most valuable asset.
                </p>
                <p>
                  But like millions of people, you might not know how to protect your brain so you can experience{' '}
                  <br className="d-none d-xl-block" />
                  mental health, clarity, and focus.
                </p>
                <p>
                  If that’s the case, the Brain Power Collection will show you{' '}
                  <b>how to use food to prevent Alzheimer’s and dementia</b> and <i>achieve peak brain performance</i>{' '}
                  for a sharper memory, heightened productivity, and increased focus.
                </p>
                <p>With this added bonus, you’ll get:</p>
                <ListIcon>
                  <>
                    <b>Three wildly popular interviews from past Food Revolution Summits</b> — featuring John Robbins
                    interviewing Dr. Dale Bredesen, Dr. Daniel Amen, and Drs. Dean and Ayesha Sherzai (includes audio
                    recordings and written transcripts).
                  </>
                  <>
                    <b>Simple Action Checklists</b> to help you <i>put the top lessons into action</i> in your life
                    right away. <br className="d-none d-xl-block" />
                    <b>These are new for everyone</b> — even past Food Revolution Summit Empowerment Package owners have
                    never seen this before!
                  </>
                </ListIcon>
                <p className="text-center balance-text mt-4">
                  <b>
                    This special gift is yours only when you join WHOLE Life Club for $1 today. (Available when you join
                    from this page only.)
                  </b>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SalesSection
          id="wlclw-welcome-cta-2"
          headline={
            <h3 className="section-heading text-center text-white">
              Join WHOLE Life Club Now While Enrollment is Open to All for a Limited Time.
            </h3>
          }
        />

        <Section id="wlclw-welcome-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox title={<>Our 60-Day, Money-Back, Risk-Free Guarantee</>}>
                  <p>Take up to 60 days to see what WHOLE Life Club is all about.</p>
                  <p>We guarantee that you and your family will:</p>
                  <ListIcon>
                    <>
                      Have the tools and information to improve your health right away, helping you eat better and enjoy
                      more vitality.
                    </>
                    <>Feel empowered knowing the truth about the foods you eat.</>
                    <>Make meaningful progress towards YOUR healthy eating goals.</>
                  </ListIcon>
                  <p>
                    If for any reason you request a refund, everything you've already downloaded is still yours to keep.
                    That’s why we call our guarantee “risk-free!”
                  </p>
                  <p>
                    If for some reason you aren’t 100% thrilled with your purchase, you have 60 days to request a
                    prompt, total refund. Email us at{' '}
                    <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a> or phone us at (831)
                    824-4779. You can also hit reply to any email we send you with your request.
                  </p>
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="wlclw-welcome-testimonials-2" color="white">
          <Container>
            <Row>
              <Col>
                <p className="balance-text text-center text-small mb-5">
                  <i>
                    Because you just signed up for the Thriving on a Plant-Based Diet: 10 Tips for Maximizing Your
                    Health and Happiness Workshop, you have an exclusive opportunity to join FRN’s membership community,
                    WHOLE Life Club. Many of our WLC participants have experienced profound results. Each of them is
                    unique. And so are you! Your experiences will vary, of course.
                  </i>
                </p>
                {['wlcLydiaK', 'wlcLisa', 'wlcAmeliaC'].map((id) => (
                  <TestimonialBubble id={id} key={id} />
                ))}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="wlclw-welcome-join" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <img
                  className="mb-4"
                  src="https://cdn.foodrevolution.org/wlc/wlc-product-image-v20220329.png"
                  alt="WHOLE Life Club product image"
                  width={1200}
                  height={500}
                />
                <h3 className="section-heading text-center mb-3">Why Should You Join Today?</h3>
                <h6 className="text-400 text-center mt-0 mb-4">
                  When you join WHOLE Life Club for just <b>${dollarPrice} today,</b> you’ll get
                </h6>
                <ListIcon>
                  <>
                    <b>Your first 30 days for ${dollarPrice}!</b> PLUS, get a special {discountPercent} discounted low
                    price — you’ll pay only ${discountPriceYearly} per year after your first 30 days —{' '}
                    <i>that comes to just ${discountMonthlyDollars} a month.</i>
                  </>
                  <>
                    <b>Annual rates never go up</b> — Your hugely discounted membership rate will be locked in as long
                    as you remain a member.
                  </>
                  <>
                    <b>You’ll support our mission</b> of healthy, ethical, sustainable food for all. In fact, for every
                    annual membership, we’ll donate to Trees for the Future, enabling them to plant another organic
                    fruit or nut tree in a low-income community.
                  </>
                </ListIcon>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="wlclw-welcome-lennon" color="white" innerClass="text-center">
          <Container>
            <Row className="align-items-center justify-content-center mb-5">
              <Col xs="8" sm="6" lg="3" className="mb-3 mb-lg-0">
                <img
                  src="https://cdn.foodrevolution.org/eattherainbow/images/john-lennon-headshot.jpg"
                  alt="headshot of John Lennon"
                  width={800}
                  height={800}
                  className="rounded-circle"
                />
              </Col>
              <Col xs="12" lg="auto" className="text-lg-left">
                <p className="section-heading text-800 text-h3 mb-3">
                  <i>
                    “Life is what happens while <br className="d-none d-lg-block" />
                    you’re busy making other plans.”
                  </i>
                </p>
                <h4 className="m-0">— John Lennon</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="balance-text">
                  “Life happens.” And, all too often, the first thing to get derailed is your healthy eating.
                </p>
                <p className="balance-text">
                  You run out of time, or you eat for comfort and snack the day away, or you just don’t feel inspired to
                  make the next meal meaningful. Sound familiar?
                </p>
                <p className="balance-text">
                  That’s why I want to personally invite you to take the next step and join WHOLE Life Club now.
                </p>
                <h5 className="mt-0">
                  Right now, from this page only, you have a limited-time opportunity to join for this special price and
                  to take advantage of the limited time Brain Power Collection bonus.
                </h5>
                <p className="balance-text">
                  When you join WHOLE Life Club, you’ll get <b>instant support</b> to help you quickly and successfully{' '}
                  <b>implement, optimize,</b> and <b>LOVE</b> your <b>healthy living lifestyle.</b>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SalesSection
          id="wlclw-welcome-cta-3"
          angle="none"
          order="last"
          trees
          headline={<h3 className="section-heading mb-5">Join WHOLE Life Club & Support Our Mission</h3>}
        />
      </div>

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons
          shareUrl={shareUrl}
          shareImage={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          floating
        />
      </div>
      <ExitModal
        exitDelay={30000}
        content={
          <>
            <p>
              Do you want to prioritize your health and well-being? Experience the transformative power of WHOLE Life
              Club!
            </p>
            <p>For a limited time, you can join for just $1.</p>
          </>
        }
        image={{
          url: 'https://cdn.foodrevolution.org/wlc/workshop/1132758418-exit.jpg',
          alt: 'close-up portrait of puzzled wavy-haired woman showing gesture isolated on vivid yellow background',
          width: 1000,
          height: 800
        }}
        positiveButtonText={
          <>
            Yes, I want <br className="d-none d-lg-block" />a $1 trial
          </>
        }
        negativeButtonText="No Thanks"
        scrollHref="wlclw-welcome-cta-3"
      />
    </div>
  );
}
