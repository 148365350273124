import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';

import PageMeta from 'components/PageMeta';
import Section, {SectionImage} from 'components/Elements/Section';
import Header from 'components/Header';
import HeaderVideo from 'components/Elements/HeaderVideo';
import OptinModal, {OptinModalTrigger} from 'components/OptinModal';
import {LandingSpeakerList} from 'components/SpeakerList';
import LandingTimerSection from 'components/Landing/LandingTimerSection';
import ModalExitLanding from 'components/Landing/ModalExitLanding';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Footer';

export default function ({
  noExitIntent = false,
  noFixedVideo = false,
  pageTitle = 'Join the FREE, Online WHOLE Life Action Hour Series',
  pageDescription = 'Get trusted solutions and practical, science-based action steps you can take NOW to achieve the lasting health and vitality you deserve.',
  shareUrl = 'https://wlc.foodrevolution.org/',
  shareImage = 'https://cdn.foodrevolution.org/wlc/opengraph/WLC-opengraph-landing-1200x1200-202205.png',
  variant = null,
  ...props
}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: ''
          }
        ]
      }
    }
  } = usePhaseState();

  const isSeries = defaultSchedule.actionHours.length > 1;
  const seriesName = `Action Hour${isSeries ? ' Series' : ''}`;

  return (
    <div className="page">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImage} />
      <div className="page-content">
        <Section id="landing-header" color="gradient-indigo-purple-right" innerClass="text-white pt-0">
          <Header className="background-black header-wrap" logoWhite style="dark" logo={isSeries ? 'plural' : ''} />
          <div className="optin-wrap pt-4 text-center text-shadow">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col xs="12">
                  <p className="mb-4">
                    <i>Free</i> {isSeries ? 'Action Hour Interview Series' : 'Action Hour Interview'} Hosted{' '}
                    <br className="d-none d-sm-block d-xl-none" />
                    By Food Revolution Network CEO Ocean Robbins
                  </p>
                </Col>
                <Col xs="12" lg="10" className="video-col px-0 px-lg-3 mb-4">
                  <HeaderVideo
                    url={
                      isSeries
                        ? 'https://www.youtube.com/watch?v=sRJj4T_7I-MM'
                        : 'https://www.youtube.com/watch?v=rbVEhqCwWEs'
                    }
                    optin
                    noFixed={noFixedVideo}
                    thumbnail={
                      isSeries
                        ? 'https://cdn.foodrevolution.org/wlc/video-thumbs/wlc-2022-landing-thumbnail-v1.jpg'
                        : null
                    }
                  />
                </Col>
                <Col xs="12" lg="10">
                  <h3 className="section-heading text-400">
                    Why Most People Will Suffer From Pain, <br className="d-none d-xl-block" />
                    Brain Fog, and Chronic Disease as They <br className="d-none d-xl-block" />
                    Age — and <br className="d-none d-sm-block d-md-none" />
                    How to Beat Those Odds
                  </h3>
                  <h6 className="mt-0 mb-4">
                    Get Behind the Scenes Interviews With <br className="d-none d-sm-block" />
                    {isSeries ? '3 of ' : ''}Today’s Top Health Experts
                  </h6>
                  <p className="mb-4">Join the WHOLE Life {seriesName}</p>
                  <OptinModalTrigger />
                </Col>
              </Row>
            </Container>
          </div>
        </Section>

        <Section id="landing-timer" color="black">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="10" xl="9">
                <LandingTimerSection />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="landing-presents" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center">
                  Food Revolution Network Presents <br className="d-none d-xl-block" />
                  the WHOLE Life {seriesName}
                </h2>
                <h4 className="mt-0 text-uppercase text-center">
                  {isSeries ? (
                    <>
                      Featuring 3 Health Revolutionaries <br className="d-none d-lg-block d-xl-none" />
                      Sharing Their Top Strategies <br className="d-none d-md-block d-lg-none" />
                      for a Lifetime <br className="d-none d-lg-block d-xl-none" />
                      of Feeling <br className="d-none d-md-block d-lg-none" />
                      {variant === 'ad' ? 'The' : 'Your'} Absolute Best
                    </>
                  ) : (
                    <>
                      Featuring This Health Revolutionary <br className="d-none d-lg-block d-xl-none" />
                      Sharing Their Top Strategies for a Lifetime <br className="d-none d-lg-block d-xl-none" />
                      of Feeling <br className="d-none d-md-block d-lg-none" />
                      {variant === 'ad' ? 'The' : 'Your'} Absolute Best
                    </>
                  )}
                </h4>
              </Col>
            </Row>
            <LandingSpeakerList teaser />
            <Row className="justify-content-center align-items-center">
              <Col xs="10" md="6" lg="5" className="text-center mb-4 mb-lg-0">
                <img className="img-speaker-ocean rounded-circle" src={require('static/speaker/ocean-robbins.jpg')} />
              </Col>
              <Col lg="7">
                <h3 className="section-heading text-center text-lg-left mb-0">Ocean Robbins</h3>
                <h4 className="mt-1 text-400 text-center text-lg-left">Your Host</h4>
                <p>
                  <b>As co-founder and host of Food Revolution Network,</b> Ocean has hosted more than two million
                  people in the annual global Food Revolution Summit, and he’s organized and facilitated hundreds of
                  live seminars, gatherings, and events for world leaders from 65 nations. He is the author of the
                  best-selling book <i>31-Day Food Revolution: Heal Your Body, Feel Great, & Transform Your World.</i>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="landing-discover-one"
          imgPosition="right"
          backgroundPosition="right-center"
          color="white"
          imgUrl={require('static/backgrounds/woman-green-smoothie-square.jpg')}
          imgUrlMobile={require('static/backgrounds/woman-green-smoothie.jpg')}
        >
          <h2 className="section-heading">
            {variant === 'ad' ? (
              <>
                What’s Inside Each <br className="d-none d-sm-block d-lg-none" />
                Inspiring Action Hour:
              </>
            ) : (
              <>
                In Each Inspiring Action Hour, <br className="d-none d-lg-block d-xl-none" />
                You’ll Discover:
              </>
            )}
          </h2>
          <ul className="list-check-green">
            <li>
              <b>Why FOOD is the strongest — and most delicious — ally in the fight against chronic illness.</b> Get
              ready to learn the top superfoods that are right in {variant === 'ad' ? 'the' : 'your'} produce aisle.
            </li>
            <li>
              <b>
                The scientifically proven steps to regain the ENERGY, JOY, and PEACE that’s possible…at any age and
                stage of life!
              </b>{' '}
              With the right tips, feeling good again comes naturally.
            </li>
            <li>
              <b>
                How to optimize {variant === 'ad' ? 'the' : 'your'} immune system to support a healthy response to
                whatever challenges life brings.
              </b>{' '}
              {variant === 'ad' ? (
                <>This goes way more in-depth than vitamin C!</>
              ) : (
                <>From colds and viruses to cancer prevention: discover what you need beyond vitamin C!</>
              )}
            </li>
            {isSeries ? (
              <li>
                <b>New healthy, mouth-watering, plant-powered recipes that are easy to make.</b> Be sure to watch each
                Action Hour for a new nutrient-rich recipe to devour right away.
              </li>
            ) : null}
          </ul>
        </SectionImage>

        <SectionImage
          id="landing-discover-two"
          color="light-gray"
          imgUrl={require('static/backgrounds/man-woman-tablet-square.jpg')}
          imgUrlMobile={require('static/backgrounds/man-woman-tablet.jpg')}
        >
          <h4 className="section-heading mb-4">
            PLUS, during the <br className="d-none d-lg-block d-xl-none" />
            Action Hours, <br className="d-none d-md-block d-lg-none" />
            {variant === 'ad' ? 'we’ll share' : 'learn'} how <br className="d-none d-lg-block d-xl-none" />
            to join WHOLE Life Club.
          </h4>
          <p>
            Live the healthiest life possible. <br className="d-none d-lg-block d-xl-none" />
            Enjoy lasting RESULTS.
          </p>
          <p>
            {variant === 'ad' ? (
              <>
                The doors only open to this inspiring online membership twice per year
                {isSeries ? ' — this is the last chance to join with a group of cohorts in 2022.' : '.'}
              </>
            ) : (
              <>
                The doors only open for direct access to this game-changing online membership twice per year
                {isSeries ? '— this is the last chance to join with a group of cohorts in 2022.' : '.'}
              </>
            )}
          </p>
          <p>
            {isSeries ? (
              <>
                Find out why members call WHOLE Life Club “a lifeline” and get an invite to lock in huge savings during
                the Action Hour series.
              </>
            ) : (
              <>Find out why members call WHOLE Life Club “a lifeline.”</>
            )}
          </p>
        </SectionImage>

        <SectionImage
          id="landing-cta-1"
          color="purple"
          imgPosition="right"
          innerClass="text-white text-center"
          imgUrl={require('static/backgrounds/woman-pineapple-square.jpg')}
        >
          <h3 className="section-heading">
            Join the WHOLE Life <br className="d-none d-sm-block" />
            {seriesName} Today
          </h3>
          <p>
            {variant === 'ad' ? (
              <>
                Because we ALL deserve a smart immune system, <br className="d-none d-sm-block d-lg-none d-xl-block" />
                healthy body, a clear mind, and lasting vitality.
              </>
            ) : (
              <>
                Because you deserve a smart immune system, <br className="d-none d-sm-block d-lg-none" />
                healthy body, a clear mind, and lasting vitality.
              </>
            )}
          </p>
          <OptinModalTrigger />
          <div className="section-cta-countdown mt-4">
            <LandingTimerSection className="section-countdown-cta" />
          </div>
        </SectionImage>

        <Section id="landing-thriving" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">
                  {variant === 'ad' ? (
                    <>
                      Each WHOLE Life Action Hour Features <br className="d-none d-xl-block" />
                      Trustworthy Information — <i>Without</i> Hype
                    </>
                  ) : (
                    <>
                      Each WHOLE Life Action Hour Features <br className="d-none d-xl-block" />
                      Information You Can <br className="d-none d-md-block d-lg-none" />
                      Trust — <i>Without</i> Hype
                    </>
                  )}
                </h3>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  <b>
                    {variant === 'ad' ? (
                      <>
                        Our experts are vetted as top, world-leading food and health mentors — with integrity and heart.
                      </>
                    ) : (
                      <>Our experts are world-leading food and health mentors — with integrity and heart.</>
                    )}
                  </b>
                </p>
                <p>
                  At Food Revolution Network, we ♥️ science, and we go to great lengths to make sure that the resources
                  we share are based on peer-reviewed research, scholarly articles, university studies, and other
                  credible sources.
                </p>
                <p>
                  {variant === 'ad' ? (
                    <>
                      That’s why this series is the trusted source for food and lifestyle wisdom, recipes, and action
                      steps to take right away.
                    </>
                  ) : (
                    <>
                      We’re critical thinkers with great big hearts, and we know you are, too! That’s why this series is
                      your trusted source for food and lifestyle wisdom, recipes, and action steps that make a
                      difference in how you feel every day.
                    </>
                  )}
                </p>
                <ul className="list-check-green mt-4">
                  <li>
                    <b>We don’t do “fake news.”</b>{' '}
                    {variant === 'ad' ? (
                      <>
                        Every guest we interview is an expert in their field. What we’re sharing is results-oriented,
                        actionable, and trustworthy.
                      </>
                    ) : (
                      <>
                        Every guest we interview is an expert in their field. You need the best information to create a
                        healthy life, and we’re determined to make sure that what you learn is actionable,
                        results-oriented, and trustworthy.
                      </>
                    )}
                  </li>
                  <li>
                    <b>Each Action Hour focuses on your WHOLE self.</b> Everything is connected, so we focus on healthy
                    food habits, meaningful family traditions, and wise lifestyle choices that anyone can adopt.
                  </li>
                  <li>
                    <b>{isSeries ? 'These leaders take' : 'This leader takes'} you to the next level: naturally!</b>{' '}
                    {variant === 'ad' ? (
                      <>
                        Get insights to improve on every level: mind, body, and spirit. We bring holistic wisdom with
                        kindness, ethics, consciousness, and LOVE.
                      </>
                    ) : (
                      <>
                        Get insights to improve on every level: mind, body, and spirit. We bring holistic wisdom to help
                        you feel supported with kindness, ethics, consciousness, and LOVE.
                      </>
                    )}
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="landing-cta-2"
          className="section-noangle"
          color="purple"
          imgPosition="right"
          innerClass="text-white text-center"
          imgUrl={require('static/backgrounds/woman-fridge-square.jpg')}
        >
          <h3 className="section-heading">
            {variant === 'ad' ? (
              <>
                Sign up now for the WHOLE Life {seriesName} for Simple Steps to Health that{' '}
                <br className="d-none d-sm-block d-md-none d-xl-block" />
                Lasts a Lifetime
              </>
            ) : (
              <>
                Sign up now for the WHOLE Life {seriesName} and Discover Simple Steps to Health that{' '}
                <br className="d-none d-xl-block" />
                Lasts a Lifetime
              </>
            )}
          </h3>
          <p>Because these solutions can help YOU thrive.</p>
          <OptinModalTrigger />
          <div className="section-cta-countdown mt-4">
            <LandingTimerSection className="section-countdown-cta" />
          </div>
        </SectionImage>
      </div>

      <Footer />
      <ShareButtons floating />
      <OptinModal />
      {noExitIntent ? null : <>{isSeries ? <ModalExitLanding launch /> : <ModalExitLanding />}</>}
    </div>
  );
}
