import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import Angle, {AngleTop} from 'components/Elements/Angle';
import './Section.scss';

export default function Section({angle = 'bottom', id, color, ...props}) {
  return (
    <section id={id} className={classNames(`section background-${color}`, props.className)}>
      {angle === 'top' || angle === 'both' ? (
        <AngleTop
          className={classNames(`angle-top`, props.angleTopClass)}
          angleWrapClass={props.angleWrapClass}
          color={color}
        />
      ) : null}
      <div className={classNames(`section-inner section-inner-${angle}`, props.innerClass)}>{props.children}</div>
      {angle === 'bottom' || angle === 'both' ? (
        <Angle
          className={classNames(`angle-bottom`, props.angleClass)}
          angleWrapClass={props.angleWrapClass}
          color={color}
        />
      ) : null}
    </section>
  );
}

export function SectionImage({
  id,
  color,
  borderColor = 'white',
  imgPosition = 'left',
  imgBgPosition = 'center',
  colClass = 'col-12 col-lg-7',
  children,
  ...props
}) {
  let stylesMobile = {
    backgroundImage: `url('${props.imgMobileUrl ? props.imgMobileUrl : props.imgUrl}')`
  };

  let stylesDesktop = {
    backgroundImage: `url('${props.imgUrl}')`
  };

  let row = `d-flex justify-content-${imgPosition === 'left' ? 'end' : 'start'}`;

  let sectionWithTitle = props.title ? `section-image-${imgPosition}-with-title` : null;

  return (
    <section id={id} className={classNames(`section`, props.className)}>
      <div
        className={classNames(
          `section-image-mobile background-image-${
            props.imgBgPositionMobile ? props.imgBgPositionMobile : imgBgPosition
          }`
        )}
        style={stylesMobile}
      />

      <div className={classNames(`section-image-content background-${color}`)}>
        <AngleTop className="d-lg-none" color={color} borderColor={borderColor} />

        <div className={classNames(`section-image-content-inner`, props.innerClass)}>
          <Container>
            <div
              className={classNames(
                `section-image-desktop section-image-${imgPosition} section-image-${color} background-image-${imgBgPosition}`,
                sectionWithTitle
              )}
              style={stylesDesktop}
            />

            {props.title ? (
              <Row className={classNames(`section-image-content-title`, props.titleClass)}>
                <Col>{props.title}</Col>
              </Row>
            ) : null}

            <Row className={row}>
              <Col className={colClass}>{children}</Col>
            </Row>
          </Container>
        </div>

        <Angle color={color} borderColor={borderColor} />
      </div>
    </section>
  );
}
