import React from 'react';
import {Head} from 'react-static';

export default function PageMeta({
  url = 'https://wlc.foodrevolution.org/',
  ogImage = 'https://cdn.foodrevolution.org/wlc/opengraph/wlc-2021-open-graph-v1-1200x630.jpg',
  title = 'Join the Free, Online WHOLE Life Action Hour Series!',
  description = 'Get trusted solutions and practical, science-based action steps you can take NOW to achieve the lasting health and vitality you deserve.',
  ...props
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@afoodrevolution" />
      <meta property="twitter:image" content={ogImage} />
      <meta property="fb:app_id" content="1685199371775570" />
      <meta property="og:site_name" content="Food Revolution Summit" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
    </Head>
  );
}
