import React, {useRef, useCallback, useEffect, useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
// @ts-ignore
import {Link} from 'react-scroll';
import {usePhaseState} from 'funnel-schedule/phase-context';
import useFixedFloating from 'utils/useFixedFloating';
import {OptinModalTrigger} from 'components/OptinModal';
import CheckoutButton from 'components/CheckoutButton';
import classNames from 'classnames';
import './HeaderVideo.scss';
import Video from 'components/Video';

export default function HeaderVideo({thumbnail, noButton = false, noFixed, label, textClass = 'text-left', ...props}) {
  const videoWrapper = useRef(null);
  const [isFixed, disableFixedListener] = useFixedFloating(videoWrapper);
  const [playing, setPlaying] = useState(props.playing === true);
  const dismiss = useCallback(() => {
    setPlaying(false);
    return disableFixedListener();
  }, []);

  useEffect(() => {
    if (noFixed) return disableFixedListener();
  }, [noFixed]);

  const onPlay = useCallback(() => setPlaying(true), []);

  useEffect(() => {
    isFixed ? document.body.classList.add('has-fixed-header') : document.body.classList.remove('has-fixed-header');
  }, [isFixed]);

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: ''
          }
        ]
      }
    }
  } = usePhaseState();

  const seriesName = `Action Hour${defaultSchedule.actionHours.length > 1 ? ' Series' : ''}`;
  return (
    <div className="header-video-wrapper" ref={videoWrapper}>
      <div className="header-video-inner">
        <div className={isFixed ? 'header-video-fixed' : 'header-video-start'}>
          {isFixed ? (
            <Button color="header-video-close" onClick={dismiss}>
              <span aria-hidden="true">×</span>
            </Button>
          ) : null}

          <div
            className={classNames(
              `${
                isFixed ? 'header-video-background header-video-fixed-background' : 'header-video-background'
              } background-${props.bgColor}`
            )}
          >
            <div className={isFixed ? 'container' : null}>
              <div className={isFixed ? 'row d-flex align-items-center' : null}>
                <div className={isFixed ? 'header-video-col col-md-5 col-xl-4' : null}>
                  <Video
                    url={props.url}
                    playing={playing}
                    onPlay={onPlay}
                    {...(thumbnail ? {thumbnail} : {})}
                    label={label}
                    noButton={noButton}
                  />
                </div>
                {isFixed ? (
                  <Col md="7" xl="6" className="py-2">
                    <div className="header-video-cta text-left">
                      {props.optin ? (
                        <>
                          <div className="d-none d-md-inline">
                            <h4 className="section-heading text-400">
                              Join in for the WHOLE Life <br className="d-none d-md-block" />
                              {seriesName}
                            </h4>
                          </div>
                          <OptinModalTrigger entry={props.entry} />
                        </>
                      ) : null}
                      {props.welcome ? (
                        <>
                          <div className="d-none d-md-inline">
                            <h4 className="section-heading section-heading-welcome">
                              Achieve your health goals and join WHOLE Life Club today!
                            </h4>
                          </div>
                          <CheckoutButton sku={props.sku} price={props.price} slug={props.slug} />
                        </>
                      ) : null}

                      {props.sales ? (
                        <>
                          <div className="d-none d-md-inline">
                            <h4 className="section-heading">
                              Become a WHOLE <br className="d-none d-md-block" />
                              Life Club Member Today!
                            </h4>
                          </div>
                          <CheckoutButton sku={props.sku} price={props.price} slug={props.slug}>
                            {props.salesBtnText}
                          </CheckoutButton>
                        </>
                      ) : null}

                      {props.actionCollection ? (
                        <>
                          <div className="d-none d-md-inline">
                            <h4 className="section-heading">
                              Know Exactly What Steps to Take to Improve Your Healthy Lifestyle
                            </h4>
                          </div>
                          <CheckoutButton sku={props.sku} price={props.price} slug={props.slug} />
                        </>
                      ) : null}
                    </div>
                  </Col>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

HeaderVideo.defaultProps = {
  bgColor: 'purple',
  salesBtnText: 'Buy Now'
};

export function ScrollingVideo({
  className = undefined,
  noFixed = false,
  bgColor = 'black',
  text,
  textColor = 'white',
  video: {url, thumbnail, label, noButton},
  ...props
}) {
  const videoWrapper = useRef(null);
  const [isFixed, disableFixedListener] = useFixedFloating(videoWrapper);
  const [playing, setPlaying] = useState(props.playing === true);
  const dismiss = useCallback(() => {
    setPlaying(false);
    return disableFixedListener();
  }, []);

  useEffect(() => {
    if (noFixed) return disableFixedListener();
  }, [noFixed]);

  const onPlay = useCallback(() => setPlaying(true), []);

  useEffect(() => {
    isFixed ? document.body.classList.add('has-fixed-header') : document.body.classList.remove('has-fixed-header');
  }, [isFixed]);

  return (
    <div className="header-video-wrapper" ref={videoWrapper}>
      <div className="header-video-inner">
        <div className={isFixed ? 'header-video-fixed' : 'header-video-start'}>
          {isFixed ? (
            <Button color="header-video-close" onClick={dismiss}>
              <span aria-hidden="true">×</span>
            </Button>
          ) : null}

          <div
            className={classNames(
              `${
                isFixed ? 'header-video-background header-video-fixed-background' : 'header-video-background'
              } background-${bgColor}`
            )}
          >
            <div className={isFixed ? 'container' : null}>
              <div className={isFixed ? 'row d-flex align-items-center' : null}>
                <div className={isFixed ? 'header-video-col col-md-5 col-xl-4' : null}>
                  <Video
                    url={url}
                    playing={playing}
                    onPlay={onPlay}
                    {...(thumbnail ? {thumbnail} : {})}
                    label={label}
                    noButton={noButton}
                  />
                </div>
                {isFixed && (
                  <Col sm="10" md="7" xl="8" className="py-md-3 px-0 px-sm-3">
                    <div className={classNames(`header-video-cta`, `text-left`)}>
                      {text && <div className={classNames(`text-${textColor}`, `d-none`, `d-md-inline`)}>{text}</div>}
                      <div className={classNames(`header-video-button`)}></div>
                    </div>
                  </Col>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
