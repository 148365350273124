import React, {useState} from 'react';
import {navigate} from '@reach/router';
import classNames from 'classnames';
import ContactInfoForm from './components/ContactInfoForm';
import SegmentationForm from './components/SegmentationForm';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import './OptinForm.scss';

function OptinForm({id, linkColor = 'purple', onComplete = () => {}, onStepComplete = () => {}, entry, ...props}) {
  const [step, setStep] = useState(0);
  return (
    <ContactInfoForm
      onComplete={() => {
        setStep(1);
        onStepComplete(1);
        onComplete();
      }}
      id={id}
      entry={entry}
    />
  );
}
export default OptinForm;
