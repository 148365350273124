import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import {Location} from '@reach/router';
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import useBeacon from 'utils/useBeacon';
import useBalanceText from 'utils/useBalanceText';

import Section, {SectionImage} from 'components/Elements/Section';
import PageMeta from 'components/PageMeta';
import HeaderVideo from 'components/Elements/HeaderVideo';
import CheckoutButton from 'components/CheckoutButton';
import WaitListForm, {WaitListFormTrigger} from 'components/WaitListForm';
import {SalesDisclaimer, TreesForTheFuture} from 'components/SalesCta';
import {TestimonialCircle} from 'components/Testimonial';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {ImgBulletCard} from 'components/Elements/ImgBullet';
import ShareButtons from 'components/Social/ShareButtons';
import ModalExitWelcome from 'components/Welcome/ModalExitWelcome';
import Footer from 'components/Footer';

export default function (props) {
  return <Location>{({location}) => <Welcome location={location} {...props} />}</Location>;
}

function Welcome({location = {}, ...props}) {
  const qs = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
  const {fromEntry = 'default'} = qs.fromEntry ? qs : location.state || {};

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            welcomeVideo: ''
          }
        ]
      },
      sales: salesSchedule = {
        phase: 'before',
        salesEndDate: ''
      }
    }
  } = usePhaseState();
  const userState = useUserState();

  const isSeries = defaultSchedule.actionHours.length > 1;
  const currentActionHour = defaultSchedule.currentActionHour;
  const seriesName = `Action Hour${isSeries ? ' Series' : ''}`;

  const isRegularSale = salesSchedule.phase === 'during';
  const is14dayTrial = isRegularSale && salesSchedule.offerFlag === 'dollarTrial14day';
  const is30dayTrial = isRegularSale && salesSchedule.offerFlag === 'dollarTrial30day';
  const isDollarTrial = is14dayTrial || is30dayTrial;

  const isSpecialSale = salesSchedule.phase === 'during';
  const isSales127 = isSeries || isSpecialSale;

  const isEvergreenSales = defaultSchedule.isEvergreenSales;

  const isSales = isSeries || isSpecialSale || isRegularSale || isEvergreenSales;

  const productSlug = is14dayTrial
    ? 'wlc-promo-special-14daytrial'
    : is30dayTrial
    ? 'wlc-promo-special-30daytrial'
    : isSales127
    ? 'wlc-promo-special'
    : 'wlc-special-brain';
  const productValue = isDollarTrial ? 1 : isSales127 ? 127 : 147;

  function WelcomeCheckoutButton({className = null, ...props}) {
    return (
      <CheckoutButton className={className} sku="WLC" price={productValue} slug={productSlug}>
        <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Buy Now
      </CheckoutButton>
    );
  }

  function CtaSection(props) {
    return (
      <Section id={props.id} color="gradient-indigo-purple-right" {...props}>
        <Container>
          {props.title ? (
            <Row className="text-center text-white mb-5">
              <Col>{props.title}</Col>
            </Row>
          ) : null}
          <Row className="d-flex justify-content-center">
            <Col lg="10" xl="8">
              <div className="purchase-card">
                <div className="purchase-card-header background-black text-white rounded-top p-3 p-lg-4">
                  <h3 className="purchase-card-title text-uppercase text-center m-0">Get Started Now!</h3>
                </div>
                <div className="purchase-card-content background-white rounded-bottom px-4 px-lg-5 py-4">
                  <div className="text-center mb-4">
                    {isDollarTrial ? (
                      <h3 className="text-green m-0">Join For Just $1 Today</h3>
                    ) : isSales127 ? (
                      <>
                        <h3 className="text-green m-0">Join for $127/year or just $19/month</h3>
                      </>
                    ) : (
                      <>
                        <h3 className="text-green m-0">Join for just $147/year or just $19/month</h3>
                      </>
                    )}
                  </div>

                  <div className="list-simple-check-wrap">
                    <IconList className="text-base">
                      <IconListItem>
                        6 delicious, whole foods, plant-powered <br className="d-none d-sm-block" />
                        recipes per week.
                      </IconListItem>
                      <IconListItem>
                        Weekly videos from Ocean Robbins <br className="d-none d-sm-block" />
                        delivered to your inbox.
                      </IconListItem>
                      <IconListItem>
                        Monthly Action Hour events with <br className="d-none d-sm-block d-lg-none" />
                        health revolutionaries.
                      </IconListItem>
                      <IconListItem>Action Checklists to keep you on track.</IconListItem>
                      <IconListItem>
                        100s of fabulous recipes, with new options <br className="d-none d-sm-block" />
                        added all the time.
                      </IconListItem>
                      <IconListItem>A private, moderated community.</IconListItem>
                      <IconListItem>Access to Health Coaches & Dieticians.</IconListItem>
                      <IconListItem>Access to the entire library of 150+ videos.</IconListItem>
                      <IconListItem>500+ health articles.</IconListItem>
                      <IconListItem>Annual rates that will never go up as long as you remain a member.</IconListItem>
                      <IconListItem>
                        A state-of-the-art membership site that’s easy to navigate, and much more!
                      </IconListItem>
                    </IconList>
                  </div>

                  <div className="text-center mb-3">
                    <p className="my-4">
                      <b>
                        The doors to WHOLE Life Club are open for direct entry. Get in now, with a stellar group of
                        cohorts (and save big!) today.
                      </b>
                    </p>
                    <h3 className="section-heading text-green mb-2">
                      {isDollarTrial ? (
                        <>
                          Only $1 for a month trial
                          <br />
                          Then <del>$247</del> $99/year
                        </>
                      ) : isSales127 ? (
                        <>
                          Only <del>$247</del> $127/year
                        </>
                      ) : (
                        <>
                          Only <del>$247</del> $147/year
                        </>
                      )}
                    </h3>
                    <h5 className="text-400 mt-0 mb-3">
                      (That’s just {isDollarTrial ? '$0.27' : isSales127 ? '$0.35' : 'pennies'} a day)
                    </h5>
                    <h5 className="m-0">Or $19/month</h5>
                  </div>
                  <div className="text-center">
                    <WelcomeCheckoutButton className="offer-box-button" />
                  </div>
                  <SalesDisclaimer />
                  {isDollarTrial ? (
                    <p className="text-small text-center mt-2">
                      Join WHOLE Life Club for just $1 for your first month. After that, you’ll be enrolled for a
                      special membership price of just $99 a year (that’s 27 cents per day) unless you cancel before
                      your month is up.
                    </p>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }

  useBeacon();
  useBalanceText();
  return (
    <div className="page">
      <PageMeta title="Welcome to the WHOLE Life Action Hours" description="Make FOOD the foundation of YOUR health" />
      <div className="page-content">
        <Section id="welcome-header" color="black" className="section-angle-first" innerClass="text-white text-center">
          <Container>
            <Row className="mb-4">
              <Col>
                <h3 className="section-heading mb-3">
                  Thank you{' '}
                  {userState && userState.user && userState.user.firstname && `, ${userState.user.firstname},`} for
                  Joining Us for the Free WHOLE Life {seriesName}!
                </h3>
                <p className="balance-text">
                  {isSeries ? 'These events' : 'This event'} will help YOU put healthy eating wisdom into ACTION. In a
                  few minutes, you’ll get an email with your next steps...
                </p>
                <p className="balance-text">
                  <b>But first,</b> watch this video to find out how to get the most out of this{' '}
                  {isSeries ? (
                    <>
                      series AND get a <i>special, one-time opportunity</i> you’ll only see on this page...
                    </>
                  ) : (
                    <>event.</>
                  )}
                </p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <HeaderVideo
                  bgColor="black"
                  sku="WLC"
                  price={productValue}
                  slug={productSlug}
                  url={
                    defaultSchedule.actionHours[currentActionHour].welcomeVideo
                      ? defaultSchedule.actionHours[currentActionHour].welcomeVideo
                      : 'https://www.youtube.com/watch?v=mcFuNpzTCNQ'
                  }
                  welcome
                  noFixed={isSeries ? false : true}
                />
              </Col>
            </Row>

            {!isSales && (
              <Row>
                <Col>
                  <h3 className="mb-2">Registration opens twice per year.</h3>
                  <h5 className="mt-0">
                    Click the button below to be notified when we open the doors to registration next:
                  </h5>
                  <WaitListFormTrigger className="mb-4" />
                  <p className="balance-text mb-0">
                    Read on to find out how WHOLE Life Club works so you can join in when your time comes.
                  </p>
                </Col>
              </Row>
            )}
          </Container>
        </Section>

        {isSales ? (
          <Section id="welcome-header-cta" color="white">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col xl="10" className="text-center">
                  <h2 className="section-only-heading mb-3">
                    {isDollarTrial ? (
                      <>SPECIAL OFFER RIGHT NOW: Just $1 for your first month in WHOLE Life Club</>
                    ) : (
                      <>
                        SPECIAL OFFER RIGHT NOW: <i>SAVE {isSpecialSale ? '$120' : '$100'}</i>
                      </>
                    )}
                  </h2>
                  <h5 className="mt-0 mb-4">
                    {isDollarTrial ? (
                      <>
                        (With annual renewal at a 60% savings AND get a Special Bonus only from this page — see below)
                      </>
                    ) : (
                      <>(and get a Special Bonus only from this page — see below)</>
                    )}
                  </h5>
                  <Row>
                    <Col>
                      <h4 className="text-400 mt-0 mb-2">
                        Annual Membership: <del>$247</del>
                        {isDollarTrial ? ' $99' : null}
                      </h4>
                      <h1 className="text-green mt-0 mb-3">
                        {isDollarTrial ? (
                          <>First month only $1</>
                        ) : (
                          <>For only {isSeries || isSpecialSale ? '$127' : '$147'}</>
                        )}
                      </h1>
                      {isDollarTrial ? null : <h4 className="text-green mt-0 mb-3">or just $19/month</h4>}
                      <WelcomeCheckoutButton />
                    </Col>
                  </Row>
                  <SalesDisclaimer noGuaranteeText />
                  {isDollarTrial ? (
                    <p className="balance-text text-small mt-2">
                      Join WHOLE Life Club for just $1 for your first month. After that, you’ll be enrolled for a
                      special membership price of just $99 a year (that’s 27 cents per day) unless you cancel before
                      your month is up. Your purchase is protected by our 60-day money-back guarantee.
                    </p>
                  ) : null}
                </Col>
              </Row>
            </Container>
          </Section>
        ) : null}

        <Section
          id="welcome-message"
          className="background-image-wood-panel background-image-center background-cover background-fixed section-angle-both-offset"
          angle="none"
          color="light-gray"
        >
          <Container>
            <Row>
              <Col>
                <div className="background-white px-3 py-4 px-lg-5 py-lg-5 my-3">
                  <h3 className="section-heading text-center">
                    A Message From Food Revolution Network Co-founder Ocean Robbins
                  </h3>
                  <p>
                    You just signed up for the FREE WHOLE Life Action Hour{isSeries ? ' series' : null}, and I’m so glad
                    you did!{isSeries ? <> You’re going to love these episodes.</> : null}
                  </p>
                  <p>
                    If you want to go further to achieve your health goals, optimize your immune system, prevent
                    disease, and enjoy more peace, vitality, and satisfaction — you’re going to love WHOLE Life Club!
                  </p>
                  <p>Whether you’re</p>
                  <ul className="mb-4">
                    <li>Facing a serious diagnosis…</li>
                    <li>In recovery from an illness…</li>
                    <li>Suffering from a few aches and pains…</li>
                    <li>Wanting to protect yourself and your family from viruses and other pathogens…</li>
                    <li>Or already feeling strong on a daily basis and want to stay that way…</li>
                  </ul>
                  <p>There’s one thing you can know with certainty:</p>
                  <h4 className="mt-0">
                    Now is the best time to take action and create new healthy habits, so you can live the life you
                    dream of. Food can be your best friend when you eat to thrive!
                  </h4>
                  <Row className=" d-flex justify-content-center align-items-center mb-xl-4">
                    <Col xs="12" sm="8" lg="4" className="text-center mb-4 mb-lg-0">
                      <img
                        className="img-ocean-note img-ocean-note-flexing img-fluid rounded-circle"
                        src={require('static/speaker/john-ocean-square.jpg')}
                      />
                    </Col>
                    <Col xs="12" lg="8">
                      <p>
                        My dad and colleague, 2-million-copy best-selling author John Robbins, and I have been
                        mobilizing this food revolution for decades.
                      </p>
                      <h5 className="mt-0">We’ve seen the power food can have to fuel health.</h5>
                      <p>
                        We’ve also seen that for many people, the biggest challenge isn’t how much they know — it’s
                        putting what they already know into action. Consistently.
                      </p>
                      <h4 className="mt-0">Knowledge is power. But only actions lead to results.</h4>
                    </Col>
                  </Row>
                  <p>
                    That’s why we created WHOLE Life Club — to help you make progress towards the lasting results you
                    want.
                  </p>
                  <p>
                    If you’re ready to immerse yourself in a new, healthy way of life, come join us. You’ll see for
                    yourself how good life gets when you have all the support and science-based nutrition insights you
                    need.
                  </p>
                  <p>I can’t wait to hear your before-and-after story!</p>
                  <Row className="d-flex align-items-center justify-content-center mt-3">
                    <Col xs="12" lg="8" xl="9" className="mb-4 mb-lg-0 text-center text-lg-left">
                      <img
                        className="img-signature mb-1"
                        src={require('static/ocean-signature.jpg')}
                        style={{maxHeight: '65px'}}
                      />
                      <h3 className="mt-0 mb-2 text-uppercase">Ocean Robbins</h3>
                      <h5 className="mt-0 mb-2">Co-Founder, Food Revolution Network</h5>
                      <h5 className="mt-0 mb-2">Host, WHOLE Life Club</h5>
                    </Col>
                    <Col xs="10" sm="8" lg="4" xl="3">
                      <img className="rounded-circle" src={require('static/speaker/ocean-robbins.jpg')} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        {isSales ? (
          <Section id="welcome-get" color="light-gray" angle="both">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading text-center">
                    Here’s what else you’ll get when you join WHOLE Life Club today:
                  </h3>
                </Col>
              </Row>
              <ImgBulletCard img={require('static/box-recipe.jpg')}>
                Our library of over 500 delicious, healthy, and easily searchable plant-based recipes, plus 6 new
                fabulous recipes delivered every single week.
              </ImgBulletCard>
              <ImgBulletCard img={require('static/box-video.jpg')}>
                A new short action video every week, plus hundreds of action videos and checklists from our entire
                content library.
              </ImgBulletCard>
              <ImgBulletCard img={require('static/monthly-action-hours-headshots-square.png')}>
                Monthly Action Hours with world-class guest experts sharing the hottest health news and essentials.
                (Plus our library of 50+!)
              </ImgBulletCard>
              <ImgBulletCard img={require('static/box-community.jpg')}>
                A sustained and moderated online community — so you can celebrate wins, share breakthroughs, and learn
                from others.
              </ImgBulletCard>
              <ImgBulletCard img={require('static/health-coaches.jpg')}>
                Access to expert nutritionists and health coaches — ask questions and get answers to your pressing
                health and nutrition questions, anytime!
              </ImgBulletCard>
              <ImgBulletCard img={require('static/backgrounds/1026486992.jpg')}>
                Action checklists and resources so you can steadily make progress and stay motivated as you create new
                healthy habits that last a lifetime.
              </ImgBulletCard>
              <ImgBulletCard
                bgColor="purple"
                textColor="white"
                borderColor="purple"
                img="https://cdn.foodrevolution.org/wlc/brain-power-collection.png"
              >
                <h5 className="section-heading text-uppercase mb-3">Free Bonus!</h5>
                <p className="mb-0">
                  <b>RIGHT NOW ONLY!</b> The Brain Power Collection — interviews with three leading brain health
                  experts, including transcripts and action checklists to help you <i>stick with it.</i>
                </p>
              </ImgBulletCard>
            </Container>
          </Section>
        ) : null}

        <Section id="welcome-testimonial-1" color="white" angle={isSales ? 'bottom' : 'both'}>
          <Container>
            <Row>
              <Col>
                <p className="balance-text text-center text-16 mb-5">
                  <i>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. Their testimonials reflect their unique experience. Your specific results will vary.
                  </i>
                </p>
                <TestimonialCircle id="sylvie-w-v2" />
                <hr className="hr-black-20 my-5" />
                <TestimonialCircle id="robin-e-v2" imgPosition="right" />
                <hr className="hr-black-20 my-5" />
                <TestimonialCircle id="amelia-c-v2" />
                <hr className="hr-black-20 my-5" />
                <TestimonialCircle id="christopher" imgPosition="right" />
                <hr className="hr-black-20 my-5" />
                <TestimonialCircle id="maureen-v2" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="welcome-results"
          imgPosition="right"
          color="light-gray"
          imgUrl={require('static/backgrounds/woman-nature-square.jpg')}
          imgUrlMobile={require('static/backgrounds/woman-nature.jpg')}
        >
          {isSeries ? (
            <>
              <h5 className="section-heading">
                Hey, I know it's weird to ask you to think about joining when you haven't even experienced the WHOLE
                Life Club Action Hour interviews yet.
              </h5>
              <p>
                But here's what I'm thinking: you're on a roll right now, taking action and making decisions in
                alignment with your best future. Why not strike while the iron is hot, and lock in a full year of
                education, inspiration, community, advice, recipes, and events?
              </p>
              <p>
                That is: take advantage of your current high level of motivation to make a decision that will support
                you even when your motivation wanes (which is totally normal, by the way).
              </p>
              <p>
                And thanks to our unconditional 60-day guarantee, you have absolutely nothing to lose by acting now. We
                take on 100% of the risk.
              </p>
              <p>
                <b>
                  So you’re actually being wise, not impulsive, by giving the WHOLE Life Club a try right now for a
                  single dollar.
                </b>
              </p>
            </>
          ) : (
            <>
              <h3 className="section-heading">It’s Time for YOU to Get Results!</h3>
              <p>You know how important it is to eat a variety of vegetables and whole grains.</p>
              <p>But that doesn’t do you much good when you’re in a food rut and aren’t inspired.</p>
              <p>
                And even having the best books on your bookshelf is of little use when cookies, chips, soda, crackers,
                or fast foods start to beckon you...
              </p>
              <p>
                Maybe you want to eat well, but get confused when new articles come out that contradict everything you
                thought you knew.
              </p>
              <p>
                <strong>WHOLE Life Club to the rescue!</strong>
              </p>
              <p>
                <strong>
                  Cut through the noise and get the practical, easy-to-implement steps you need to see lasting RESULTS.
                </strong>
              </p>
            </>
          )}
        </SectionImage>

        {isSales ? <CtaSection id="salesbox-1" /> : null}

        <Section id="welcome-testimonial-2" color="white">
          <Container>
            <Row>
              <Col>
                <p className="balance-text text-center text-16 mb-5">
                  <i>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. Their testimonials reflect their unique experience. Your specific results will vary.
                  </i>
                </p>
                <TestimonialCircle id="juanita-v2" />
                <hr className="hr-black-20 my-5" />
                <TestimonialCircle id="cindy-v2" imgPosition="right" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="welcome-themes-1"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1215073452.jpg')}
        >
          <h3 className="section-heading">Here are some recent WHOLE Life Club topics</h3>
          <p className="balance-text">
            <i>You’ll get them all instantly — the moment you join!</i>
          </p>
          <IconList>
            <IconListItem>
              <b>Foods to Fight Cancer and Thrive in Every Cell</b> — see how it’s possible to prevent and even take
              steps that help beat cancer.
            </IconListItem>
            <IconListItem>
              <b>Preventing Dementia</b> — employ the new breakthroughs in nutrition and lifestyle to keep your brain
              sharp and avoid devasting brain diseases.
            </IconListItem>
            <IconListItem>
              <b>Probiotics and Gut Health</b> — this is one of the newest and most promising health discoveries of our
              time, and we share all the breaking updates as they roll in.
            </IconListItem>
            <IconListItem>
              <b>Hidden Dangers</b> — pesticides, glyphosate, food safety, GMOs, toxins: what you need to know to
              protect yourself and your family.
            </IconListItem>
            <IconListItem>
              <b>Stopping Heart Disease</b> — learn how to avoid the #1 cause of death by switching to heart-healthy
              superfoods and adopting positive lifestyle routines you’ll love.
            </IconListItem>
            <IconListItem>
              <b>Detoxing and Cleansing</b> — use food to heal your body naturally.
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="welcome-themes-2"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1179985039.jpg')}
        >
          <IconList>
            <IconListItem>
              <b>Reversing Chronic Inflammation</b> — implement scientifically proven ways to reduce chronic
              inflammation and help your body to heal.
            </IconListItem>
            <IconListItem>
              <b>Weight Loss and Food Freedom</b> — discover how to rewire your brain to eat what’s good for you.
            </IconListItem>
            <IconListItem>
              <b>Outdoor and Indoor Gardening</b> — grow your own fresh fruits, veggies, and herbs for the most
              delicious flavor and maximum nutrient levels (even in small spaces).
            </IconListItem>
            <IconListItem>
              <b>Creating Joy and Dealing With Stress</b> — get truly effective mind-body techniques so you can become
              the happiest version of yourself.
            </IconListItem>
            <IconListItem>
              <b>Mythbusting</b> — no longer wonder whether you’re doing the right thing when it comes to soy, carbs,
              protein, fats, and other hotly debated topics.
            </IconListItem>
            <IconListItem>
              <b>Mental Health</b> — find out what landmark studies show us about how neuroscience and nutrition can
              help create balance, resilience, and less suffering.
            </IconListItem>
          </IconList>
          <h5 className="mt-0">And there will be new ones for you every month!</h5>
        </SectionImage>

        {isSales ? (
          <>
            <CtaSection
              id="salesbox-2"
              title={
                <>
                  <h3 className="section-only-heading">Join WHOLE Life Club Now, While Enrollment is Open to All.</h3>
                </>
              }
            />

            <Section id="welcome-brain" color="white">
              <Container>
                <Row>
                  <Col>
                    <h3 className="section-heading text-center mb-3">
                      Plus, If You Join Right Now, From This Page — You’ll Get Our Brain Power Collection Bonus!
                    </h3>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center">
                  <Col xs="10" lg="5" className="mb-3 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/wlc/brain-power-collection.png" />
                  </Col>
                  <Col xs="12" lg="7">
                    <p>
                      This collection will show you <b>how to use food to prevent Alzheimer’s and dementia</b> and{' '}
                      <i>achieve peak brain performance.</i>
                    </p>
                    <p>With this added bonus, you’ll get:</p>
                    <IconList>
                      <IconListItem>
                        <b>Three wildly popular interviews from past Food Revolution Summits</b> — featuring John
                        Robbins interviewing Dr. Dale Bredesen, Dr. Daniel Amen, and Drs. Dean and Ayesha Sherzai
                        (including audio recordings and written transcripts).
                      </IconListItem>
                      <IconListItem>
                        <b>Simple Action Checklists</b> to help you <i>put the top lessons into action</i> in your life
                        right away (<b>new for everyone</b> — even past Food Revolution Summit Empowerment Package
                        owners have never seen this before!)
                      </IconListItem>
                    </IconList>
                  </Col>
                </Row>
              </Container>
            </Section>

            <Section id="welcome-guarantee" color="light-gray">
              <Container>
                <Row>
                  <Col>
                    <div className={classNames(`guarantee-box rounded background-white box-shadow-black-50`)}>
                      <h2 className="section-heading guarantee-heading text-center text-sm-left">
                        Our 60-Day, Money-Back, Risk-Free Guarantee
                      </h2>
                      <p>Take up to 60 days to see what WHOLE Life Club is all about.</p>
                      <p className="mb-4">We guarantee that you and your family will:</p>
                      <IconList>
                        <IconListItem>
                          Have the tools and information to improve your health right away, helping you eat better and
                          enjoy more vitality.
                        </IconListItem>
                        <IconListItem>Feel empowered knowing the truth about the foods you eat.</IconListItem>
                        <IconListItem>Make meaningful progress towards YOUR healthy eating goals.</IconListItem>
                      </IconList>
                      <p>
                        If for any reason you request a refund, everything you've already downloaded is still yours to
                        keep. That’s why we call our guarantee “risk-free!”
                      </p>
                      <p>
                        If for some reason you aren’t 100% thrilled with your purchase, you have 60 days to request a
                        prompt, total refund. Email us at{' '}
                        <a className="link-black text-underline" href="mailto:support@foodrevolution.org">
                          support@foodrevolution.org
                        </a>{' '}
                        or phone us at{' '}
                        <a className="link-black text-underline" href="tel:8318244779">
                          (831) 824-4779
                        </a>
                        . You can also hit reply to any email we send you with your request.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Section>
          </>
        ) : null}

        <Section id="welcome-testimonial-3" color="white" angle={isSales ? 'bottom' : 'none'}>
          <Container>
            <Row>
              <Col>
                <p className="balance-text text-center text-16 mb-5">
                  <i>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. Their testimonials reflect their unique experience. Your specific results will vary.
                  </i>
                </p>
                <TestimonialCircle id="lydia-k-v2" />
                <hr className="hr-black-20 my-5" />
                <TestimonialCircle id="lisa-v2" imgPosition="right" />
              </Col>
            </Row>
          </Container>
        </Section>

        {isSales ? (
          <>
            <Section id="welcome-why" color="light-gray">
              <Container>
                <Row className="d-flex align-items-center justify-content-center mb-4">
                  <Col>
                    <img
                      src="https://cdn.foodrevolution.org/wlc/wlc-product-image-v20220329.png"
                      width="1200"
                      height="500"
                      alt="WHOLE Life Club graphic"
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col lg="10">
                    <h3 className="section-heading text-center mb-3">Why Should You Join Today?</h3>
                    <h6 className="text-center text-400 mt-0 mb-4">
                      When you join WHOLE Life Club{' '}
                      {isDollarTrial ? (
                        <>
                          for just <b>$1 today,</b>
                        </>
                      ) : (
                        <>
                          at the <b>annual rate,</b>
                        </>
                      )}{' '}
                      you’ll get:
                    </h6>
                    <IconList>
                      <IconListItem>
                        {' '}
                        {isDollarTrial ? (
                          <>
                            <b>Your first month for just $1!</b> PLUS get a special low price... you’ll pay only $99 per
                            year after your first month — <i>that’s just $8.25 a month.</i>
                          </>
                        ) : (
                          <>
                            <b>A special low price...</b> you’ll pay only {isSeries || isSpecialSale ? '$127' : '$147'}{' '}
                            per year —{' '}
                            <i>a {isSeries || isSpecialSale ? '63%' : '57%'} annual discount compared to $29/month.</i>
                          </>
                        )}
                      </IconListItem>
                      <IconListItem>
                        <b>Annual rates that will never go up</b> — as long as you remain a member, your discounted
                        membership rate will be locked in.
                      </IconListItem>
                      <IconListItem>
                        <b>To support our mission</b> of healthy, ethical, sustainable food for all. In fact, for every
                        paid membership,{' '}
                        <i>
                          we’ll donate to Trees for the Future, enabling them to plant another organic fruit or nut tree
                          in a low-income community...
                        </i>
                      </IconListItem>
                    </IconList>
                  </Col>
                </Row>
              </Container>
            </Section>

            <Section id="" color="white" innerClass="text-center">
              <Container>
                <Row className="align-items-center justify-content-center mb-5">
                  <Col xs="8" sm="6" lg="3" className="mb-3 mb-lg-0">
                    <img
                      className="rounded-circle"
                      src={require('static/john-lennon-headshot.jpg')}
                      style={{maxHeight: '200px'}}
                    />
                  </Col>
                  <Col xs="12" lg="auto" className="text-lg-left">
                    <h3 className="section-heading mb-3">
                      <i>“Life is what happens while you’re busy making other plans.”</i>
                    </h3>
                    <h4 className="m-0">— John Lennon</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="balance-text">
                      “Life happens.” And, usually, the first thing to get derailed when it does is your healthy meals.
                    </p>
                    <p className="balance-text">
                      You <em>run out of time,</em> or you <em>eat for comfort</em> and snack the day away, or you just{' '}
                      <em>don’t feel inspired</em> to make the next meal meaningful. Sound familiar?
                    </p>
                    <p className="balance-text">
                      That’s why I want to personally invite you to take the next step and join WHOLE Life Club now.
                    </p>
                    <p className="balance-text">
                      When you join WHOLE Life Club, you’ll get <b>instant support</b> to help you easily and
                      successfully <b>implement, optimize,</b> and <b>LOVE</b> your <b>healthy living lifestyle.</b>
                    </p>
                  </Col>
                </Row>
              </Container>
            </Section>
            <CtaSection
              id="salesbox-3"
              className="section-angle-last"
              title={
                <>
                  <h2 className="section-heading text-white text-center mb-4">
                    Join WHOLE Life Club & Support Our Mission
                  </h2>
                  <TreesForTheFuture />
                </>
              }
              angle="none"
            />
          </>
        ) : null}
      </div>

      <Footer />
      <ShareButtons floating />
      {isSeries ? <ModalExitWelcome /> : null}
      <WaitListForm />
    </div>
  );
}
