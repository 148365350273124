import React, {useCallback, useState, useEffect} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import Modal from 'components/Modal';
import {Link, animateScroll as scroll} from 'react-scroll';
import useExitIntent from 'utils/useExitIntent.js';
import {TestimonialText} from 'components/Testimonial';
import IconList, {IconListItem} from 'components/Elements/IconList';

export default function ModalExitWelcome(props) {
  const {isOpen, toggle} = useExitIntent(30000);
  return (
    <Modal className="modal-exit-intent modal-exit-intent-welcome" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h3 className="section-heading">
            This little popup <br className="d-none d-xl-block" />
            has a BIG message…
          </h3>
          <p>You’re about to miss out on WHOLE Life Club!</p>
          <IconList className="icon-list-small">
            <IconListItem>
              6 delicious <b>plant-powered recipes</b> per week
            </IconListItem>
            <IconListItem>
              <b>Weekly videos</b> from Ocean Robbins
            </IconListItem>
            <IconListItem>
              <b>Monthly Action Hours</b> with health revolutionaries
            </IconListItem>
            <IconListItem>
              <b>Action Checklists</b> to keep you on track
            </IconListItem>
            <IconListItem>
              A supportive, moderated <b>community</b>
            </IconListItem>
            <IconListItem>
              Access to <b>Health Coaches & Dietitians</b>
            </IconListItem>
            <IconListItem>
              <b>And much more</b>
            </IconListItem>
          </IconList>
          <p>
            Doors only open twice a year, so don’t <br className="d-none d-xl-block" />
            leave without checking it out.
          </p>
          <Button
            className="btn-exit text-uppercase text-white text-h5"
            color="cta"
            onClick={toggle}
            block
            activeClass="active"
            spy={true}
            offset={-55}
            to="salesbox-1"
            smooth={true}
            href="#"
            tag={Link}
          >
            Learn More
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div className="exit-modal-image background-image-welcome-exit" />
        </Col>
      </Row>
    </Modal>
  );
}
