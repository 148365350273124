import React, {useEffect, useState} from 'react';
import PubNub from 'pubnub';
import axios from 'axios';
import {usePhaseDispatch} from 'funnel-schedule/phase-context';

const {SCHEDULE_URL} = process.env;
const {REACT_APP_PUBNUB_SUBSCRIBE_KEY} = process.env;
const {REACT_APP_PUBNUB_CHANNEL} = process.env;

export default function usePubNubSubscribe() {
  const phaseDispatch = usePhaseDispatch();
  const [messageReceived, setMessageReceived] = useState();
  const pubnub = new PubNub({
    subscribeKey: REACT_APP_PUBNUB_SUBSCRIBE_KEY
  });

  // HISTORY set on mount
  useEffect(() => {
    pubnub.history(
      {
        channel: REACT_APP_PUBNUB_CHANNEL,
        count: 1, // how many items to fetch
        stringifiedTimeToken: true // false is the default
      },
      (status, response) => {
        // handle status, response
        if (response && response.messages) {
          console.log('history responses ', response.messages, response.messages[0].entry.object);
          const lastMessage = response.messages[0].entry.object;
          setMessageReceived({...lastMessage, fromHistory: true});
        } else {
          console.log('NO history responses ', response);
        }
      }
    );
  }, []);

  // SUBSCRIBE and update state with messages
  useEffect(() => {
    const listener = pubnub.addListener({
      status(statusEvent) {
        if (statusEvent.category === 'PNConnectedCategory') {
        }
      },
      message(msg) {
        setMessageReceived(msg.message.object);
      }
    });
    pubnub.subscribe({
      channels: [REACT_APP_PUBNUB_CHANNEL]
    });
    return () =>
      pubnub.unsubscribe({
        channels: [REACT_APP_PUBNUB_CHANNEL]
      });
  }, []);

  // PAYLOAD FROM API IS
  // current payloads: {default: {phase: "before", webinar_id: 1}}

  // STRUCTURE OF STATE IN REDUCER:
  // {"phaseData":{"phases":{"default":{"phase":"before"}}}}         'default' is really 'sales', and it is a value of 'context' now.   'default', 'broadcast', etc

  /* STRUCTURE OF PUBNUB MESSAGE:

    {
        "commandType": "PHASE_DISPATCH",      //command for what to do, use reducer or REFRESH browser

            "reducerType": "UPDATE_PHASE",    //the command for the reducer
            "payload": {
                "schedule": "broadcast",
                "data": {
                    "url": "https://youtube.com",
                    "phase":"before",
                    "webinar_id":1
                }
            }

    }
*/

  // This is listening for a change in the messageReceived which is only updated by PubNub subscribe
  // With a new messageReceived we follow through with the command given in the message
  useEffect(() => {
    console.log('message received in PubNub commponent useeffect: ', messageReceived);
    if (messageReceived && messageReceived.commandType) {
      // {default: {phase: "before", webinar_id: 1}}
      const convertedToFunnelTalk = {[messageReceived.payload.schedule]: messageReceived.payload.data};
      console.log('converToFunnelTalk ', convertedToFunnelTalk);
      switch (messageReceived.commandType) {
        case 'PHASE_DISPATCH': {
          console.log('type :', messageReceived.reducerAction, 'payloadL', convertedToFunnelTalk);
          return phaseDispatch({
            type: messageReceived.reducerAction, // SET_PHASE, etc
            payload: convertedToFunnelTalk // this is merged into th.e schedule object {default:{...}, broadcast:{..}}
          });
        }
        case 'BROWSER_REFRESH': {
          if (messageReceived.fromHistory) {
            // do nothing.  prevent infinite loop
            return;
          }
          console.log('messsageReceived ', messageReceived.fromHistory);
          // browser refresh
          // @ts-ignore
          location.reload('forceGet'); // forceGet is boolean for not using the cache
        }
        case 'SCHEDULE_PULL': {
          //  schedulePull();  //tried this but I think the context is lost.  Maybe TODO
          axios.get(SCHEDULE_URL).then(({data}) => {
            console.log('schedul url ', SCHEDULE_URL, 'data ', data);
            return phaseDispatch({
              type: 'SET_PHASE',
              payload: data
            });
          });
        }
      }
    }
  }, [messageReceived]);
}
