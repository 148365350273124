import React from 'react';
import SalesIndex from '../whole';
import {usePhaseState} from 'funnel-schedule/phase-context';

export default function () {
  const {
    phases: {workshop: workshopSchedule = {phase: 'before'}}
  } = usePhaseState();
  const workshopPhase = workshopSchedule.phase;
  return <SalesIndex version={workshopPhase === 'after' ? 'salesFull' : 'workshop'} />;
}
