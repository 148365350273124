import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {FacebookProvider, Comments} from 'react-facebook';

export default function FacebookCommentFeed(props) {
  return (
    <div className="facebook-comments-feed">
      <Container className={props.containerClass} fluid>
        <Row>
          <Col xs="12">
            <FacebookProvider appId="1685199371775570">
              <Comments
                href={props.url}
                width="100%"
                numPosts={props.numPosts}
                orderBy="reverse_time"
                key={props.commentsKey}
              />
            </FacebookProvider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

FacebookCommentFeed.defaultProps = {
  url: 'http://wlc.foodrevolution.org/broadcasts/',
  numPosts: '5'
};
