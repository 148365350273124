import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import CheckoutButton from 'components/CheckoutButton';
import './Guarantee.scss';
import {usePhaseState} from 'funnel-schedule/phase-context';
import IconList, {IconListItem} from 'components/Elements/IconList';

export function GuaranteeBox({title, children, ...props}) {
  return (
    <div className={classNames(`guarantee-box rounded background-white box-shadow-black-50`, props.className)}>
      <h2 className="section-heading guarantee-heading text-center text-sm-left">{title}</h2>
      {children}
    </div>
  );
}

export default function Guarantee(props) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: ''
          }
        ]
      },
      sales: salesSchedule = {
        phase: 'before',
        salesEndDate: ''
      }
    }
  } = usePhaseState();

  const isSeries = defaultSchedule.actionHours.length > 1;
  const isSpecialSale = salesSchedule.phase === 'during';

  return (
    <div
      className={classNames(`guarantee-box rounded background-${props.bgColor} box-shadow-black-50`, props.className)}
    >
      <h2 className="section-heading guarantee-heading text-center text-sm-left">
        Our 60-Day Better Than Money Back&nbsp;
        <br className="d-none d-md-block" />
        Risk-Free Guarantee
      </h2>
      <p>Take up to 60 days to see what WHOLE Life Club is all about.</p>
      <p className="mb-4">We guarantee that you and your family will:</p>
      <IconList>
        <IconListItem>
          Have the tools and information to improve your health right away, helping you eat better and enjoy more
          vitality.
        </IconListItem>
        <IconListItem>Feel empowered knowing the truth about the foods you eat.</IconListItem>
        <IconListItem>Make meaningful progress towards YOUR healthy eating goals.</IconListItem>
      </IconList>
      <p>
        Your satisfaction is important to us! If for some reason you aren’t 100% thrilled with your purchase, you have
        60 days to request a prompt, total refund. Email us at{' '}
        <a className="link-black text-underline" href="mailto:support@foodrevolution.org">
          support@foodrevolution.org
        </a>{' '}
        or phone us at{' '}
        <a className="link-black text-underline" href="tel:8318244779">
          (831) 824-4779
        </a>
        . You can also hit reply to any email we send you with your request.
      </p>
      <p className="mb-0">
        If for any reason you request a refund, everything you've already downloaded is still yours to keep. That’s why
        we call our guarantee “risk-free!”
      </p>
    </div>
  );
}

Guarantee.defaultProps = {
  bgColor: 'white',
  borderColor: 'light-gray'
};
