import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {usePhaseState} from 'funnel-schedule/phase-context';
import './LandingTimerSection.scss';
import ShowIf from 'funnel-schedule/ShowIf';
import classNames from 'classnames';
import CountdownTimer from '../../Elements/CountdownTimer';

export default function LandingTimerSection(props) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: ''
          }
        ]
      }
    }
  } = usePhaseState();
  const isSeries = defaultSchedule.actionHours.length > 1;
  const currentActionHour = defaultSchedule.currentActionHour;
  const notFinalActionHour = currentActionHour !== 2;
  const time = new Date(defaultSchedule.actionHours[defaultSchedule.currentActionHour].date).getTime();

  return (
    <div className={classNames(`section-countdown text-center text-white`, props.className, props.timerClassName)}>
      <Container fluid>
        <Row className="align-items-center">
          <Col>
            {isSeries && defaultSchedule.phase === 'before' && currentActionHour === 0 ? (
              <div className="timer">
                <h4 className="timer-heading">The Action Hour Series Starts In:</h4>
                <CountdownTimer time={time} />
              </div>
            ) : (isSeries && defaultSchedule.phase === 'during') ||
              (isSeries && defaultSchedule.phase === 'after' && notFinalActionHour) ? (
              <div className="section-countdown-heading-live text-uppercase">
                The Action <br className="d-none d-sm-block d-md-none" />
                Hour Series <br className="d-none d-lg-block" />
                is Happening Now!
              </div>
            ) : isSeries && defaultSchedule.phase === 'after' && currentActionHour === 2 ? (
              <div className="section-countdown-heading-replay text-uppercase">
                The Action Hour <br className="d-none d-sm-block" />
                Series is on Replay
              </div>
            ) : (
              <div className="section-countdown-heading-demand text-uppercase">
                The Action Hour <br className="d-none d-sm-block" />
                is on Demand
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
