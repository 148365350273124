import React, {Fragment, useCallback, useState} from 'react';
import classNames from 'classnames';
import {Col, Container, Row, Button} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import ReactMarkdown from 'react-markdown';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import {FrnHeader} from 'components/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import CountdownTimer from 'components/Elements/CountdownTimer';
import {OptinModalTrigger, OptinModalWorkshop} from 'components/OptinModal';
import {ScrollingVideo} from 'components/Elements/HeaderVideo';
import {FaqListFull, ListFaq} from 'components/FaqList';
import {ListIcon} from 'components/Elements/IconList';
import OptinFormWorkshop from 'components/OptInFormWorkshop';
import Modal, {ExitModal} from 'components/Modal';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Footer';

//@ts-ignore
import {workshopSpeakers, workshopSpeakerList, workshopHostList, workshopFaq} from 'data/wlc-workshop.json';

export default function WorkshopIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = 'default',
  entry = 'default',
  ...props
}) {
  const pageTitle =
    'Join the Thriving on a Plant-Based Diet: 10 Tips for Maximizing Your Health and Happiness Workshop';
  const pageDescription =
    'Get actionable insights from world-leading experts on how to optimize your health with a plant-based diet. Join this free live workshop and start your journey towards better health and happiness!';
  const shareUrl = 'https://wlc.foodrevolution.org/workshop/';
  const ogImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-facebook-202405.png';
  const pinterestImage = 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-opengraph-pinterest-202405.png';
  const facebookDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const twitterDescription =
    'Unlock the secrets to a healthier life with a plant-based diet! Join our free live workshop featuring expert advice from renowned nutritionists and doctors. Register now and kickstart your journey to better health! #FoodRevolution';
  const pinterestDescription =
    'Join us in this free live workshop to discover the benefits of a plant-based diet! Get practical tips from nutrition experts to improve your health and happiness. Register now for free and take your first step towards a healthier you!';

  const {
    phases: {
      workshop: workshopSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const phase = workshopSchedule.phase;
  const registrationEndTime = new Date(workshopSchedule.registrationEndTime).getTime();
  const timerVisible = new Date(workshopSchedule.registrationEndTime) > new Date();

  function Timer({className = null, timerClass = undefined}) {
    return (
      <div className={classNames(`timer`, className)}>
        <h6 className="section-heading mb-2">Registration closes in:</h6>
        <CountdownTimer time={registrationEndTime} className={timerClass} />
      </div>
    );
  }

  function OptinTrigger({btnText = 'Join Now'}) {
    return <OptinModalTrigger>{btnText}</OptinModalTrigger>;
  }

  useBalanceText();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />
      <div className="page-content">
        <FrnHeader
          shareUrl={shareUrl}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          bgColor="purple"
          logoUrl="https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-logo-white.svg"
          logoAlt="WHOLE Life Club Workshop logo"
          logoWidth={300}
          logoHeight={132}
        />
        <Section
          id="wlclc-landing-header"
          color="light-gray"
          className="section-angle-first section-angle-both-offset background-cover background-fixed background-image-1222380854"
          innerClass="pt-0"
          angle="none"
        >
          <div className="background-black-90 text-white text-center py-4">
            <Container>
              <Row>
                <Col>
                  <p className="text-h5 mb-0">
                    <i>Free to all!</i>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row>
              <Col>
                <div className="optin-box background-white rounded box-shadow-black-50 text-center p-4 p-lg-5 mt-5">
                  <h1 className="section-heading text-h2 mb-2">Thriving on a Plant-Based Diet</h1>
                  <h2 className="text-h3 mt-0 mb-3">
                    10 Tips for Maximizing <i>Your</i> Health and Happiness
                  </h2>
                  <h5 className="mt-0 mb-3">A free WHOLE Life Club Live Workshop</h5>
                  <p className="mb-1">
                    <b>June 1, 2024</b>
                  </p>
                  <p className="mb-4">
                    <b>12–3 pm ET (9–12 PT, 4–7 pm GMT)</b>
                  </p>
                  <OptinFormWorkshop
                    entry={entry}
                    navigateUrl={entry === 'wlcCustomer' ? '/workshop/wlc/welcome/' : '/workshop/welcome/'}
                    formHeader={
                      <p className="balance-text text-center">Enter your email below to claim your free spot!</p>
                    }
                    btnText="Register Now!"
                  />
                  {timerVisible && <Timer timerClass="countdown-dark" className="mt-4" />}
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section id="wlclc-landing-video" color="white" angle="both">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <ScrollingVideo
                  bgColor="black"
                  noFixed={noFixedVideo}
                  video={{
                    url: 'https://www.youtube.com/watch?v=pwHSNpOshvI',
                    label: 'WLC-LW - Landing',
                    thumbnail: 'https://cdn.foodrevolution.org/wlc/workshop/wlc-lw-thumb-landing-240514.gif',
                    noButton: true
                  }}
                  text={
                    <>
                      <p>
                        <b>
                          JOIN US AT THE Thriving on a Plant-Based Diet: 10 Tips for Maximizing Your Health and
                          Happiness LIVE WORKSHOP!
                        </b>
                      </p>
                      <OptinTrigger />
                    </>
                  }
                />
              </Col>
            </Row>
          </Container>
        </Section>
        <SectionImage
          id="wlclc-landing-makeithappen"
          color="light-gray"
          imgPosition="right"
          imgUrl="https://cdn.foodrevolution.org/wlc/images/1412688011-square.jpg"
        >
          <h3 className="section-heading">Make it happen for YOU!</h3>
          <p>
            Tens of thousands of studies published in peer-reviewed medical journals make it clear: A healthy diet and
            lifestyle can slash your risk of Alzheimer’s, cardiovascular disease, stroke, cancer, type 2 diabetes,
            autoimmune disease, and brain fog. It can add many vibrant and healthy years to your life and give you more
            energy, deeper sleep, and more satisfaction.
          </p>
          <p>
            So how can you take advantage of the latest research and put the learnings into action for real results you
            can actually feel?
          </p>
          <p>
            Join this free three-hour live workshop to get cutting-edge insights and useful tools you can implement{' '}
            <i>right away.</i>
          </p>
        </SectionImage>
        <Section id="wlclc-landing-experts" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center">
                  Optimize your health with practical tips from world-leading experts…
                </h2>
              </Col>
            </Row>
            <Row className="align-items-stretch justify-content-center text-center">
              {workshopSpeakerList.map((item, i) => {
                return (
                  <Fragment key={item + i}>
                    {workshopSpeakers
                      .filter((speaker) => speaker.id === item.id)
                      .map((speaker) => {
                        const [isOpen, setIsOpen] = useState(false);
                        const onClick = useCallback(() => setIsOpen(true), []);
                        const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
                        return (
                          <Col key={'speaker' + i} xs="6" lg="4" xl="2" className="mb-4 d-flex flex-column">
                            <img className="box-shadow-black-25 rounded mb-3" src={speaker.image.url} />
                            <h6 className="text-small mt-0 mb-3">{speaker.name}</h6>
                            {speaker.bio && (
                              <div className="mt-auto">
                                <Button color="green" onClick={onClick} className="text-small text-white" block>
                                  Learn More
                                </Button>
                                <Modal isOpen={isOpen} toggle={toggle} size="lg">
                                  <Container className="px-sm-4 pt-0 pb-5" fluid>
                                    <Row className="d-flex justify-content-center">
                                      <Col xs="10" lg="4" className="mb-4 mb-lg-0">
                                        <LazyLoad height={300} offset={100}>
                                          <img className="box-shadow-image rounded" src={speaker.image.url} />
                                        </LazyLoad>
                                      </Col>
                                      <Col xs="12" lg="8">
                                        <div className="speaker-details">
                                          <h3 className="speaker-name section-heading mb-2">{speaker.name}</h3>
                                          <ReactMarkdown className="speaker-description" source={speaker.bio} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Modal>
                              </div>
                            )}
                          </Col>
                        );
                      })}
                  </Fragment>
                );
              })}
            </Row>
          </Container>
        </Section>
        <Section id="wlclc-landing-discover" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">
                  {
                    {
                      default: <>In this content-rich LIVE workshop, you’ll discover:</>,
                      ad: <>In this content-rich LIVE workshop, we’ll share:</>
                    }[variant]
                  }
                </h3>
                <ListIcon>
                  <>
                    What the latest research tells us about lifestyle choices that can prevent — and often reverse —
                    disease.
                  </>
                  <>Simple shifts to kick off a healing transformation.</>
                  <>
                    The foods proven to increase lifespan, improve heart health, starve cancer, slash the risk of
                    dementia, decrease type 2 diabetes markers, and much more.
                  </>
                  <>New behavioral and lifestyle hacks that can help overcome cravings, food addictions, and more.</>
                  <>The essentials of a plant-based kitchen and how to create delicious, nourishing meals.</>
                  <>The common pitfalls of a plant-based diet… and how to overcome them.</>
                  <>Eye-opening facts about food that will jumpstart new healthy habits — for life! </>
                  <>
                    Insights from <i>New York Times</i> bestselling authors, researchers, doctors, and world-renowned
                    natural health experts as they share how to protect your health.
                  </>
                  <>Recipes and a recipe demo from our in-house recipe guru and plant-based nutritionist!</>

                  {entry !== 'wlcCustomer' && (
                    <>
                      And if you want to go further — you’ll get an invitation to join FRN’s thriving membership
                      community, WHOLE Life Club, with a special trial offer.
                    </>
                  )}
                </ListIcon>
                <div className="text-center mt-5">
                  <OptinTrigger btnText="Register for the Free Workshop" />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section id="wlclc-landing-faq" color="white">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Frequently Asked Questions</h3>
                <FaqListFull faqlist={workshopFaq} />
              </Col>
            </Row>
          </Container>
        </Section>
        <SectionImage
          id="wlclc-landing-cta-final"
          color="purple"
          imgPosition="left"
          imgUrl="https://cdn.foodrevolution.org/wlc/images/1649298438-square.jpg"
          className="landing-final-cta section-angle-last text-center text-white section-noangle"
          angle="none"
        >
          <h3 className="section-heading text-uppercase">Want a Blueprint for Thriving?</h3>
          <h4 className="mt-0">
            JOIN US AT THE Thriving on a Plant-Based Diet: 10 Tips for Maximizing <i>Your</i> Health and Happiness LIVE
            WORKSHOP!
          </h4>
          <OptinTrigger />
          {entry != 'wlcCustomer' && (
            <p className="balance-text text-small mt-4">
              <i>
                The event is TOTALLY FREE. And if you want to go further — you’ll get an invitation to join FRN’s
                thriving membership community, WHOLE Life Club, with a special trial opportunity.
              </i>
            </p>
          )}
        </SectionImage>
      </div>
      {/*!noExitIntent && <ModalExitWorkshop />*/}
      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons
          shareUrl={shareUrl}
          shareImage={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          floating
        />
        <OptinModalWorkshop
          entry={entry}
          navigateUrl={entry === 'wlcCustomer' ? '/workshop/wlc/welcome/' : '/workshop/welcome/'}
          formHeader={
            <p className="text-center">
              Enter your email below to join the{' '}
              <b>
                Thriving on a Plant-Based Diet: <br className="d-none d-xl-block" />
                10 Tips for Maximizing <i>Your</i> Health and Happiness Live Workshop
              </b>
            </p>
          }
        />
      </div>
      {!noExitIntent && (
        <ExitModal
          exitDelay={30000}
          content={
            <>
              <h5 className="section-heading">Wait! Don’t Miss Our Free Live Workshop!</h5>
              <p>Unlock health secrets and expert tips at the Thriving on a Plant-Based Diet workshop.</p>
              <ListIcon>
                <>Expert advice</>
                <>Live Q&A</>
                <>Replay access</>
              </ListIcon>
              <p>Register Now to transform your health!</p>
            </>
          }
          image={{
            url: 'https://cdn.foodrevolution.org/wlc/workshop/1167771085-exit.jpg',
            alt: 'man wearing purple sweatshirt standing over isolated pink background waiving',
            width: 1000,
            height: 800
          }}
          positiveButtonText="Yes, Sign Me Up"
          positiveOptin
          negativeButtonText="No Thanks"
        />
      )}
    </div>
  );
}
