import React, {useState} from 'react';
import {ModalBody, Button} from 'reactstrap';
import classNames from 'classnames';
import ContactInfoForm from './components/ContactInfoForm';
import Modal from 'components/Modal';
import {useModal} from 'contexts/modal';

import './WaitListForm.scss';

export default function WaitListForm({id = 'waitListForm', ...props}) {
  const [step, setStep] = useState(0);
  const modalProps = useModal();
  const onStepComplete = React.useCallback((step) => step === 1, []);
  const toggle = React.useCallback(() => {
    modalProps.toggle();
  }, []);
  return (
    <Modal {...props} isOpen={modalProps.isOpen} toggle={toggle} size="lg">
      <ModalBody className="px-4 pt-0 pb-4">
        {(() => {
          switch (step) {
            default:
            case 0:
              return (
                <ContactInfoForm
                  onComplete={() => {
                    setStep(1);
                    onStepComplete(1);
                  }}
                  id={id}
                />
              );
            case 1:
              return (
                <div className="text-center py-4">
                  <h4 className="section-heading">
                    Thank you for signing up to be <br className="d-none d-sm-block d-lg-none" />
                    notified the next time we open <br className="d-none d-sm-block" />
                    WHOLE Life Club to welcome new members. We will be in touch!
                  </h4>
                  <Button
                    className={classNames(
                      `btn-size-350 text-uppercase text-white text-condensed text-800`,
                      props.className
                    )}
                    color="upsell-gray"
                    size="lg"
                    onClick={modalProps.toggle}
                  >
                    Close
                  </Button>
                </div>
              );
          }
        })()}
      </ModalBody>
    </Modal>
  );
}

export function WaitListFormTrigger({btnText = 'Signup Here', ...props}) {
  const modalProps = useModal();
  return (
    <Button
      className={classNames(`btn-size-350 text-uppercase text-white text-condensed text-800`, props.className)}
      color="cta"
      size="lg"
      onClick={modalProps.toggle}
      {...props}
    >
      {btnText}
    </Button>
  );
}
