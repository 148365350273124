import * as Sentry from '@sentry/browser';
import axios from 'axios';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import qs from 'qs';
const {SF_API_URL} = process.env;
const {SITE_ID} = process.env;

export function submitForm(values, form_id) {
  if (form_id) values.form_id = form_id;
  values.site_id = SITE_ID || '';
  try {
    if (window.analytics && window.analytics.user) {
      const ajs_user = window.analytics.user();
      if (ajs_user) {
        ajs_user.id && (values.ajs_userId = ajs_user.id());
        ajs_user.anonymousId && (values.ajs_anonymousId = ajs_user.anonymousId());
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }
  try {
    if (Cookies) {
      ['aff_', 'opid', 'sid', 'utm_campaign', 'utm_source', 'utm_content', 'utm_medium', 'utm_term'].map(
        (key) => Cookies.get(key) && (values[`cookie_${key}`] = Cookies.get(key))
      );
    }
  } catch (e) {
    Sentry.captureException(e);
  }
  try {
    const qParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    ['orid', 'opid', 'sid', 'utm_campaign', 'utm_source', 'utm_content', 'utm_medium', 'utm_term'].map(
      (key) => qParams[key] && (values[key] = qParams[key])
    );
  } catch (e) {
    Sentry.captureException(e);
  }
  const formData = queryString.stringify(values);
  return axios.post(`${SF_API_URL}/form`, formData).catch((error) => {
    if (error.response.data && error.response.data.code === -4)
      throw 'Invalid email address. Please check your address, try another address or contact us at support@foodrevolution.org.';
    Sentry.withScope((scope) => {
      scope.setExtras({response: error.response, request: error.request});
      Sentry.captureException(error);
    });
    if (error.response) {
      throw 'An error occurred while trying to submit your request, please check your entries and try again.';
    } else if (error.request) {
      throw 'There was a problem communicating with our servers, please check your internet connection and try again.';
    }
  });
}
