import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';
import {DateTime} from 'luxon';
import ReactMarkdown from 'react-markdown';
import Modal from 'components/Modal';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-light-svg-icons';
import {days, speakers, actionHourSpeakers} from 'data/speaker.json';
import {acSpeakers} from 'data/ac18.json';
import {OptinModalTrigger} from 'components/OptinModal';
import './SpeakerList.scss';
import {usePhaseState} from 'funnel-schedule/phase-context';
import AudioPlayer from 'components/Elements/AudioPlayer';
import {SectionImage} from 'components/Elements/Section';

function Speaker({cols, id, modalLink, name, shortName, presentation, description, image, bio}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  return (
    <Col className={classNames('speaker-item mb-3', id, cols)}>
      <Row className="speaker-list-item-row row d-flex align-items-stretch justify-content-center h-100">
        <Col xs="12" className="h-100">
          <div className="text-center d-flex flex-column h-100">
            {!modalLink ? (
              <a className="speaker-list-item-link mb-2" onClick={onClick}>
                <LazyLoad height={245} offset={100}>
                  <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
                </LazyLoad>
              </a>
            ) : (
              <div className="speaker-list-item-image mb-2">
                <LazyLoad height={245} offset={100}>
                  <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
                </LazyLoad>
              </div>
            )}
            <div className="speaker-details my-auto">
              <p className="speaker-name text-700 mb-0">{name}</p>
              <p className="speaker-presentation text-small mb-0">{presentation}</p>
            </div>
            {!modalLink ? (
              <p className="mt-3 mb-0">
                <Button block color="green" className="text-small text-white" onClick={onClick}>
                  Learn More
                </Button>
                <Modal isOpen={isOpen} toggle={toggle} size="lg">
                  <Container className="p-4" fluid>
                    <Row className="d-flex align-items-center justify-content-center mb-4">
                      <Col xs="8" lg="4" className="mb-4 mb-lg-0">
                        <LazyLoad height={245} offset={100}>
                          <img
                            className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle"
                            src={image}
                          />
                        </LazyLoad>
                      </Col>
                      <Col xs="12" lg="8">
                        <div className="speaker-details text-center text-lg-left">
                          <h1 className="speaker-name">{name}</h1>
                          <h4 className="speaker-presentation">{presentation}</h4>
                          <p className="speaker-description mb-0">{description}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="speaker-bio-info">
                          <h5 className="speaker-bio-heading">More About {shortName}</h5>
                          <ReactMarkdown source={bio || '### Testing'} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal>
              </p>
            ) : null}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

Speaker.defaultProps = {
  cols: 'col-6 col-sm-4 col-lg-3'
};

function SpeakerInfo({cols, id, modalLink, name, shortName, presentation, description, image, bio}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  return (
    <Col className={classNames(`speaker-info speaker-${id}`, cols)}>
      <Row className="speaker-info-row">
        <Col xs="12" className="speaker-info-col">
          <div className="speaker-info-wrap">
            <div className="speaker-info-image mb-md-2">
              {!modalLink ? (
                <Button className="speaker-list-item-link" color="img" onClick={onClick}>
                  <LazyLoad height={245} offset={100}>
                    <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
                  </LazyLoad>
                </Button>
              ) : (
                <div className="speaker-list-item-image">
                  <LazyLoad height={245} offset={100}>
                    <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
                  </LazyLoad>
                </div>
              )}
            </div>
            <div className="speaker-info-details my-auto">
              <p className="speaker-name text-700 mb-0">{name}</p>
              <p className="speaker-presentation text-small mb-0">{presentation}</p>
            </div>
            {!modalLink ? (
              <div className="speaker-info-more">
                <div className="mt-md-2 mb-0">
                  <Button className="speaker-info-btn" color="speaker-info" onClick={onClick} block>
                    <FontAwesomeIcon className="speaker-info-btn-icon d-md-none" icon={faInfoCircle} />
                    <span className="d-none d-md-inline">Learn&nbsp;</span>
                    More
                  </Button>
                </div>
                <Modal isOpen={isOpen} toggle={toggle} size="lg">
                  <Container className="px-sm-4 pt-0 pb-4" fluid>
                    <Row className="d-flex align-items-center justify-content-center mb-4">
                      <Col xs="10" lg="4" className="mb-4 mb-lg-0">
                        <LazyLoad height={245} offset={100}>
                          <img
                            className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle"
                            src={image}
                          />
                        </LazyLoad>
                      </Col>
                      <Col xs="12" lg="8">
                        <div className="speaker-details">
                          <h3 className="speaker-name section-heading mb-2">{name}</h3>
                          <h5 className="speaker-presentation mt-0">{presentation}</h5>
                          <p className="speaker-description mb-0">{description}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="speaker-bio-info">
                          <h5 className="speaker-bio-heading section-heading">More About {shortName}</h5>
                          <ReactMarkdown source={bio || ''} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

SpeakerInfo.defaultProps = {
  cols: 'col-12 col-md-4 col-lg-3'
};

export default function SpeakerList({modalLink, cols}) {
  // Very basic, usually (but not always!) in the order of the JSON file
  return speakers.map((speaker) => <Speaker cols={cols} modalLink={modalLink} {...speaker} key={speaker.id} />);
}

export function SpeakerListInfo({modalLink, cols}) {
  // Very basic, usually (but not always!) in the order of the JSON file
  return speakers.map((speaker) => <SpeakerInfo cols={cols} modalLink={modalLink} {...speaker} key={speaker.id} />);
}

export function SpeakersForDate({date, cols}) {
  // Show only speakers for date, Note: matching dates by string is very bad, should use date library
  return speakers
    .filter((speaker) => speaker.date.includes(date))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((speaker) => <SpeakerInfo cols={cols} {...speaker} key={speaker.id} />);
}

export function SpeakersForBroadcastDay({day = 0, cols}) {
  const {date} = days[day];
  const {number} = days[day];
  const {title} = days[day];
  const {theme} = days[day];
  return (
    <>
      <div className="broadcast-day-heading text-center mb-4">
        <h2 className="section-heading mb-3">
          Day {number} - {title}
        </h2>
        <h4 className="text-400 my-0">{theme}</h4>
      </div>
      <Row className="d-flex justify-content-center">
        <SpeakersForDate cols={cols} date={date} />
      </Row>
    </>
  );
}

export function SpeakersForBroadcastTomorrow({day = 0, cols}) {
  if (day + 1 >= days.length) return null;
  const {date} = days[day + 1];
  const {number} = days[day + 1];
  const {title} = days[day + 1];
  const {theme} = days[day + 1];
  return (
    <>
      <div className="broadcast-day-heading text-center mb-4">
        <h2 className="section-heading mb-3">
          Day {number} - {title}
        </h2>
        <h4 className="text-400 my-0">{theme}</h4>
      </div>
      <Row className="d-flex justify-content-center">
        <SpeakersForDate cols={cols} date={date} />
      </Row>
    </>
  );
}

export function SpeakersGroupByDay(props) {
  return days.map(({date, title, theme}) => (
    <Col xs="12" lg="10" xl="6" className="d-flex align-items-stretch flex-column">
      <div className="background-light-gray text-center p-3 rounded mb-4">
        <h4 className="section-heading mb-1">
          {props.noDate ? null : <>{DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MMMM d')} -</>} {title}
        </h4>
        <p className="speaker-day-theme mb-0">{theme}</p>
      </div>
      <Row className="d-flex align-items-stretch justify-content-center h-100">
        <SpeakersForDate cols="col-12 col-md-4" date={date} />
      </Row>
    </Col>
  ));
}

export function SpeakerReplay({id, name, shortName, presentation, description, image, bio, url, audio, ...props}) {
  return (
    <div className={classNames(`speaker-replay speaker-${id}`)}>
      <Container>
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs="10" sm="8" lg="4" className="text-center mb-4 mb-lg-0">
            <LazyLoad height={245} offset={100}>
              <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle" src={image} />
            </LazyLoad>
          </Col>
          <Col lg="8" className="text-center">
            <div className="speaker-name">{name}</div>
            <div className="speaker-presentation">{presentation}</div>
            <div className="speaker-description">{description}</div>

            {props.noAudio ? null : <AudioPlayer url={audio} />}

            <Row className="d-flex align-items-center justify-content-center">
              <Col xl="6" className="mb-2 mb-xl-0">
                <Button
                  className="btn-speaker-replay text-white text-uppercase"
                  color="green"
                  href={url}
                  target="_blank"
                  block
                >
                  Visit {shortName}’s Website
                </Button>
              </Col>
              <Col xl="6" className="pl-xl-0">
                <Button
                  className="btn-speaker-replay text-white text-uppercase"
                  color="cta"
                  href="/empowerment/"
                  target="_blank"
                  block
                >
                  Buy The Empowerment Package
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export function SpeakersForReplay({date, ...props}) {
  // Show only speakers for date, Note: matching dates by string is very bad, should use date library
  return speakers
    .filter((speaker) => speaker.date.includes(date))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((speaker) => <SpeakerReplay {...speaker} key={speaker.id} {...props} />);
}

export function SpeakersForReplayDay({day = 0, ...props}) {
  const {date} = days[day];
  return <SpeakersForReplay date={date} {...props} />;
}

export function SpeakerHour({id, name, shortName, presentation, description, image, bio, date, ...props}) {
  const dateObj = DateTime.fromISO(date, {zone: 'America/Los_Angeles'});
  return (
    <div className={classNames(`speaker-hour speaker-${id}`)}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="10" sm="8" lg="3" className="text-center mb-4 mb-lg-0">
          <LazyLoad height={245} offset={100}>
            <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle" src={image} />
          </LazyLoad>
        </Col>
        <Col lg="9">
          <h3 className="speaker-name">{name}</h3>
          <h5 className="speaker-presentation">{presentation}</h5>
          <div className="speaker-date">
            {props.demand ? (
              <>
                {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} @{' '}
                {dateObj.setZone('America/Los_Angeles').toFormat('h a')} PT until the next month's Action Hour.
              </>
            ) : null}
          </div>
          <div className="speaker-description">
            <ReactMarkdown source={description || ''} />
          </div>
          <div className="speaker-hour-button">
            {props.beforeOpt ? <OptinModalTrigger /> : null}
            {props.liveOpt ? <OptinModalTrigger>Live Now</OptinModalTrigger> : null}
            {props.replayOpt ? <OptinModalTrigger>On Replay</OptinModalTrigger> : null}
            {props.demandOpt ? <OptinModalTrigger>On Demand</OptinModalTrigger> : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function SpeakersForHour({date, ...props}) {
  // Show only speakers for date, Note: matching dates by string is very bad, should use date library
  return speakers
    .filter((speaker) => speaker.date.includes(date))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((speaker) => <SpeakerHour {...speaker} date={date} key={speaker.id} {...props} />);
}

export function SpeakersForHourDay({day = 0, ...props}) {
  const {date} = days[day];
  return <SpeakersForHour date={date} {...props} />;
}

export function SpeakerBio({id, name, shortName, presentation, description, image, bio, date, ...props}) {
  const dateObj = DateTime.fromISO(date, {zone: 'America/Los_Angeles'});
  return (
    <SectionImage
      id={classNames(`section-host-speaker-${id}`)}
      className={classNames(`section-host-speaker section-host-speaker-${id}`, props.className)}
      imgPosition={props.imgPosition ? props.imgPosition : 'right'}
      backgroundPosition={props.backgroundPosition ? props.backgroundPosition : 'center-top'}
      color={props.overlayColor ? props.overlayColor : 'black'}
      imgMobile={image}
      imgDesktop={image}
    >
      <h3 className="speaker-bio-name section-heading">
        {name} — {presentation}
      </h3>
      <ReactMarkdown source={bio || ''} />
      <div className="speaker-bio-day mb-4">
        {props.demand ? (
          <>
            <strong>
              Learn from {shortName} on {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} @{' '}
              {dateObj.setZone('America/Los_Angeles').toFormat('h a')} PT until the next month's Action Hour.
            </strong>
          </>
        ) : null}
      </div>

      <div className="speaker-bio-button">
        {props.beforeOpt ? <OptinModalTrigger /> : null}
        {props.liveOpt ? <OptinModalTrigger>Live Now</OptinModalTrigger> : null}
        {props.replayOpt ? <OptinModalTrigger>On Replay</OptinModalTrigger> : null}
        {props.demandOpt ? <OptinModalTrigger>On Demand</OptinModalTrigger> : null}
      </div>
    </SectionImage>
  );
}

export function SpeakersForBio({date, ...props}) {
  // Show only speakers for date, Note: matching dates by string is very bad, should use date library
  return speakers
    .filter((speaker) => speaker.date.includes(date))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((speaker) => <SpeakerBio {...speaker} date={date} key={speaker.id} {...props} />);
}

export function SpeakersForBioDay({day = 0, ...props}) {
  const {date} = days[day];
  return <SpeakersForBio date={date} {...props} />;
}

export function WlcSpeakerListItem({
  speaker: {name, shortName, bio, image},
  title,
  date,
  description,
  isReplay = false,
  isLive = false,
  isSeries = false,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //

  const dateObj = DateTime.fromISO(date);
  return (
    <div className={classNames(`speaker-hour`)}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="10" sm="8" lg="3" className="text-center mb-4 mb-lg-0">
          <LazyLoad height={245} offset={100}>
            <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle" src={image} />
          </LazyLoad>
        </Col>
        <Col lg="9">
          <h3 className="section-heading speaker-title">{title}</h3>
          <h5 className="speaker-name">with {name}</h5>
          <div className="speaker-date">
            {isSeries ? (
              <>
                {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} @{' '}
                {dateObj.setZone('America/New_York').toFormat('h a')} ET /{' '}
                {dateObj.setZone('America/Los_Angeles').toFormat('h a')} PT
              </>
            ) : (
              <>
                {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} — Broadcast starting @ 12 am PT until
                the next month's Action Hour.
              </>
            )}
          </div>
          <div className="speaker-description">
            <ReactMarkdown source={description || ''} />
          </div>
          <div className="speaker-hour-button">
            <Row className="d-flex align-items-center">
              <Col xs="12" md="6" className="mb-3 mb-md-0">
                <OptinModalTrigger block>
                  {isReplay ? (isSeries ? 'On Replay' : 'On Demand') : isLive ? 'Live Now' : null}
                </OptinModalTrigger>
              </Col>
              <Col xs="12" md="6">
                <Button color="speaker-more" onClick={onClick} size="lg" block>
                  Learn More
                </Button>
                <Modal isOpen={isOpen} toggle={toggle} size="lg">
                  <Container className="px-sm-4 pt-0 pb-4" fluid>
                    <Row className="d-flex align-items-center justify-content-center mb-4">
                      <Col xs="10" lg="4" className="mb-4 mb-lg-0">
                        <LazyLoad height={245} offset={100}>
                          <img
                            className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle"
                            src={image}
                          />
                        </LazyLoad>
                      </Col>
                      <Col xs="12" lg="8">
                        <div className="speaker-details">
                          <h3 className="speaker-name section-heading mb-2">{name}</h3>
                          <h5 className="speaker-presentation mt-0 mb-2">{title}</h5>
                          <p className="text-16 mb-4">
                            <em>
                              {isSeries ? (
                                <>
                                  {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} @{' '}
                                  {dateObj.setZone('America/New_York').toFormat('h a')} ET /{' '}
                                  {dateObj.setZone('America/Los_Angeles').toFormat('h a')} PT
                                </>
                              ) : (
                                <>
                                  {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} — Broadcast
                                  starting @ 12 am PT until the next month's Action Hour.
                                </>
                              )}
                            </em>
                          </p>
                          <p className="speaker-description mb-0">
                            <ReactMarkdown source={description || ''} />
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="speaker-bio-info">
                          <h5 className="speaker-bio-heading section-heading">More About {shortName}</h5>
                          <ReactMarkdown source={bio || ''} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

function WlcSpeakerListBroadcastItem({
  cols,
  modalLink,
  speaker: {name, shortName, bio, image},
  title,
  date,
  description,
  speakerImageClass,
  isReplay = false,
  isLive = false,
  isSeries = false,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //

  const dateObj = DateTime.fromISO(date);
  return (
    <Col className={classNames(`speaker-info`, cols)}>
      <Row className="speaker-info-row">
        <Col xs="12" className="speaker-info-col">
          <div className="speaker-info-wrap">
            <div className="speaker-info-image mb-md-2">
              {!modalLink ? (
                <Button className="speaker-list-item-link" color="img" onClick={onClick}>
                  <LazyLoad height={245} offset={100}>
                    <img
                      className={
                        speakerImageClass
                          ? 'img-fluid center-block'
                          : 'speaker-headshot img-fluid img-speaker-shadow center-block rounded'
                      }
                      src={image ? image : 'https://cdn.foodrevolution.org/global/images/placeholder.jpg'}
                    />
                  </LazyLoad>
                </Button>
              ) : (
                <div className="speaker-list-item-image">
                  <LazyLoad height={245} offset={100}>
                    <img
                      className={
                        speakerImageClass
                          ? 'img-fluid center-block'
                          : 'speaker-headshot img-fluid img-speaker-shadow center-block rounded'
                      }
                      src={image ? image : 'https://cdn.foodrevolution.org/global/images/placeholder.jpg'}
                    />
                  </LazyLoad>
                </div>
              )}
            </div>
            <div className="speaker-info-details">
              <div className="speaker-info-details-inner my-auto">
                <p className="speaker-name text-700 mb-0">{name ? name : 'Speaker Name'}</p>
                <p className="speaker-presentation text-small">{title ? title : 'Presentation Title'}</p>
              </div>
              <p className="speaker-date text-small mb-0">
                <em>
                  {isSeries ? (
                    <>
                      {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')}{' '}
                      <br className="d-none d-xl-block" />@ {dateObj.setZone('America/New_York').toFormat('h a')} ET /{' '}
                      {dateObj.setZone('America/Los_Angeles').toFormat('h a')} PT
                    </>
                  ) : (
                    <>
                      {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} — Broadcast starting @ 12 am PT
                      until the next month's Action Hour.
                    </>
                  )}
                </em>
              </p>
            </div>
            {!modalLink ? (
              <div className="speaker-info-more">
                <div className="mt-md-2 mb-0">
                  <Button className="speaker-info-btn" color="speaker-info" onClick={onClick} block>
                    <FontAwesomeIcon className="speaker-info-btn-icon d-md-none" icon={faInfoCircle} />
                    <span className="d-none d-md-inline">Learn&nbsp;</span>
                    More
                  </Button>
                </div>
                <Modal isOpen={isOpen} toggle={toggle} size="lg">
                  <Container className="px-sm-4 pt-0 pb-4" fluid>
                    <Row className="d-flex align-items-center justify-content-center mb-4">
                      <Col xs="10" lg="4" className="mb-4 mb-lg-0">
                        <LazyLoad height={245} offset={100}>
                          <img
                            className={
                              speakerImageClass
                                ? 'img-fluid center-block'
                                : 'speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle'
                            }
                            src={image ? image : 'https://cdn.foodrevolution.org/global/images/placeholder.jpg'}
                          />
                        </LazyLoad>
                      </Col>
                      <Col xs="12" lg="8">
                        <div className="speaker-details">
                          <h3 className="speaker-name section-heading mb-2">{name ? name : 'Speaker Name'}</h3>
                          <h5 className="speaker-presentation mt-0 mb-2">{title ? title : 'Presentation Title'}</h5>
                          <p className="text-16 mb-4">
                            <em>
                              {isSeries ? (
                                <>
                                  {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} @{' '}
                                  {dateObj.setZone('America/New_York').toFormat('h a')} ET /{' '}
                                  {dateObj.setZone('America/Los_Angeles').toFormat('h a')} PT
                                </>
                              ) : (
                                <>
                                  {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')} — Broadcast
                                  starting @ 12 am PT until the next month's Action Hour.
                                </>
                              )}
                            </em>
                          </p>
                          <p className="speaker-description mb-0">
                            <ReactMarkdown source={description || 'Presentation description'} />
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="speaker-bio-info">
                          <h5 className="speaker-bio-heading section-heading">
                            More About {shortName ? shortName : 'Name'}
                          </h5>
                          <ReactMarkdown source={bio || 'Speaker Bio'} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

WlcSpeakerListBroadcastItem.defaultProps = {
  cols: 'col-12 col-md-4'
};

export function WlcSpeakerList(props) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: '',
            speakerImageClass: ""
          }
        ]
      }
    }
  } = usePhaseState();

  return (
    <>
      {props.broadcastList ? (
        <>
          <Row className="speaker-broadcasts-wrap d-flex align-items-stretch justify-content-center">
            {defaultSchedule.actionHours.map((actionHour, i) => (
              <WlcSpeakerListBroadcastItem
                key={i}
                {...actionHour}
                isLive={defaultSchedule.currentActionHour === i && defaultSchedule.phase === 'during'}
                isReplay={
                  (defaultSchedule.currentActionHour === i && defaultSchedule.phase === 'after') ||
                  defaultSchedule.currentActionHour > i
                }
                isSeries={defaultSchedule.actionHours.length > 1}
              />
            ))}
          </Row>
        </>
      ) : (
        <div className="speaker-list-wrap mt-3">
          {defaultSchedule.actionHours.map((actionHour, i) => (
            <WlcSpeakerListItem
              key={i}
              {...actionHour}
              isLive={defaultSchedule.currentActionHour === i && defaultSchedule.phase === 'during'}
              isReplay={
                (defaultSchedule.currentActionHour === i && defaultSchedule.phase === 'after') ||
                defaultSchedule.currentActionHour > i
              }
              isSeries={defaultSchedule.actionHours.length > 1}
            />
          ))}
        </div>
      )}
    </>
  );
}

// Action Collection Speaker Component
export function AcSpeakerList(props) {
  // Very basic, usually (but not always!) in the order of the JSON file
  return acSpeakers.map((speaker, i) => <AcSpeakerItem {...speaker} key={i} />);
}

function AcSpeakerItem({name, image, title}) {
  return (
    <div className={classNames(`ac-speaker`)}>
      <Row className="speaker-info-row m-0">
        <Col xs="12" className="speaker-info-col">
          <div className="speaker-info-wrap">
            <div className="speaker-info-image mb-md-2">
              <LazyLoad height={245} offset={100}>
                <img className="speaker-info-headshot rounded" src={image} />
              </LazyLoad>
            </div>
            <div className="speaker-info-details d-flex flex-column">
              <div className="speaker-info-details-inner my-auto">
                <p className="speaker-name text-700 mb-0">{name}</p>
                <p className="speaker-presentation text-small mb-0">{title}</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

// Action Collection Speaker Component
export function AcSpeakerListDetail(props) {
  // Very basic, usually (but not always!) in the order of the JSON file
  return acSpeakers.map((speaker, i) => <AcSpeakerDetailItem {...speaker} key={i} />);
}

function AcSpeakerDetailItem({name, image, title, description}) {
  return (
    <div className="ac-speaker-detail">
      <Row className="align-items-center justify-content-center">
        <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
          <LazyLoad height={245} offset={100}>
            <img className="speaker-info-headshot rounded-circle" src={image} />
          </LazyLoad>
        </Col>
        <Col lg="9">
          <h5 className="section-heading text-400">
            {name} — <b>{title}</b>
          </h5>
          <ReactMarkdown source={description} />
        </Col>
      </Row>
    </div>
  );
}

// Action Hour Speaker List
export function ActionHourSpeakerList(props) {
  // Very basic, usually (but not always!) in the order of the JSON file
  return actionHourSpeakers.map((speaker, i) => <ActionHourSpeakerItem {...speaker} key={i} />);
}

function ActionHourSpeakerItem({id, name, image}) {
  return (
    <Col xs="6" md="3" xl="2" className={classNames(`action-hour-speaker speaker-item speaker-item-${id} mb-3`)}>
      <Row className="speaker-list-item-row row d-flex align-items-stretch justify-content-center h-100">
        <Col xs="12" className="h-100">
          <div className="text-center d-flex flex-column h-100">
            <div className="speaker-list-item-image mb-2">
              <LazyLoad height={245} offset={100}>
                <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
              </LazyLoad>
            </div>
            <div className="speaker-details my-auto">
              <p className="speaker-name text-small text-800 mb-0">{name}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

// Landing Speaker List
export function LandingSpeakerList(props) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        currentActionHour: 0,
        actionHours: [
          {
            date: '',
            description: '',
            speaker: [{name: '', shortName: '', bio: '', image: '', url: ''}],
            title: '',
            video: '',
            thumb: '',
            recipe: '',
            speakerImageClass: ""
          }
        ]
      }
    }
  } = usePhaseState();
  const isSeries = defaultSchedule.actionHours.length > 1;
  return (
    <>
      <Row className="speaker-row justify-content-center my-4">
        {defaultSchedule.actionHours.map((actionHour, i) => (
          <LandingSpeakerItem {...actionHour} key={i} isSeries={isSeries} {...props} />
        ))}
      </Row>
    </>
  );
}

function LandingSpeakerItem({
  cols = 'col-12 col-md-4',
  modalLink,
  id,
  speaker: {name, shortName, bio, image},
  date,
  title,
  description,
  speakerImageClass,
  isSeries,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  const dateObj = DateTime.fromISO(date);

  function SpeakerModal(props) {
    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg" className={props.modalClass}>
        <Container className="px-sm-4 pt-0 pb-4" fluid>
          <Row className="d-flex align-items-center justify-content-center mb-4">
            <Col xs="10" lg="4" className="mb-4 mb-lg-0">
              <LazyLoad height={245} offset={100}>
                <img
                  className={
                    speakerImageClass
                      ? 'img-fluid center-block'
                      : 'speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle'
                  }
                  src={image ? image : 'https://cdn.foodrevolution.org/global/images/placeholder.jpg'}
                />
              </LazyLoad>
            </Col>
            <Col xs="12" lg="8">
              <div className="speaker-details">
                <h3 className="speaker-name section-heading mb-2">{name ? name : 'Speaker Name'}</h3>
                <h5 className="speaker-presentation mt-0 mb-2">{title ? title : 'Presentation Title'}</h5>
                <p className="speaker-date">
                  {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')}
                  {isSeries ? null : <> — Broadcast starting @ 12 am PT until the next month's Action Hour.</>}
                </p>
                <p className="speaker-description mb-0">{description ? description : 'Presentation description'}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="speaker-bio-info">
                <h5 className="speaker-bio-heading section-heading">More About {shortName ? shortName : 'Name'}</h5>
                <ReactMarkdown source={bio || 'Speaker Bio'} />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>
    );
  }
  function ModalTrigger(props) {
    return (
      <Button className="speaker-info-btn" color="speaker-info" onClick={onClick} block>
        <FontAwesomeIcon className="speaker-info-btn-icon d-md-none" icon={faInfoCircle} />
        <span className="d-none d-md-inline">Learn&nbsp;</span>
        More
      </Button>
    );
  }
  return (
    <>
      <Col
        className={classNames(
          `speaker-info speaker-info-${isSeries ? 'default' : 'singular'} speaker-${id} ${isSeries ? cols : 'col-12'}`
        )}
      >
        <Row className="speaker-info-row">
          <Col xs="12" className="speaker-info-col">
            <div className="speaker-info-wrap">
              <div className="speaker-info-image mb-md-2">
                {!modalLink ? (
                  <Button className="speaker-list-item-link" color="img" onClick={onClick}>
                    <LazyLoad height={245} offset={100}>
                      <img
                        className={
                          speakerImageClass
                            ? 'img-fluid center-block'
                            : 'speaker-headshot img-fluid img-speaker-shadow center-block rounded'
                        }
                        src={image ? image : 'https://cdn.foodrevolution.org/global/images/placeholder.jpg'}
                      />
                    </LazyLoad>
                  </Button>
                ) : (
                  <div className="speaker-list-item-image">
                    <LazyLoad height={245} offset={100}>
                      <img
                        className={
                          speakerImageClass
                            ? 'img-fluid center-block'
                            : 'speaker-headshot img-fluid img-speaker-shadow center-block rounded'
                        }
                        src={image ? image : 'https://cdn.foodrevolution.org/global/images/placeholder.jpg'}
                      />
                    </LazyLoad>
                  </div>
                )}
              </div>
              {props.teaser ? (
                <div className="speaker-info-details mb-auto">
                  <p className="speaker-name text-800 mt-md-2 mb-2">{name ? name : 'Speaker Name'}</p>
                  {props.noTitle ? null : (
                    <p className="speaker-presentation mb-2">
                      <i>{title ? title : 'Presentation Title'}</i>
                    </p>
                  )}
                  <p className="speaker-date text-small mb-3">
                    {dateObj.setZone('America/Los_Angeles').toFormat('EEEE, MMMM dd')}
                    {isSeries ? null : <> — Broadcast starting @ 12 am PT until the next month's Action Hour.</>}
                  </p>
                  <p className="speaker-teaser text-small mb-2">
                    {description ? description : 'Presentation description'}
                  </p>
                  {isSeries ? null : (
                    <div className="d-none d-md-block mt-3">
                      <ModalTrigger />
                    </div>
                  )}
                </div>
              ) : (
                <div className="speaker-info-details my-auto">
                  <p className="speaker-name text-700 mb-0">{name ? name : 'Speaker Name'}</p>
                  {props.noTitle ? null : (
                    <p className="speaker-presentation text-small mb-0">{title ? title : 'Presentation Title'}</p>
                  )}
                </div>
              )}
              {!modalLink ? (
                <div className="speaker-info-more">
                  <div className="mt-md-2 mb-0">
                    {isSeries ? (
                      <ModalTrigger />
                    ) : (
                      <div className="d-md-none">
                        <ModalTrigger />
                      </div>
                    )}
                  </div>
                  <SpeakerModal />
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
}
